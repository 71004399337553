<ng-container *ngIf="extensions">
  The file needs to be a
  <ng-container *ngFor="let extension of extensions; index as i">
    {{extension | uppercase}}<ng-container *ngIf="extensions.length > 2 && i < extensions.length - 2">,</ng-container>
    <ng-container *ngIf="extensions.length > 1 && i == extensions.length - 2"> or</ng-container>
  </ng-container>
</ng-container>
<!--<ng-container *ngIf="!extensions">
  Any file type is supported
</ng-container>
not bigger than 20MB.-->
