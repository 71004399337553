import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-upload-file-requirements',
  templateUrl: './upload-file-requirements.component.html',
  styleUrls: ['./upload-file-requirements.component.scss']
})
export class UploadFileRequirementsComponent {
  @Input() extensions: string[];
}
