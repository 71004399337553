<header id="header" class="header fixed-top">

  <div class="mobile-banner" *ngIf="isMobile()">
    <h3>Our platform is optimized for desktop use. Please visit us from a computer to sign up and start investing! Mobile coming soon.</h3>
  </div>

  <div class="container-fluid">

    <div class="navbar navbar-expand-sm navbar-light" [ngClass]="navOpen ? 'menu-active': ''">

      <a class="navbar-brand logo" routerLink="/" routerLinkActive="is-active">
        <img *ngIf="!alternateNameToggle" src="./assets/images/munio-logo-white.svg" alt="Munio" class="white-logo">
        <img *ngIf="!alternateNameToggle" src="./assets/images/munio-logo.svg" alt="Munio" class="blue-logo">
        <img *ngIf="alternateNameToggle" src="{{alternateNameLogoUrl}}" alt="{{alternateName}}">
      </a>

      <div id="trigger-overlay" (click)="toggleNav()"><fa-icon icon="bars"></fa-icon></div>
      <div class="overlay overlay-hugeinc" [ngClass]="navOpen ? 'menu-open': ''">
        <button type="button" class="overlay-close" (click)="toggleNav()"><fa-icon icon="times"></fa-icon></button>
        <nav>

          <ul class="navbar-nav ml-sm-auto">
            <li *ngIf="isLoginPage()" class="logo-link">
              <a class="navbar-brand logo" routerLink="/" routerLinkActive="is-active">
                <img *ngIf="!alternateNameToggle" src="./assets/images/munio-logo-white.svg" alt="Munio">
                <img *ngIf="alternateNameToggle" src="{{alternateNameLogoUrl}}" alt="{{alternateName}}">
              </a>
            </li>
            <li *ngIf="!isLoginPage()" class="nav-item">
              <a routerLink="/" (click)="setNavFalse()" class="nav-link" routerLinkActive="is-active">Home</a>
            </li>
            <li *ngIf="!isLoginPage()" class="nav-item">
              <a routerLink="/contact" (click)="setNavFalse()" class="nav-link" routerLinkActive="is-active">Contact Us</a>
            </li>
            <li *ngIf="!isLoginPage()" class="ml-md-2">
              <a routerLink="/register" queryParamsHandling="preserve"  (click)="setNavFalse()" class="btn block btn-block-xs btn-primary btn-signup">Register</a>
            </li>
            <li *ngIf="!isLoginPage()" class="nav-item">
              <a routerLink="/log-in" queryParamsHandling="preserve"  (click)="setNavFalse()" class="btn block btn-block-xs btn-primary btn-login">Log In</a>
            </li>
          </ul>

        </nav>
      </div>
    </div>
  </div>
</header>

