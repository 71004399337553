import { HostListener, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CanDeactivate } from '../interfaces/can-deactivate';

/**
 * Abstract class which components should inherit from when they should be able to prevent route changes or page reloads
 * if data has not been saved.
 */
@Directive()
export abstract class CanDeactivateComponent implements CanDeactivate {
  form: UntypedFormGroup;
  protected alwaysDeactivate = false;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

  /**
   * If the user is allowed to navigate away from the form if the form has been altered and the data not saved.
   * @returns {boolean}
   */
  canDeactivate(): boolean {
    return this.alwaysDeactivate || !this.form || (this.form && !this.form.dirty);
  }
}
