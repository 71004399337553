import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from './component/breadcrumbs.component';
import { BreadcrumbsConfig } from './service/breadcrumbs.config';
import { BreadcrumbsService } from './service/breadcrumbs.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    BreadcrumbsComponent,
  ],
  exports: [
    BreadcrumbsComponent,
  ],
})
export class BreadcrumbsModule {
  static forRoot(): ModuleWithProviders<BreadcrumbsModule> {
    return {
      ngModule: BreadcrumbsModule,
      providers: [
        BreadcrumbsService,
        BreadcrumbsConfig
      ]
    };
  }
}
