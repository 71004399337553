<div class="modal-header">
  <h5 class="modal-title text-uppercase text-primary">Confirm delete</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" #modalBody>
  <p>Are you sure you want to delete the following image?</p>
  <img class="delete-preview" [src]="imageUrl" alt="">
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-default" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-danger" (click)="activeModal.close()">Delete</button>
</div>
