<div class="pre-auth-content remove-top-padding">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <h1 class="pb-sm-3">Disable Two-Step Authentication</h1>
        <div *ngIf="successMessage">
          <h5 class="text-center">{{successMessage}}</h5>
        </div>
        <div *ngIf="errorMessage">
          <h5 class="text-center text-danger">{{errorMessage}}</h5>
        </div>

        <mat-spinner class="spinner-centre"*ngIf="!successMessage && !errorMessage"></mat-spinner>

        <h5 class="text-center mt-5">
          <a routerLink="/login">Log In</a>
        </h5>
      </div>
    </div>
  </div>
</div>
