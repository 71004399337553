// Generic version of the Swagger API that can be used on list components to handle search on an arbitrary list of parameters, including paging
import { mergeMap as _observableMergeMap, catchError as _observableCatch, tap } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase, HttpParams } from '@angular/common/http';
import { API_BASE_URL, ApiException, EmailMessageInputModel } from "./generated-api.service";

export interface PagedResult<T> {
  results?: T[] | undefined;
  currentPage?: number | undefined;
  pageCount?: number | undefined;
  pageSize?: number | undefined;
  rowCount?: number | undefined;
  firstRowOnPage?: number | undefined;
  lastRowOnPage?: number | undefined;
}

@Injectable()
export class GenericSearchClient<TModel> {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * @param input (optional)
   * @return Success
   */
  public search(url: string, params?: HttpParams): Observable<PagedResult<TModel>> {
    let url_ = this.baseUrl + url;
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      }),
      params: params
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processSearch(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processSearch(<any>response_);
        } catch (e) {
          return <Observable<PagedResult<TModel>>><any>_observableThrow(e);
        }
      } else
        return <Observable<PagedResult<TModel>>><any>_observableThrow(response_);
    }));
  }

  protected processSearch(response: HttpResponseBase): Observable<PagedResult<TModel>> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }};
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        result200 = _responseText === "" ? null : <Observable<PagedResult<TModel>>>JSON.parse(_responseText, this.jsonParseReviver);
        return _observableOf(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<PagedResult<TModel>>(<any>null);
  }

  public export(url: string, params?: HttpParams): Observable<PagedResult<TModel>> {
    let url_ = this.baseUrl + url;
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      }),
      params: params
    };

    return this.http.request("get", url_, options_).pipe(tap((response_: any) => {
      this.processExport(response_);
    }));
  }

  protected processExport(response: Response): void {
    const status = response.status;

    if (status === 200) {
      var a = document.createElement("a");
      // @ts-ignore
      a.href = URL.createObjectURL(response.body);
      a.download = response.headers.get("content-disposition").split("; ")[1].replace("filename=", "");
      a.type = response.headers.get("content-type");
      document.body.appendChild(a);
      a.click();
    }
  }

  public sendEmail(url: string, params?: HttpParams, model?: EmailMessageInputModel): Observable<number> {
    let url_ = this.baseUrl + url;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(model);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: params
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
        return this.processSendEmail(response_);
    })).pipe(_observableCatch((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
            try {
                return this.processSendEmail(<any>response_);
            } catch (e) {
                return <Observable<number>><any>_observableThrow(e);
            }
        } else
            return <Observable<number>><any>_observableThrow(response_);
    }));
  }

  protected processSendEmail(response: HttpResponseBase): Observable<number> {
    const status = response.status;
    const responseBlob =
        response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }};
    if (status === 200) {
        return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 !== undefined ? resultData200 : <any>null;
        return _observableOf(result200);
        }));
    } else if (status !== 200 && status !== 204) {
        return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }));
    }
    return _observableOf<number>(<any>null);
  }

}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
  if(result !== null && result !== undefined)
    return _observableThrow(result);
  else
    return _observableThrow(new ApiException(message, status, response, headers, null));
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = event => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
