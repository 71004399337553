<div class="modal-header">
  <h5 class="modal-title text-uppercase text-primary">Send Email</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-template #loading>
  <div class="modal-body modal-body-loading">
    <app-loader></app-loader>
  </div>
</ng-template>

<div class="modal-body py-0">

  <form [formGroup]="form" (ngSubmit)="onConfirmSubmit()" *ngIf="form" novalidate>
    <app-form-errors [formGroup]="form"></app-form-errors>

    <div class="row">
      <div class="col-8">
        <app-form-field [fieldOption]="options['subject']"></app-form-field>
      </div>
      <div class="col-4">
        <div class="row attachment-container">
          <div class="col">
            <button type="button" (click)="addAttachment()" class="btn btn-primary attachment-button">
              <fa-icon icon="paperclip" aria-hidden="true"></fa-icon>
            </button>
          </div>
          <div class="col">
            <div class="attached-list">
              <div *ngFor="let attachment of attachments" class="attached-item">
                {{attachment.fileName}}<fa-icon (click)="removeAttachment(attachment)" icon="trash-alt" aria-hidden="true"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="editor-container">
      <email-editor minHeight="80vh"></email-editor>
    </div>

    <div class="button-container">
      <div class="mr-2 ml-0 pl-0">
        <button type="button" class="btn btn-primary" [disabled]="busy" (click)="loadDesign()" matTooltip="Load default email template" *ngIf="!templateLoaded">Load Email Template</button>
      </div>
      <div class="mx-2">
        <button type="button" class="btn btn-primary" [disabled]="busy" (click)="sendTemplate()" matTooltip="Send test email to your email address.">Send Test Email</button>
      </div>
      <div class="mx-2">
        <app-submit [busy]="busy" *ngIf="mailingList.length < 1" [label]="'Send Mail to investors'" btnClass="btn btn-primary"></app-submit>
      </div>
      <div class="mx-2">
        <button type="button" *ngIf="mailingList.length < 1" class="btn btn-primary" (click)="addMailingList()">Add a mailing list</button>
        <button type="button" color="accent" *ngIf="mailingList.length > 0" class="btn btn-primary" (click)="removeMailingList()">Remove a mailing list</button>
        <div class="mailing-list-caution">
          Please make sure that the email addresses are in the first column of the file
        </div>
        <app-submit *ngIf="mailingList.length > 0" [busy]="busy" [label]="'Send Mail to ' + mailingList.length + ' investors'" btnClass="btn btn-primary"></app-submit>
      </div>
    </div>

  </form>
</div>
