<h1 mat-dialog-title>
  {{data.getProspectus ? 'Contact Details' : 'Enquire'}}
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
    <span aria-hidden="true">&times;</span>
  </button>
</h1>



<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
  <div mat-dialog-content>
    <app-form-errors [formGroup]="form"></app-form-errors>
    <app-form-field [fieldOption]="options['jobTitle']"></app-form-field>
    <app-form-field [fieldOption]="options['fullName']" autocomplete="name"></app-form-field>
    <app-form-field [fieldOption]="options['emailAddress']" autocomplete="email" name="username"></app-form-field>
    <app-form-field [fieldOption]="options['contactNumber']"></app-form-field>
    <app-form-field [fieldOption]="options['countryId']"></app-form-field>

    <div *ngIf="!data.getProspectus">
      <app-form-field [fieldOption]="options['additionalComments']"></app-form-field>
      <mat-radio-group class="radio-button-group">
        <mat-radio-button color="primary" [value]="true" (click)="reasonChangeInvest()">
          I would like to invest, contact me
        </mat-radio-button>
        <mat-radio-button color="primary" [value]="false" (click)="reasonChangeQuestion()">
          I have a question, contact me
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <app-submit [busy]="busy" [disabled]="!form.valid" label="Submit" btnClass="btn btn-primary btn-lg"></app-submit>
  </div>
</form>
  <div class="loading-blocks" *ngIf="!form">
    <div class="shadow-field shadow-field-lg"></div>
    <div class="shadow-field shadow-field-lg"></div>
    <div class="shadow-field shadow-field-lg"></div>
    <div class="shadow-field shadow-field-lg"></div>
    <div class="shadow-btn shadow-btn-lg"></div>
  </div>



