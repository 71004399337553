import { Component, Inject, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormComponent } from '../../shared/classes/form.component';
import * as data from "../../shared/services/generated-api.service";
import { FieldOptions, FieldType, InputType } from "../../shared/components/field/field.component";

@Component({
  selector: 'app-request-disable-mfa',
  templateUrl: './request-disable-mfa.component.html',
  styleUrls: ['./request-disable-mfa.component.scss']
})
export class RequestDisableMfaComponent extends FormComponent<any> implements OnInit {
  successMsg = null;
  protected fields = ['emailAddress', 'password', 'recaptcha'];

  constructor(
    @Inject(data.UsersClient) private usersClient: data.UsersClient
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  createForm() {
    let fieldOptions = {
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "password": new FieldOptions({
        name: "password",
        label: "Password",
        type: FieldType.input,
        inputType: InputType.password,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "recaptcha": new FieldOptions({
        name: "recaptcha",
        label: "Recaptcha",
        type: FieldType.recaptcha,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    this.buildFromOptions(of(fieldOptions));
  }

  submitData(): Observable<any> {
    return this.usersClient.requestDisableMFA({ ...this.form.value });
  }

  handleSubmitSuccess(resp: any) {
    this.successMsg = "An email has been sent with further instructions.  Please check your inbox.";
  }
}
