import { Pipe, PipeTransform } from '@angular/core';
import * as data from "../services/generated-api.service";

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(value: data.UserAccountModel, format?: string): any {
    let nameList: Array<string>;
    if (value) {
      if (format) {
        if (format === 'full') {
          nameList = [value.firstName, value.middleNames, value.lastName];
        } else {
          throw Error(`Unsupported format "${format}".`);
        }
      } else {
        nameList = [value.firstName, value.lastName];
      }
      return nameList.filter(i => i).join(' ');
    }
  }

}
