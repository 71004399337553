import { Injectable } from '@angular/core';

@Injectable()
export class HeaderSideNavService {

  normal = true;
  minimal = !this.normal;

  sidenavOpen = false;

  minimalise() {
    this.normal = false;
    this.minimal = !this.normal;
  }

  normalise() {
    this.normal = true;
    this.minimal = !this.normal;
  }

  toggle() {
    this.normal = !this.normal;
    this.minimal = !this.normal;
  }

  minimalOpen() {
    this.sidenavOpen = true;
  }

  minimalClose() {
    this.sidenavOpen = false;
  }

}
