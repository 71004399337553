import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-enum-filter-multi-select',
  templateUrl: './enum-filter-multi-select.component.html',
  styleUrls: ['./enum-filter-multi-select.component.scss']
})
export class EnumFilterMultiSelectComponent implements OnChanges {
  @Input() enumDef: { id: any, name: string }[];
  @Input() selection: { id: any, name: string }[];
  @Output() selectionChange = new EventEmitter<{ id: any, name: string }[]>();

  controls: UntypedFormControl[] = [];

  private buildFormArray(enumDef: { id: any, name: string }[], selection: { id: any, name: string }[]) {
    this.controls = enumDef.map(e => {
      return new UntypedFormControl(selection.findIndex(s => s.id === e.id) >= 0);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const enumDef = (changes.enumDef && changes.enumDef.currentValue) || this.enumDef;
    const selection = (changes.selection && changes.selection.currentValue) || this.selection;
    if (enumDef && selection) {
      this.buildFormArray(enumDef, selection);
    }
  }

  checkboxChange() {
    this.selection = [];
    this.controls.forEach((control, i) => {
      if (control.value) {
        this.selection.push(this.enumDef[i]);
      }
    });
    this.selectionChange.emit(this.selection);
  }

}
