import { Component, Inject, OnInit } from "@angular/core";
import { ListComponent } from "../../../../../shared/classes/list.component";
import { GenericSearchClient } from "../../../../../shared/services/search-api.service";
import * as api from "../../../../../shared/services/generated-api.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AppToasterService } from "../../../../../shared/services/toaster.service";
import { HttpParams } from "@angular/common/http";
import { Select } from "@ngxs/store";
import { AppState } from "../../../../../store/states";
import { Observable } from "rxjs";
import { UserContextModel } from "../../../../../store/models";
import { MessageDetailModalComponent } from "../message-detail-modal/message-detail-modal.component";

@Component({
  selector: "app-messages",
  templateUrl: "./messages-list.component.html",
  styleUrls: ["./messages-list.component.scss"]
})
export class MessagesListComponent extends ListComponent implements OnInit {

  pageSize = 20;
  displayedColumns = ["sentAt", "subject"];

  @Select(AppState.accountDetails) account$: Observable<api.UserAccountModel>;
  @Select(AppState.userContext) userContext$: Observable<UserContextModel>;
  account: api.UserAccountModel;


  constructor(
    @Inject(GenericSearchClient) protected genericSearchClient: GenericSearchClient<api.EmailDisplayModel>,
    @Inject(Router) protected router: Router,
    @Inject(ActivatedRoute) protected activatedRoute: ActivatedRoute,
    @Inject(MatDialog) protected dialog: MatDialog,
    @Inject(AppToasterService) protected toastr: AppToasterService,
  ) {
    super(router, activatedRoute, genericSearchClient, dialog, toastr);
  }

  ngOnInit() {
    this.account$.subscribe(account => {
      this.account = account;
      super.ngOnInit();
    });

  }

  protected prepareFilterQueryParams(queryParams: { [index: string]: any; }) {
    queryParams["userId"] = this.account.userId;
  }

  protected setFilters(queryParams: ParamMap) {
  }

  protected getFilters(): any[] {
    return [{ "userId": this.account.userId }];
  }

  protected clearFilters() {
  }

  protected getData(queryParams: HttpParams) {
    return this.genericSearchClient.search("api/emails", queryParams);
  }

  viewMessage(message: api.EmailDisplayModel) {
    const dialogRef = this.dialog.open(MessageDetailModalComponent, {
      data: message
    });
  }

}
