import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import * as api from "../../../../shared/services/generated-api.service";
import { MatDialog } from "@angular/material/dialog";
import { Select } from '@ngxs/store';
import { AppToasterService } from '../../../../shared/services/toaster.service';
import { Observable, of } from 'rxjs';
import * as _ from "lodash";
import { AppState } from '../../../../store/states';
import { FormComponent } from '../../../../shared/classes/form.component';
import { FieldOptions, FieldType, InputType } from "../../../../shared/components/field/field.component";
import { ConfirmDialogComponent } from "../../../../shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from "../../../../shared/services/modal.service";
import { DocumentUploadModalComponent } from "../../../../shared/components/document-upload-modal/document-upload-modal.component";
import { DataImportModalComponent } from "../../../../shared/components/data-import-modal/data-import-modal.component";
//FIXME import { EmailEditorComponent } from 'angular-email-editor';
import template from "../../../../../assets/templates/email-template.json";

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SendEmailComponent extends FormComponent<api.EmailMessageInputModel> implements OnInit {

  attachments: api.EmailAttachmentInputModel[] = [];
  mailingList: string[] = [];
  protected fields = ['subject', 'text'];
  user: api.UserAccountModel;
  @Select(AppState.accountDetails) account$: Observable<api.UserAccountModel>;

  //FIXME @ViewChild(EmailEditorComponent) private emailEditor: EmailEditorComponent;
  templateLoaded = false;

  constructor(
    public dialogRef: MatDialogRef<SendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    @Inject(api.UsersClient) protected usersClient: api.UsersClient,
    @Inject(MatDialog) private dialog: MatDialog,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(ModalService) protected modalService: ModalService
  ) { super(); }

  ngOnInit() {
    this.account$.subscribe(result => {
      this.user = result;
      super.ngOnInit();
    });
  }

  createForm(): void {
    let fieldOptions = {
      "subject": new FieldOptions({
        name: "subject",
        label: "Subject",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false,
      }),
    };
    this.fields = _.keys(fieldOptions);
    this.buildFromOptions(of(fieldOptions));
  }

  handleSubmitSuccess(obj: api.EmailMessageInputModel): void {
 // FIXME
    // this.emailEditor.exportHtml((data: any) => {
    //   obj.html = data.html;
    // });

    this.dialogRef.close(obj);
  }

  submitData(formData: any): Observable<any> {

    return of(new api.EmailMessageInputModel({
        subject: formData.subject,
        attachments: this.attachments,
        mailingList: this.mailingList,
    }));

  }

  onConfirmSubmit() {
    this.setFormAsTouched(this.form);
    if (!this.form.valid) {
      if (this.toastr) {
        this.toastr.formError();
      }
      return false;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.message = "Are you sure you want to send this message to the selected investors?";
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onSubmit();
      } else {
        this.toastr.info("Sending cancelled.");
      }
    })
  }

  sendTemplate() {
    this.setFormAsTouched(this.form);
    if (!this.form.valid) {
      if (this.toastr) {
        this.toastr.formError();
      }
      return false;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.message = `Are you sure you want to send this message to your email address ${this.user.emailAddress}?`;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const formData = { ...this.form.value };

        //FIXME
        // this.emailEditor.exportHtml((data : any) => {
        //   this.usersClient
        //     .sendTemplateEmail(new api.EmailMessageInputModel({
        //       subject: formData.subject,
        //       attachments: this.attachments,
        //       html: data.html,
        //     }))
        //     .subscribe(result => this.toastr.success("Template email sent to your email address"));
        // });

      }
    }, () => null);
  }

  loadDesign() {
    //FIXME this.emailEditor.loadDesign(template);
    this.templateLoaded = true;
  }

  saveDesign() {

    //FIXME
    // this.emailEditor.saveDesign(design => {
    //   const jsonString = JSON.stringify(design);
    //   console.log(jsonString);
    // });

/*    const fs = require('fs');

    this.emailEditor.saveDesign(design => {
      fs.writeFile("../../../../../assets/templates/email-template.json", design, (error) => {
        if (error) {
          this.toastr.error("Error saving email design. Please try again.");
          return;
        }
        this.toastr.success("Email design has been saved");
      });
    });*/
  }

  addAttachment() {
    const modalRef = this.modalService.open(DocumentUploadModalComponent, { windowClass: "modal-9", backdropClass: "modal-backdrop-9" });
    modalRef.componentInstance.document = { label: "Attachment" };
    modalRef.componentInstance.fileSizeInMB = 2;
    modalRef.componentInstance.directory = "email";
    modalRef.componentInstance.private = true;
    modalRef.componentInstance.returnDocName = true;

    modalRef.result.then(
      result => this.attachments.push(result)
    );
  }

  removeAttachment(file: api.EmailAttachmentInputModel) {
    this.attachments = this.attachments.filter(a => a.fileUrl != file.fileUrl);
  }

  addMailingList() {

    const modalRef = this.modalService.open(DataImportModalComponent, { windowClass: "modal-9", backdropClass: "modal-backdrop-9" });
    modalRef.componentInstance.document = { label: "Mailing List" };
    modalRef.componentInstance.directory = "mailing-list";
    modalRef.componentInstance.private = true;
    modalRef.componentInstance.entityImporting = "mailing-list";
    modalRef.componentInstance.entityTitle = "Mailing List";
    modalRef.componentInstance.options.allowedContentTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    modalRef.result.then(
      result => {
        this.mailingList = result;
        if (this.mailingList.length == 0) {
          this.toastr.error("Failed to import email addresses.")
        }
      }
    );
  }

  removeMailingList() {
    this.mailingList = [];
  }

  close() {
    this.dialogRef.close();
  }
}
