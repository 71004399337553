<h2>Complete Registration</h2>
<br />

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
  <app-form-errors [formGroup]="form"></app-form-errors>

  <div class="col-12" *ngFor="let fieldName of fields">
    <app-form-field [fieldOption]="options[fieldName]">
    </app-form-field>
  </div>

  <app-submit [busy]="busy" [disabled]="!form.valid" label="Register" btnClass="btn btn-primary float-right" matStepperNext></app-submit>
</form>
