<h2>Account Details</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
  <app-form-errors [formGroup]="form"></app-form-errors>
  <app-form-field  id="firstName" [fieldOption]="options['firstName']" autocomplete="given-name"></app-form-field>
  <app-form-field id="lastName" [fieldOption]="options['lastName']" autocomplete="family-name"></app-form-field>
  <app-form-field id="emailAddress" [fieldOption]="options['emailAddress']" autocomplete="username" name="username"></app-form-field>
  <app-form-field id="password" [fieldOption]="options['password']" autocomplete="new-password"  [passwordStrengthMeter]="true"></app-form-field>

  <button id="next" mat-flat-button color="primary" class="float-right" matStepperNext>Next</button>
</form>
