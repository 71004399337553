<div class="container">
  <div class="modal-header flex-row justify-content-between px-4">
    <div class="flex-column">
      <div class="pb-1">Subject: </div>
      <h5 class="modal-title text-uppercase text-primary">{{ data.subject }}</h5>
    </div>
    <div>
      <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading">
    <div class="modal-body modal-body-loading">
      <app-loader></app-loader>
    </div>
  </div>

  <div *ngIf="!isLoading" class="modal-body py-0">

    <div class="attachment-container">
      <div class="attached-list">
        <div *ngFor="let attachment of data.attachments" class="attached-item" (click)="viewDocument(attachment.fileUrl)">
          {{ attachment.fileName }}<fa-icon icon="paperclip" aria-hidden="true"></fa-icon>
        </div>
      </div>
    </div>

    <hr>

    <div id="message-viewer" class="viewer-container"></div>

  </div>

</div>
