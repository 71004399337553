import { Component, Inject, OnInit } from '@angular/core';
import * as data from "../../shared/services/generated-api.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-disable-mfa',
  templateUrl: './disable-mfa.component.html',
  styleUrls: ['./disable-mfa.component.scss']
})
export class DisableMfaComponent implements OnInit {
  errorMessage = "";
  successMessage = "";

  constructor(
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
  ) {
    
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let token = params['token'];
      if (!token) {
        this.errorMessage = "No token supplied.  Please confirm that you've copied the full link into your browser.";
      }

      this.usersClient.disableMFA(token).subscribe(() => {
        this.successMessage = "Two-Step Authentication has been successfully disabled for your account.  Please log in using the below link.";
      }, (error) => {
        this.errorMessage = JSON.parse(error.response)["non_field_errors"];
      });
    });

  }
}
