<div mat-dialog-title class="text-uppercase">
  <span class="font-weight-bold">MAKE YOUR ACCOUNT MORE SECURE</span>
</div>

<div mat-dialog-content>
  <div class="card-body" style="max-width: 720px;overflow:hidden;">
    <ng-container *ngIf="stepIndex == 1">
      <h5>To enhance the security of your account, please proceed with the Two-Step Authentication enrollment process.</h5>

      <h6>Two-step authentication uses either SMS (text message) or an Authenticator app (such as <a href="https://authy.com/" target="_blank">Authy, </a> <a href="https://www.microsoft.com/en-us/account/authenticator" target="_blank">Microsoft Authenticator</a> or <a href="https://mashable.com/2017/10/29/how-to-set-up-google-authenticator/" target="_blank">Google Authenticator</a>) to send you a verification code when you log in.</h6>

      <h6>To use app authentication, please install one of these apps on your mobile device via the relevant App Store.</h6>

      <button mat-flat-button color="primary" class="text-center mt-3 mb-3" (click)="stepIndex = 2">ENABLE AUTHENTICATION</button>
    </ng-container>

    <ng-container *ngIf="stepIndex == 2">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
        <app-form-errors [formGroup]="form"></app-form-errors>

        <h5 class="mb-5">Please select the type of two-step authentication you'd like to use.</h5>

        <div class="row py-4">
          <div class="col-12">
            <app-form-field [fieldOption]="options['mfaType']"></app-form-field>
          </div>
          <div *ngIf="!isAppMfa" class="col-12">
            <app-form-field [fieldOption]="options['contactNumber']"></app-form-field>
          </div>
        </div>
        <button mat-flat-button color="primary" class="text-center mt-3 mb-3" (click)="handleStepIndex3()">Next</button>
</form>
    </ng-container>

    <ng-container *ngIf="stepIndex == 3">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
        <app-form-errors [formGroup]="form"></app-form-errors>

        <div *ngIf="isAppMfa">
            <h5 class="mb-5">Please use an authenticator app on your mobile device to scan the QR code. Then enter the code in the space below.</h5>
          <h6 class="mb-5" *ngIf="hasPreviousTotpSeed">Please note that if MFA has previously been disabled for this account, a re-scan of the barcode is required prior to capturing the verification code.</h6>

          <div class="row">
            <div>
<!--              <ngx-kjua [text]="totpLink" [size]="160" [ecLevel]="'M'"></ngx-kjua>-->
              <a *ngIf="!captureCodeManually" href="" onclick="return false;" (click)="toggleCaptureCodeManually()">Can't scan the code? Show me the key</a>
              <!--<a *ngIf="captureCodeManually" href="" onclick="return false;" matTooltip="Copy Code"
                 ngxClipboard [cbContent]="totpCode" (cbOnSuccess)="totpCodeCopied()">
                <fa-icon icon="copy"></fa-icon> {{totpCode}}
              </a>-->
            </div>
            </div>

            <div class="row py-4">
              <div class="col-6">
                <app-form-field [fieldOption]="options['totp']"></app-form-field>
              </div>
            </div>
          </div>

        <div *ngIf="!isAppMfa">
          <h5 class="mb-5">An SMS has been sent to your registered contact number. Please enter the verification code below.</h5>
          <h6 class="mb-5">Please note that SMS verification codes are valid for 3 minutes.</h6>

          <div class="row">
            <div class="col-6">
              <app-form-field [fieldOption]="options['smsCode']"></app-form-field>
            </div>
          </div>

          <p class="mtn text-right small"><a href="" onclick="return false;" (click)="resendSms()">Resend verification code</a></p>
        </div>

        <div mat-dialog-actions align="end">
          <button mat-flat-button mat-stroked-button color="secondary" class="text-center mt-3 mb-3" type="button" (click)="handleStepIndex2()">Back</button>
          <app-submit [busy]="busy" [disabled]="!form.valid" [label]="'Accept'"></app-submit>
        </div>
      </form>
    </ng-container>
  </div>
</div>
