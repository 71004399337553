import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import * as data from "../../services/generated-api.service";

@Component({
  selector: "app-incomplete-profile-banner",
  templateUrl: "./incomplete-profile-banner.component.html",
  styleUrls: ["./incomplete-profile-banner.component.scss"]
})
export class IncompleteProfileBannerComponent implements OnInit, OnDestroy {
  account: data.UserAccountModel;
  private accountUpdateSubscription: Subscription;

  constructor(
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
  ) {
    // Listen for account updates and update the account data on changes.
    // dashboardService.accountUpdate$.subscribe(account => this.account = account);
  }

  ngOnInit() {
     this.accountUpdateSubscription = this.usersClient.getCurrentUserAccountDetails()
       .subscribe(account => this.account = account);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
     this.accountUpdateSubscription.unsubscribe();
  }

}
