import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSideNavParentLi]',
})
export class SideNavParentLiDirective {
  constructor(public el: ElementRef) { }

  @HostListener('click', ['$event.target']) onClick(target) {
    const anchorClicked = this.el.nativeElement.getElementsByTagName('a')[0].contains(target);
    if (anchorClicked) {
      this.el.nativeElement.classList.toggle('active');
    }
  }
}
