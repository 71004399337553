import { Component, OnInit, Inject, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormComponent } from '../../../shared/classes/form.component';
import { FieldOptions, FieldType, InputType } from "../../../shared/components/field/field.component";
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../../services/config.service';
import * as _ from "lodash";


@Component({
  selector: 'app-login-auth',
  templateUrl: './login-auth.component.html',
  styleUrls: ['./login-auth.component.scss']
})
export class LoginAuthComponent extends FormComponent<any> implements OnInit, OnChanges {

  protected fields = ['password'];
  @Input() autocompletePassword: string;
  @Output() formSubmitted = new EventEmitter<UntypedFormGroup>();
  public bankId = false;
  sandboxToggle = false;
  requiresVerification = this.configService.settings.featureToggles.enableMfa;
  
  constructor(
      @Inject(ConfigService) private configService: ConfigService,
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  ngOnChanges() {
    this.createForm();
  }

  createForm() {

    let password = new FieldOptions({
      name: "password",
      label: "Password",
      type: FieldType.input,
      inputType: InputType.password,
      allowBlank: false,
      allowNull: false,
      readOnly: false
    });


    let fieldOptions = ({ password });
    this.fields = _.keys(fieldOptions);

    this.buildFromOptions(of(fieldOptions), of({ password: this.autocompletePassword }));
  }

  ngOnInit() {
    this.sandboxToggle = this.configService.settings.featureToggles.sandbox;
    super.ngOnInit();
  }

  submitData(): Observable<any> {
    return of(this.formSubmitted.emit(this.form));
  }

  protected attachFormErrors(formGroup: UntypedFormGroup, errors: {}) {
    super.attachFormErrors(formGroup, errors);
  }

  public handleSubmitError(error: any) {
    this.bankId = false;
    if (error.status === 400) {
      this.attachFormErrors(this.form, JSON.parse(error.response));
    }
  }

  reset() {
    this.bankId = false;
    this.form.get('password').markAsUntouched();
    this.form.get('password').markAsPristine();
    this.setFormAsPristine(this.form);
  }

  bankIdLogin() {
    this.bankId = true;
    return of(this.formSubmitted.emit(this.form));
  }

  handleSubmitSuccess(respData: any) {
    this.bankId = false;
  }
}
