<div mat-dialog-title class="text-uppercase">
  <span class="font-weight-bold">Updated Terms of Use</span>
</div>


<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate style="max-width: 720px;">
    <div class="card-body">

      <ng-template #loading>
        <div class="modal-body modal-body-loading">
          <app-loader></app-loader>
        </div>
      </ng-template>

      <h5>Please review and accept the updated Terms of Use below in order to continue.</h5>

      <app-form-errors [formGroup]="form"></app-form-errors>

      <div class="row py-4">
        <div class="col-12" *ngFor="let fieldName of fields">
          <app-form-field [fieldOption]="options[fieldName]"
                          requiredErrorMsg="">
          </app-form-field>
        </div>
      </div>

      <div mat-dialog-actions align="end">
        <app-submit [busy]="busy" [disabled]="!form.valid" [label]="'Accept'"></app-submit>
      </div>
    </div>
  </form>
</div>
