<div>
  <h1 mat-dialog-title>Confirm</h1>
  <div mat-dialog-content>
    <p>You have unsaved changes. If you leave your changes will be lost.</p>
  </div>
  <div mat-dialog-actions align="center">
    <button class="btn btn-default" (click)="leave()">Leave</button>
    <button class="btn btn-primary" (click)="stay()" cdkFocusInitial>Stay</button>
  </div>
</div>
