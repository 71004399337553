import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-picture-delete-confirm-modal',
  templateUrl: './picture-delete-confirm-modal.component.html',
  styleUrls: ['./picture-delete-confirm-modal.component.scss']
})
export class PictureDeleteConfirmModalComponent {
  imageUrl: string;

  constructor(@Inject(NgbActiveModal) public activeModal: NgbActiveModal) { }

}
