<div class="http-status container-fluid text-center">
  <div class="row justify-content-center align-items-center">
    <div class="col-auto">
      <div class="status-number text-primary">{{status}}</div>
      <h1>{{statusText}}</h1>
      <p *ngIf="status === 500">Well, that wasn't supposed to happen. Please try again in a moment.</p>
      <button class="btn btn-primary" (click)="reload()" *ngIf="status === 500">Reload page</button>
    </div>
  </div>
</div>
