
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
    <app-form-errors [formGroup]="form"></app-form-errors>
    <div class="alert alert-success" role="alert" *ngIf="form && form.errors && form.errors.server && form.errors.server[0].indexOf('verified') > -1">
      If you did not receive your verification mail, you can <a href="/confirm-email">request a new link here</a>.
    </div>
    <app-form-field [fieldOption]="options['emailAddress']" autocomplete="username"></app-form-field>
    <app-form-field [fieldOption]="options['password']" autocomplete="password" style="display: none;"></app-form-field>
    <button id="nextEmail" mat-flat-button color="primary" class="float-right mb-4 mt-4" matStepperNext>Next</button>
    <p class="text-center mt-4">
      Not an investor yet? <a routerLink="/register">Register now</a>
    </p>
  </form>


<div class="loading-blocks" *ngIf="!form">
  <div class="shadow-field shadow-field-lg"></div>
  <div class="shadow-btn shadow-btn-lg"></div>
</div>
