import { Component, Inject } from '@angular/core';
import { UploaderOptions, UploadFile, UploadOutput } from 'ngx-uploader';
import { BaseUploadComponent } from '../../classes/base-upload.component';
import { ConfigService } from '../../../services/config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { AppToasterService } from "../../../shared/services/toaster.service";

@Component({
  selector: 'app-file-import-modal',
  templateUrl: './file-import-modal.component.html',
  styleUrls: ['./file-import-modal.component.scss']
})
export class FileImportModalComponent extends BaseUploadComponent {
  document: any;
  immediate = true;
  entityImporting = 'task';
  options = {
    concurrency: 1,
    maxUploads: 1,
    allowedContentTypes: ['text/csv', 'application/json', 'application/vnd.ms-excel'],
    maxFileSize: 20 * 1024 * 1024
  } as UploaderOptions;

  constructor(
    @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(ConfigService) protected configService: ConfigService,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(Store) protected store: Store,
  ) {
    super(configService, store);
  }

  protected addedToQueue(file: UploadFile) {
    this.importData(file, this.entityImporting);
  }

  public onImportTasks(output: UploadOutput): void {
    this.onUploadOutput(output, this.entityImporting);
  }

  protected uploadDone(file: UploadFile) {
    this.toastr.success(`${this.entityImporting.charAt(0).toUpperCase()}${this.entityImporting.substring(1)}s have successfully been imported.`);
    this.activeModal.close(file.response);
  }
}
