<div class="pre-auth-content remove-top-padding">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <!-- Token is invalid -->
        <div *ngIf="!tokenIsValid">
          <mat-spinner class="spinner-centre" *ngIf="!errorMsg"></mat-spinner>
          <div *ngIf="errorMsg">
            <h1 class="pb-sm-3">{{errorMsg}}</h1>
            <p class="text-center">
              Go back to <a routerLink="/forgot-password">forgot password</a> to generate a new request.
            </p>
          </div>
        </div>

        <!-- Token is valid -->
        <div *ngIf="tokenIsValid">
          <h1 class="pb-sm-3">Choose your new password</h1>
          <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form && !successMsg" novalidate>
            <app-form-errors [formGroup]="form"></app-form-errors>
            <app-form-field [fieldOption]="options['newPassword']" autocomplete="new-password" [passwordStrengthMeter]="true"></app-form-field>
            <app-submit [busy]="busy" [disabled]="!form.valid" btnClass="btn btn-primary btn-lg"></app-submit>
          </form>
          <div class="loading-blocks" *ngIf="!form">
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-btn shadow-btn-lg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

