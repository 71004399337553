import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit {
  @Output() dismiss = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  dismissClicked() {
    this.dismiss.emit();
  }

}
