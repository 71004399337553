import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * `CsrfInterceptorService` add the CSRF token to request headers.
 *
 * CSRF tokens are not added by default when making requests to absolute URLs.
 */
@Injectable()
export class CsrfInterceptorService implements HttpInterceptor {

  constructor(@Inject(HttpXsrfTokenExtractor) private tokenExtractor: HttpXsrfTokenExtractor) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'X-CSRFToken';
    const token = this.tokenExtractor.getToken() as string;
    if (token !== null && !req.headers.has(headerName) && req.url.startsWith('/')) {
      req = req.clone({ headers: req.headers.set(headerName, token) });
    }
    return next.handle(req);
  }

}
