<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
  <app-form-errors [formGroup]="form"></app-form-errors>
  <div class="alert alert-success" role="alert" *ngIf="form && form.errors && form.errors.server && form.errors.server[0].indexOf('verified') > -1">
    If you did not receive your verification mail, you can <a href="/confirm-email">request a new link here</a>.
  </div>

  <ng-container *ngIf="!mustEnableMfa">
    <app-form-field [fieldOption]="options['verificationToken']"></app-form-field>
    <br />
    <p *ngIf="mfaTypeId != 2" class="mtn text-right small"><a routerLink="/request-disable-mfa" class="forgot-password">I've lost my Authenticator device</a></p>
    <p *ngIf="mfaTypeId == 2" class="mtn text-right small"><a href="" onclick="return false;" (click)="resendSms()" class="forgot-password">Resend verification code</a></p>
    <p *ngIf="mfaTypeId == 2" class="mtn text-right small"><a routerLink="/request-disable-mfa" class="forgot-password">My phone number has changed</a></p>
  </ng-container>

  <app-submit id="login" *ngIf="!mustEnableMfa" [busy]="busy" label="Log In" btnClass="btn btn-primary btn-lg"></app-submit>
</form>

<div class="loading-blocks" *ngIf="!form">
  <div class="shadow-field shadow-field-lg"></div>
  <div class="shadow-btn shadow-btn-lg"></div>
  <div class="shadow-btn shadow-btn-lg"></div>
</div>
