<div class="pre-auth-content remove-top-padding">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <h1 class="pb-sm-3">Disable Two-Step Authentication</h1>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form && !successMsg" novalidate>
          <app-form-errors [formGroup]="form"></app-form-errors>
          <app-form-field [fieldOption]="options['emailAddress']" autocomplete="email"></app-form-field>
          <app-form-field [fieldOption]="options['password']" autocomplete="password"></app-form-field>
          <app-form-field [fieldOption]="options['recaptcha']"></app-form-field>
          <app-submit [busy]="busy" label="Submit" [disabled]="!form.valid" btnClass="btn btn-primary btn-lg"></app-submit>
        </form>
        <div *ngIf="successMsg">
          <h5 class="text-center">{{successMsg}}</h5>
        </div>
        <div class="loading-blocks" *ngIf="!form">
          <div class="shadow-field shadow-field-lg"></div>
          <div class="shadow-field shadow-field-lg"></div>
          <div class="shadow-captcha"></div>
          <div class="shadow-btn shadow-btn-lg"></div>
        </div>
        
      </div>
    </div>
  </div>
</div>
