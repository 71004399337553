<div class="text-uppercase" mat-dialog-title>
  <span class="font-weight-bold">{{apiData['id'] ? 'Edit' : 'Add'}} Bank Account</span>
  <button mat-button class="dialog-close" aria-label="Close" [mat-dialog-close]>
    &#x2716;
  </button>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate autocomplete="off">
    <div class="plush">
      <br />
      <ng-template #loading>
        <div class="modal-body modal-body-loading">
          <app-loader></app-loader>
        </div>
      </ng-template>

      <div *ngIf="apiData['id'] && apiData.hasBeenVerified && !usesBankId" class="alert alert-warning"><fa-icon icon="exclamation-circle"></fa-icon>&nbsp; Please note that any changes made to this bank account will require re-verification.</div>

      <app-form-errors [formGroup]="form"></app-form-errors>

      <div class="row">
        <div class="col-6">
          <app-form-field [fieldOption]="options['name']"></app-form-field>
        </div>
        <div class="col-6">
          <app-form-field hidden="true" [fieldOption]="options['bankAccountTypeId']"></app-form-field>
        </div>
        <div class="col-6" *ngIf="shouldShowField('accountHolderName')">
          <app-form-field [fieldOption]="options['accountHolderName']"></app-form-field>
        </div>
        <div class="col-6" *ngIf="shouldShowField('accountNumber')">
          <app-form-field [fieldOption]="options['accountNumber']"></app-form-field>
        </div>
        <div class="col-6" *ngIf="shouldShowField('bankName')">
          <app-form-field [fieldOption]="options['bankName']"></app-form-field>
        </div>
        <div class="col-6" *ngIf="shouldShowField('bankAddress')">
          <app-form-field [fieldOption]="options['bankAddress']"></app-form-field>
        </div>
        <div class="col-6" *ngIf="shouldShowField('swiftbic')">
          <app-form-field [fieldOption]="options['swiftbic']"></app-form-field>
        </div>
        <div class="col-6" *ngIf="shouldShowField('verificationDocumentLink')">
          <app-form-field [fieldOption]="options['verificationDocumentLink']"></app-form-field>
        </div>
      </div>
      <br />
      <br />
      <div mat-dialog-actions align="end">
        <app-submit id="bankSave" [busy]="busy" [label]="Save" [disabled]="!(userContext$ | async).canTransact" btnClass="btn btn-primary" style="margin-left: 15px;"></app-submit>
      </div>
    </div>
  </form>
</div>

