import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subscription, throwError, timer ,  Observable } from 'rxjs';
import { finalize, flatMap, retryWhen } from 'rxjs/operators';
import { ActiveToast, ToastrService } from 'ngx-toastr';

const RETRY_STATUSES = [0, 502, 503, 504];

@Injectable({
  providedIn: 'root'
})
export class RetryRequestInterceptorService implements HttpInterceptor {
  private activeToastr: ActiveToast<any>;
  private activeToastrOnHiddenSub = new Subscription();

  constructor(
    @Inject(ToastrService) private toastr: ToastrService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        retryWhen(
          attempts => {
            let count = 0;
            return attempts.pipe(
              flatMap(error => {
                if (error instanceof HttpErrorResponse && RETRY_STATUSES.includes(error.status)) {
                  ++count;
                  const delayMS = 1000 * (count ** 2);

                  if (!this.activeToastr) {
                    this.activeToastr = this.toastr.warning(
                      'Experiencing a connection error. Busy retrying to communicate with the server...',
                      'Connection error',
                      {disableTimeOut: true, closeButton: false, tapToDismiss: false}
                    );
                    this.activeToastrOnHiddenSub.add(
                      this.activeToastr.onHidden.subscribe(() => this.activeToastr = null)
                    );
                  }

                  if (count > 5) {
                    return throwError(error);
                  } else {
                    return timer(delayMS);
                  }
                }
                return throwError(error);
              })
            );
          }
        ),
        finalize(() => {
          if (this.activeToastr) {
            this.activeToastrOnHiddenSub.unsubscribe();
            this.toastr.remove(this.activeToastr.toastId);
            this.activeToastr = null;
          }
        }),
      );
  }

}
