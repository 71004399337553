import { Injectable, Inject } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { AppToasterService } from "../services/toaster.service";
import * as api from "../services/generated-api.service";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class AdminNotificationsService {
  private connection: signalR.HubConnection;

  constructor(
    @Inject(AppToasterService) protected toastr: AppToasterService,
  ) {
    this.investorChatMessagePending.subscribe(id => this.toastr.info(`You have a new pending message that needs to be approved in the chat.`));
  }

  public connect() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`/admin/hub`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.onclose((err) => setTimeout(() => this.tryConnect(), 5000));

    this.connection.on("depositModified", (id: string) => this.depositModified.next(id));
    this.connection.on("withdrawalModified", (id: string) => this.withdrawalModified.next(id));
    this.connection.on("marketplaceListingCreated", (id: string) => this.marketplaceListingCreated.next(id));
    this.connection.on("investorChatMessagePending", (id: string) => this.investorChatMessagePending.next(id));
    this.connection.on("voteCast", (id: string) => this.voteCast.next(id));
    this.connection.on("taskModified", (id: string) => this.taskModified.next(id));
    this.connection.on("taskConstraintModified", (id: string) => this.taskConstraintModified.next(id));
    this.connection.on("quoteModified", (id: string) => this.quoteModified.next(id));
    this.connection.on("contractModified", (id: string) => this.contractModified.next(id));    
    
    this.connection.on("chatMessagePending", pendingMessage => this.chatMessagePending.next(pendingMessage));
    this.connection.on("chatMessageApproved", approvedMessage => this.chatMessageApproved.next(approvedMessage));
    this.connection.on("chatMessageDeclined", declinedMessage => this.chatMessageDeclined.next(declinedMessage));
    this.connection.on("chatMessageDeleted", deletedMessage => this.chatMessageDeleted.next(deletedMessage));

    this.tryConnect();
  }

  tryConnect() {
    this.retry(() => this.connection.start().then(() => this.connection.invoke("register")));
  }

  retry(fn, retries = 10, err = null) {
    if (!retries) {
      return Promise.reject(err);
    }
    return fn().catch(err => {
      return setTimeout(() => this.retry(fn, (retries - 1), err), 1000)
    });
  }

  public disconnect() {
    if (!this.connection) return;

    this.connection.onclose(() => {});
    this.connection.stop();
    this.connection = null;
  }

  public depositModified = new Subject<string>();
  public withdrawalModified = new Subject<string>();
  public marketplaceListingCreated = new Subject<string>();
  public investorChatMessagePending = new Subject<string>();
  public voteCast = new Subject<string>();
  public taskModified = new Subject<string>();
  public taskConstraintModified = new Subject<string>();
  public quoteModified = new Subject<string>();
  public contractModified = new Subject<string>();
  
  public chatMessagePending = new Subject<api.ChatMessageDisplayModel>();
  public chatMessageApproved = new Subject<api.ChatMessageDisplayModel>();
  public chatMessageDeclined = new Subject<api.ChatMessageDisplayModel>();
  public chatMessageDeleted = new Subject<api.ChatMessageDisplayModel>();
}
