import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerErrorService } from './server-error.service';

/*
We want to handle 500 errors globally in the application without explicitly handling them in every request on components.
This generally works, but when resolving data on routes, we need to redirect the user to another page so that the component which
is relying on the route data does not break because of missing data. Thus the interceptor should be skip-able from route resolves so
that the error message is not displayed twice.
 */
export const ServerErrorInterceptorSkipHeader = 'X-Skip-500-Interceptor';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(@Inject(ServerErrorService) private service: ServerErrorService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip handling 500 error if skip header in request.
    if (req.headers.has(ServerErrorInterceptorSkipHeader)) {
      const headers = req.headers.delete(ServerErrorInterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    // Intercept and display 500 error over page content.
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 500) {
          this.service.display();
        }
        return throwError(error);
      })
    );
  }

}
