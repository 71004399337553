import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  // Keep a reference of all open modals so we can close them all on route changes etc...
  private modals: NgbModalRef[] = [];

  constructor(private ngbModal: NgbModal) { }

  open (content: any, config?: any) {
    const modal = this.ngbModal.open(content, config);

    // Keep track of the open modal.
    this.modals.push(modal);

    const componentClassList = (modal as any)._windowCmptRef.instance._elRef.nativeElement.classList;
    const backdropClassList = (modal as any)._backdropCmptRef.location.nativeElement.classList;
    setTimeout(() => {
      componentClassList.add('custom-show');
      backdropClassList.add('custom-show');

    }, 0);

    const fx = (modal as any)._removeModalElements.bind(modal);
    (modal as any)._removeModalElements = () => {
      backdropClassList.remove('custom-show');
      componentClassList.remove('custom-show');

      // Remove closed modal/
      const index = this.modals.indexOf(modal);
      if (index !== -1) {
        this.modals.splice(index, 1);
      }

      setTimeout(fx, 250);
    };

    return modal;
  }

  closeAll() {
    for (const modal  of this.modals) {
      modal.dismiss();
    }
  }
}
