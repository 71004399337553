import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { RegisterComponent } from './pages/register/register.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { UnauthenticatedComponent } from './pages/unauthenticated/unauthenticated.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { UnAuthenticatedGuard } from './shared/guards/un-authenticated-guard.service';
import { AuthenticatedGuard } from './shared/guards/authenticated-guard.service';
import { ConfirmEmailComponent } from "./pages/confirm-email/confirm-email.component";
import { VerifyDispComponent } from "./pages/verify-disp/verify-disp.component";
import { RequestDisableMfaComponent } from './pages/request-disable-mfa/request-disable-mfa.component';
import { DisableMfaComponent } from './pages/disable-mfa/disable-mfa.component';
import { CompleteDispRegistrationComponent } from './pages/complete-disp-registration/complete-disp-registration.component';
import { LandingComponent } from './pages/landing/landing.component';

const routes: Routes = [
  {
   path: 'investor',
   loadChildren: () => import('./modules/investor/investor.module').then(m => m.InvestorModule),
  },
  {
   path: 'broker',
   loadChildren: () => import('./modules/broker/broker.module').then(m => m.BrokerModule),
  },
  {
   path: 'supplier',
   loadChildren: () => import('./modules/supplier/supplier.module').then(m => m.SupplierModule),
  },
  {
   path: 'qualityAssessor',
   loadChildren: () => import('./modules/qualityAssessor/qualityAssessor.module').then(m => m.QualityAssessorModule),
  },
  {
   path: 'admin',
   loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '',
    component: UnauthenticatedComponent,
    children: [
      {
        path: '',
        component: LandingComponent,
        data: { title: 'Home' }
      },
      {
       path: 'register',
       children: [
         {
           path: '',
           component: RegisterComponent,
           data: { title: 'Register' }
         },
       ]

      },
      {
       path: 'login',
       redirectTo: 'log-in',
       pathMatch: 'full',
      },
      {
       path: 'log-in',
       component: LoginComponent,
       canActivate: [UnAuthenticatedGuard],
       data: { title: 'Log In' },
      },
      {
       path: 'forgot-password',
       component: ForgotPasswordComponent,
       data: { title: 'Forgot Password' },
      },
      {
       path: 'password-reset/:token',
       component: PasswordResetComponent,
       data: { title: 'Password Reset' },
      },
      {
       path: 'password-set/:token',
       component: SetPasswordComponent,
       data: { title: 'Password Set' },
      },
      //{
      //  path: 'green-bond',
      //  component: GreenBondHomeComponent,
      //  data: { title: 'MUNIO Green Bond' },
      //},
      {
       path: 'contact',
       component: ContactUsComponent,
       data: { title: 'Contact Us' },
      },
      {
       path: 'confirm-email',
       component: ConfirmEmailComponent,
       data: { title: 'Confirm Email' },
      },
      {
       path: 'verify-disp/:token',
       component: VerifyDispComponent,
       data: { title: 'Verify DISP' },
      },
      {
       path: 'request-disable-mfa',
       component: RequestDisableMfaComponent,
       data: { title: 'Disable Two-Step Authentication' },
      },
      {
       path: 'disable-mfa/:token',
       component: DisableMfaComponent,
       data: { title: 'Disable Two-Step Authentication' },
      },
      {
       path: 'register-disp/:token',
       component: CompleteDispRegistrationComponent,
       data: { title: 'Complete BankID Registration' },
      },
    ]
  },
  {
    path: '',
    component: UnauthenticatedComponent,
    children: [
      {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: 'Page not found' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouting { }
