import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { IClientOptions } from "../shared/services/generated-api.service";
//FIXME import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
//FIXME import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

/**
 * The config service is responsible to load App settings from the API. This might include OAuth client IDs etc.
 */
@Injectable()
export class ConfigService {
  private http;
  public loadedSettings = false;
  public settings: IClientOptions;

  URL_SETTINGS = 'settings';

  // Cyclic dependency error with HttpInterceptor
  // https://github.com/angular/angular/issues/18224
  constructor(
    @Inject(Injector) private injector: Injector,
    //FIXME private appInsightsService: AppInsightsService
  ) {

  }

  private static getUrl(path: string) {
    return environment.apiRoot + path;
  }

  /**
   * Config factory should call this method. This happens during `APP_INITIALIZER`. The provider will wait for the promise to resolve
   * before the rest of the app is loaded.
   * @returns {Promise<boolean>}
   */
  load(): Promise<boolean> {
    this.http = this.injector.get(HttpClient);
    return new Promise<boolean>((resolve) => {
      this.http.get(ConfigService.getUrl(this.URL_SETTINGS))
        .subscribe(
          (response: any) => {
            this.loadedSettings = true;
            this.settings = response;
            //FIXME
            // this.appInsightsService.config = {
            //   instrumentationKey: this.settings?.appInsightsInstrumentation
            // };
            //
            // this.appInsightsService.queue.push(() => {
            //   // Filter out successful SignalR requests from App Insights telemetry...
            //   this.appInsightsService.context.addTelemetryInitializer((envelope) => {
            //     return !(
            //       envelope.data.baseData.resultCode == "200" &&
            //       envelope.data.baseData.name.indexOf("/hub") > -1
            //     );
            //   });
            // });
            //
            // //make sure to initialize and start-up app insights
            // this.appInsightsService.init();

            resolve(true);
          },
          (error: any) => {
            console.error('Unable to load app settings from API!', error);
            // Resolve the promise so that the app loading continues.
            // We do not want to break the entire app if some settings does not exist.
            resolve(true);
          }
        );
    });

  // //FIXME
  //  /**
  //   * Config factory should call this method. This happens during `APP_INITIALIZER`. The provider will wait for the promise to resolve
  //   * before the rest of the app is loaded.
  //   * @returns {Promise<boolean>}
  //   */
  //  load(): Promise<boolean> {
  //    this.http = this.injector.get(HttpClient);
  //    return new Promise<boolean>((resolve) => {
  //      this.http.get(ConfigService.getUrl(this.URL_SETTINGS))
  //        .subscribe(
  //          response => {
  //            this.loadedSettings = true;
  //            this.settings = response;
  //            //mapConfig.apiKey = this.settings.googleApiKey;
  //
  //            this.appInsightsService.config = {
  //              instrumentationKey: this.settings.appInsightsInstrumentation
  //            };
  //
  //            this.appInsightsService.queue.push(() => {
  //              // Filter out successful SignalR requests from App Insights telemetry...
  //              this.appInsightsService.context.addTelemetryInitializer((envelope) => {
  //                return !(
  //                    envelope.data.baseData.resultCode == "200" &&
  //                    envelope.data.baseData.name.indexOf("/hub") > -1
  //                  );
  //              });
  //            });
  //
  //            //make sure to initialize and start-up app insights
  //            this.appInsightsService.init();
  //
  //            resolve(true);
  //          },
  //          error => {
  //            console.error('Unable to load app settings from API!', error);
  //            // Resolve the promise so that the app loading continues.
  //            // We do not want to break the entire app if some settings does not exist.
  //            resolve(true);
  //          }
  //        );
  //    });

   }

}
