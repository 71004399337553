// Actions

import * as data from "../shared/services/generated-api.service";
import { Data } from '@angular/router';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public token: string) {}
}

export class SetTokenFromAuthInterceptor {
  static readonly type = '[Auth] SetToken from AuthInterceptor';
  constructor(private token: string) {}
}

export class RefreshJWT {
  static readonly type = '[Auth] RefreshJWT';
}

/* Require log in actions */

export abstract class SetRedirect {
  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  constructor(public returnToUrl?: string) {}
}

export class RequireLoginFromAuthGuard extends SetRedirect {
  static readonly type = '[Auth] RequireLogin from AuthenticatedGuard';
}

export class RequireLoginFromAuthInterceptor extends SetRedirect {
  static readonly type = '[Auth] RequireLogin from AuthInterceptor';
}

export class Logout extends SetRedirect {
  static readonly type = '[Auth] Logout from Header';
}

/* ClearAppState */

export class ClearAppState {
  static readonly type = '[App] ClearAppState';
}

/* Reload App State */

export class ReloadAppStateFromInit {
  static readonly type = '[App] ReloadAppState from Init';
}

export class ReloadAppStateFromLogin {
  static readonly type = '[App] ReloadAppState from Login';
}

export class ReloadAppStateFromInvestorDetailForm {
  static readonly type = '[App] ReloadAppState from Investor Detail Form';
}

export class ReloadAppStateFromConfirmTakeon {
  static readonly type = '[App] ReloadAppState from ConfirmTakeon';
}

/* SetUserState */

export abstract class AbstractSetUserState {
  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  constructor(public loggedInUserAccount?: data.UserAccountModel, public actingOnBehalfOfUserAccount?: data.UserAccountModel) {}
}

export class SetUserStateFromAccountForm extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from AccountForm';
}

export class SetUserStateFromUploadPicModal extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from UploadPicModal';
}

export class SetUserStateFromIsAdminGuard extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from IsAdminGuard';
}

export class SetUserStateFromIsBrokerGuard extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from IsBrokerGuard';
}

export class SetUserStateFromIsSupplierGuard extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from IsSupplierGuard';
}

export class SetUserStateFromIsQualityAssessorGuard extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from IsQualityAssessorGuard';
}

export class SetUserStateFromIsInvestorGuard extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from IsInvestorGuard';
}

export class SetUserStateFromNavigateToDashboard extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from NavigateToDashboard';
}

export class SetUserStateFromReloadAppState extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from ReloadAppState';
}

export class CheckForUpdatedTermsOfUse {
  static readonly type = '[App] CheckForUpdatedTermsOfUse';
}

/* Investor Impersonation */

export class EnterInvestorStateFromListSelection {
  static readonly type = '[App] EnterInvestorState from List Selection';

  constructor(public investorUserId?: string) {}
}

export class ExitInvestorState {
  static readonly type = '[App] ExitInvestorState';
}


export class ToggleSideNav {
  static readonly type = '[App] ToggleSideNav';
}

export class CloseSideNav {
  static readonly type = '[App] CloseSideNav';
}

/* NavigateToDashboard */

export abstract class NavigateToDashboard {
  public allowRedirect = false;
}

export class NavigateToDashboardFromHomeComponent extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from HomeComponent';
}

export class NavigateToDashboardFromIsAdminGuard extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from IsAdminGuard';
}

export class NavigateToDashboardFromIsBrokerGuard extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from IsBrokerGuard';
}

export class NavigateToDashboardFromIsSupplierGuard extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from IsSupplierGuard';
}

export class NavigateToDashboardFromIsQualityAssessorGuard extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from IsQualityAssessorGuard';
}


export class NavigateToDashboardFromIsInvestorGuard extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from IsInvestorGuard';
}

export class NavigateToDashboardFromUnAuthenticatedGuard extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from UnAuthenticatedGuard';
}

export class NavigateToDashboardFromLogin extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from Login';

  // Log in is the only action that should allow for redirects.  Redirecting from guard actions may lead to an infinite redirect loop...
  allowRedirect = true;
}

export class NavigateToDashboardFromInvestorSelection extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from Investor Selection';
}

export class NavigateToDashboardFromExitInvestorState extends NavigateToDashboard {
  static readonly type = '[App] NavigateToDashboard from Exit Investor State';
}

/* Log in Modal */

export class ShowLoginModalFromAuthInterceptor {
  static readonly type = '[App] ShowLoginModal from AuthInterceptor';
}

export class SkipInvestorQuestions {
  static readonly type = '[App] SkipInvestorQuestions'
}

