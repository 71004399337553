import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Inject, Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerErrorComponent } from './server-error.component';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorService {
  private componentRef: ComponentRef<ServerErrorComponent>;
  private dismiss$: Subscription;

  constructor(
    @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(ApplicationRef) private appRef: ApplicationRef,
    @Inject(Injector) private injector: Injector,
  ) { }

  display() {
    if (this.componentRef === undefined) {
      // 1. Create a component reference from the component
      this.componentRef = this.componentFactoryResolver
        .resolveComponentFactory(ServerErrorComponent)
        .create(this.injector);

      // 2. Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(this.componentRef.hostView);

      // 3. Get DOM element from component
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

      // 4. Append DOM element to the body
      document.body.appendChild(domElem);

      this.dismiss$ = this.componentRef.instance.dismiss.subscribe(() => this.remove());
    }
  }

  remove() {
    if (this.componentRef) {
      this.dismiss$.unsubscribe();  // prevent memory leak.
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }

}
