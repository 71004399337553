import { Component, Inject, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../services/config.service';
import { UploaderOptions, UploadFile } from 'ngx-uploader';
import { Store } from '@ngxs/store';
import { BaseUploadComponent, UploaderState } from '../../classes/base-upload.component';


@Component({
  selector: 'app-video-upload-modal',
  templateUrl: './video-upload-modal.component.html',
  styleUrls: ['./video-upload-modal.component.scss']
})
export class VideoUploadModalComponent extends BaseUploadComponent {
  /** The title of the modal */
  title: string;
  video: any;
  immediate = true;
  options = {
    concurrency: 1,
    maxUploads: 1,
    allowedContentTypes: ['video/mp4'],
    maxFileSize: 2000 * 1024 * 1024
  } as UploaderOptions;

  constructor (
    @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(ConfigService) protected configService: ConfigService,
    @Inject(Store) protected store: Store,
  ) {
    super(configService, store);
    this.containerName = "uploaded-videos"
  }

  protected addedToQueue(file: UploadFile) {
    this.uploadFile(file, );

  }

  protected  uploadDone(file: UploadFile) {
    this.activeModal.close(file.response);
  }
}
