import { Action, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { AppStateModel, AuthStateModel, UserContextModel } from "./models";
import { filter, first, shareReplay, switchMap, tap } from "rxjs/operators";
import {
  AbstractSetUserState,
  ClearAppState,
  CloseSideNav,
  Login,
  Logout,
  NavigateToDashboardFromHomeComponent,
  NavigateToDashboardFromIsAdminGuard,
  NavigateToDashboardFromLogin,
  NavigateToDashboardFromUnAuthenticatedGuard,
  RefreshJWT,
  ReloadAppStateFromInit,
  ReloadAppStateFromLogin,
  RequireLoginFromAuthGuard,
  RequireLoginFromAuthInterceptor,
  SetTokenFromAuthInterceptor,
  SetUserStateFromAccountForm,
  SetUserStateFromIsAdminGuard,
  SetUserStateFromNavigateToDashboard,
  SetUserStateFromReloadAppState,
  SetUserStateFromUploadPicModal,
  ToggleSideNav,
  EnterInvestorStateFromListSelection,
  ExitInvestorState,
  NavigateToDashboardFromInvestorSelection,
  NavigateToDashboardFromExitInvestorState,
  SetUserStateFromIsBrokerGuard,
  NavigateToDashboardFromIsBrokerGuard,
  SetUserStateFromIsSupplierGuard,
  NavigateToDashboardFromIsSupplierGuard,
  SetUserStateFromIsQualityAssessorGuard,
  NavigateToDashboardFromIsQualityAssessorGuard,
  SetUserStateFromIsInvestorGuard,
  NavigateToDashboardFromIsInvestorGuard,
  CheckForUpdatedTermsOfUse,
  SetRedirect,
  NavigateToDashboard,
  ReloadAppStateFromInvestorDetailForm,
  ReloadAppStateFromConfirmTakeon,
  SkipInvestorQuestions

} from "./actions";
import { Inject, Injectable } from "@angular/core";
import { RedirectService } from "../shared/services/redirect.service";
import { ActivatedRoute, Router } from "@angular/router";
//FIXME import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { JwtHelper } from "../shared/classes/jwt-helper";
import { Observable, forkJoin, of } from "rxjs";
import * as data from "../shared/services/generated-api.service";
import { InvestorNotificationsService } from "../shared/push-notifications/investor-notifications.service";
import { AdminNotificationsService } from "../shared/push-notifications/admin-notifications.service";
import { SupplierNotificationsService } from "../shared/push-notifications/supplier-notifications.service";
import { QualityAssessorNotificationsService } from "../shared/push-notifications/quality-assessor-notifications.service";
import * as _ from "lodash";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TermsOfUseAcceptanceModalComponent } from "../shared/components/terms-of-use-acceptance-modal/terms-of-use-acceptance-modal.component";

const LOGIN_URL = "/login";

@Injectable()
@State<AuthStateModel>({
  name: "auth"
})
export class AuthState implements NgxsOnInit {
  private jwtHelper = new JwtHelper();

  constructor(
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
    @Inject(RedirectService) private redirectService: RedirectService,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    //FIXME @Inject(AppInsightsService) private appInsightsService: AppInsightsService,
  )
  { }

  @Selector()
  static token(state: AuthStateModel) { return state.token; }

  @Selector()
  static actingOnBehalfOfUserId(state: AuthStateModel) { return state.actingOnBehalfOfUserId; }

  /* Init */

  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    if (state.token && !this.jwtHelper.isTokenValid(state.token)) {
      ctx.patchState({ token: null });
    }
  }

  /* Commands */

  @Action(Login)
  login({ patchState, dispatch }: StateContext<AuthStateModel>, { token }: Login) {
    patchState({ token: token, actingOnBehalfOfUserId: null });
    return dispatch(new ReloadAppStateFromLogin()).pipe(
      switchMap(() => dispatch(new NavigateToDashboardFromLogin()))
    );
  }

  @Action(SetTokenFromAuthInterceptor)
  setToken({ patchState, dispatch }: StateContext<AuthStateModel>, { token }: Login) {
    patchState({ token: token });
  }

  @Action(RefreshJWT)
  refresh({ getState, patchState }: StateContext<AuthStateModel>) {
    const state: AuthStateModel = getState();
    return this.usersClient.refreshToken().pipe(
      tap(data => {
        patchState({ token: data.token });
      })
    );
  }

  // This function clears out the entire authentication state, starting fresh after login...
  @Action([Logout])
  unsetUserContext({ setState }: StateContext<AuthStateModel>) {
    //FIXME this.appInsightsService.setAuthenticatedUserContext(null);
    return setState({} as AuthStateModel);
  }

  // This function retains the actingOnBehalfOfUserId value, allowing the user to re-login via the dialog and continue where they left off without losing the impersonation context...
  @Action([RequireLoginFromAuthGuard, RequireLoginFromAuthInterceptor])
  unsetToken({ patchState }: StateContext<AuthStateModel>) {
    //FIXME this.appInsightsService.setAuthenticatedUserContext(null);
    return patchState({ token: null });
  }

  @Action([Logout, RequireLoginFromAuthGuard, RequireLoginFromAuthInterceptor])
  logout({ dispatch }: StateContext<AuthStateModel>, { returnToUrl }: SetRedirect) {
    return dispatch(new ClearAppState()).pipe(
      tap(() => {
        if (returnToUrl) {
          this.redirectService.redirect(LOGIN_URL, returnToUrl);
        } else {
          this.router.navigateByUrl(LOGIN_URL);
        }
      })
    );
  }

  @Action([
    EnterInvestorStateFromListSelection
  ])
  enterInvestorState({ getState, patchState }: StateContext<AuthStateModel>, { investorUserId }: { investorUserId: string }) {
    patchState({ actingOnBehalfOfUserId: investorUserId });
  }

  @Action([ExitInvestorState])
  ExitInvestorState({ patchState }: StateContext<AuthStateModel>) {
    patchState({ actingOnBehalfOfUserId: null });
  }
}

@Injectable()
@State<AppStateModel>({
  name: "app",
  defaults: { sideNavOpen: false }
})
export class AppState implements NgxsOnInit {
  private jwtHelper = new JwtHelper();


  constructor(
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
    @Inject(Store) private store: Store,
    @Inject(Router) private router: Router,
    @Inject(RedirectService) private redirectService: RedirectService,
    //FIXME @Inject(AppInsightsService) private appInsightsService: AppInsightsService,
    @Inject(InvestorNotificationsService) private investorNotificationService: InvestorNotificationsService,
    @Inject(AdminNotificationsService) private adminNotificationService: AdminNotificationsService,
    @Inject(SupplierNotificationsService) private supplierNotificationService: SupplierNotificationsService,
    @Inject(QualityAssessorNotificationsService) private qualityAssessorNotificationsService: QualityAssessorNotificationsService,
    @Inject(MatDialog) private dialog: MatDialog
  ) { }

  @Selector()
  static accountId(state: AppStateModel) { return state.userContext.user.investorAccount && state.userContext.user.investorAccount.id; }

  @Selector()
  static accountDetails(state: AppStateModel) { return state.userContext.user; }

  @Selector()
  static loggedInAccount(state: AppStateModel) { return state.userContext.loggedInUser; }

  @Selector()
  static userContext(state: AppStateModel) { return state.userContext; }

  @Selector()
  static dashboards(state: AppStateModel) { return state.userContext.dashboards; }

  @Selector()
  static sideNavOpen(state: AppStateModel) { return state.sideNavOpen; }


  ngxsOnInit(ctx: StateContext<AppStateModel>) {
    const token = this.store.selectSnapshot(AuthState.token);
    if (token && this.jwtHelper.isTokenValid(token)) {
      ctx.dispatch(new ReloadAppStateFromInit());
    }

  }

  @Action(ClearAppState)
  clearAppState({ setState }: StateContext<AppStateModel>) {
    this.investorNotificationService.disconnect();
    this.adminNotificationService.disconnect();
    this.supplierNotificationService.disconnect();
    this.qualityAssessorNotificationsService.disconnect();
    setState({ sideNavOpen: false });
  }

  @Action([
    ReloadAppStateFromInit,
    ReloadAppStateFromLogin,
    ReloadAppStateFromInvestorDetailForm,
    ReloadAppStateFromConfirmTakeon
  ])
  reloadAppState({ dispatch }: StateContext<AppStateModel>) {
    const actingOnBehalfOfUserId = this.store.selectSnapshot(AuthState.actingOnBehalfOfUserId);

    return forkJoin(
      this.usersClient.getLoggedInUserAccountDetails(),
      actingOnBehalfOfUserId ? this.usersClient.getUserAccountDetails(actingOnBehalfOfUserId) : of(null)
    ).pipe(tap(([loggedInUserAccount, actingOnBehalfOfUserAccount]) => {
      dispatch(new SetUserStateFromReloadAppState(loggedInUserAccount, actingOnBehalfOfUserAccount));
      //FIXME this.appInsightsService.setAuthenticatedUserContext(loggedInUserAccount.userId, null, true);
    }));
  }

  @Action([
    SetUserStateFromAccountForm,
    SetUserStateFromUploadPicModal,
    SetUserStateFromIsAdminGuard,
    SetUserStateFromIsBrokerGuard,
    SetUserStateFromIsSupplierGuard,
    SetUserStateFromIsQualityAssessorGuard,
    SetUserStateFromIsInvestorGuard,
    SetUserStateFromReloadAppState
  ])
  setUserAccount({ getState, patchState, dispatch }: StateContext<AppStateModel>, { loggedInUserAccount, actingOnBehalfOfUserAccount }: AbstractSetUserState) {

    let applicableAccount = actingOnBehalfOfUserAccount || loggedInUserAccount;
    if (!applicableAccount) {
      return true;
    }

    if (applicableAccount.isAdmin) {
      this.adminNotificationService.connect();
    }

    if (applicableAccount.supplierId) {
      this.supplierNotificationService.connect(applicableAccount.supplierId);
    }

    if (applicableAccount.qualityAssessorId) {
      this.qualityAssessorNotificationsService.connect(applicableAccount.qualityAssessorId);
    }

    if (applicableAccount.brokerId) {
    }

    if (applicableAccount.investorId) {
      this.investorNotificationService.connect(applicableAccount.investorId);
    }

    let userContext = _.clone(getState().userContext) || new UserContextModel();
    userContext.loggedInUser = loggedInUserAccount;
    actingOnBehalfOfUserAccount && (userContext.actingOnBehalfOfUser = actingOnBehalfOfUserAccount);
    patchState({ userContext: userContext });

    // Check whether there are new Terms of Use for the user to accept...
    dispatch(new CheckForUpdatedTermsOfUse());
  }

  @Action([
    CheckForUpdatedTermsOfUse
  ])
  CheckForUpdatedTermsOfUse({ getState }: StateContext<AppStateModel>) {
    if (getState().userContext.actingOnBehalfOfUser) return;

    this.usersClient.getNewTermsToAccept().subscribe(result => {
      if (result && result.length) {
        this.dialog.open(TermsOfUseAcceptanceModalComponent, <MatDialogConfig>{
          data: result,
          disableClose: true,
          panelClass: "header-mat-dialog",
          autoFocus: false
        })
      }
    });
  }

  @Action([
    EnterInvestorStateFromListSelection
  ])
  enterInvestorState({ getState, patchState, dispatch }: StateContext<AppStateModel>, { investorUserId }: { investorUserId: string }) {

    let userContext = _.clone(getState().userContext);

    return this.usersClient.getUserAccountDetails(investorUserId).pipe(tap(u => {
      userContext.actingOnBehalfOfUser = u;
      patchState({ userContext: userContext });
      dispatch(new NavigateToDashboardFromInvestorSelection());
    }),
      shareReplay(1));
  }

  @Action([
    ExitInvestorState
  ])
  exitInvestorState({ getState, patchState, dispatch }: StateContext<AppStateModel>) {
    let userContext = _.clone(getState().userContext);
    userContext.actingOnBehalfOfUser = null;
    patchState({ userContext: userContext });
    dispatch(new NavigateToDashboardFromExitInvestorState);
  }

  @Action(ToggleSideNav)
  toggleSideNav({ getState, patchState }: StateContext<AppStateModel>) {
    patchState({ sideNavOpen: !getState().sideNavOpen });
  }

  @Action(CloseSideNav)
  closeSideNav({ patchState }: StateContext<AppStateModel>) {
    patchState({ sideNavOpen: false });
  }

  @Action(SkipInvestorQuestions)
  skipInvestorQuestions({ patchState }: StateContext<AppStateModel>) {
  }

  @Action([
    NavigateToDashboardFromHomeComponent,
    NavigateToDashboardFromIsAdminGuard,
    NavigateToDashboardFromIsBrokerGuard,
    NavigateToDashboardFromIsSupplierGuard,
    NavigateToDashboardFromIsQualityAssessorGuard,
    NavigateToDashboardFromIsInvestorGuard,
    NavigateToDashboardFromUnAuthenticatedGuard,
    NavigateToDashboardFromLogin,
    NavigateToDashboardFromInvestorSelection,
    NavigateToDashboardFromExitInvestorState
  ])
  navigateToDashboard({ dispatch, setState, getState }: StateContext<AppStateModel>, { allowRedirect }: NavigateToDashboard) {

    let newState = _.clone(getState());
    setState(newState);

    // Wait until the dashboard state is set before navigating.
    return this.store.select(AppState.dashboards).pipe(
      tap(dashboards => {
        if (!dashboards) {
          dispatch(new SetUserStateFromNavigateToDashboard());
        }
      }),
      filter(dashboards => Boolean(dashboards)),
      first(),
      tap(dashboards => {
        if (dashboards.length === 0) {
          this.router.navigateByUrl("/account/no-profile");
        } else {
          this.redirectService.navigateToReturn(dashboards[0].url, allowRedirect);
        }
      }),
    );
  }

}
