import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as data from "../../shared/services/generated-api.service";
import { AppToasterService } from '../../shared/services/toaster.service';
import { Store } from '@ngxs/store';
import { Login } from '../../store/actions';
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-complete-disp-registration',
  templateUrl: './complete-disp-registration.component.html',
  styleUrls: ['./complete-disp-registration.component.scss']
})
export class CompleteDispRegistrationComponent implements OnInit {
  uid: string;
  dispToken: string;
  errorMsg: string;
  tokenIsValid = false;

  constructor(
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(Store) private store: Store,
    @Inject(MatDialog) private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dispToken = params['token'];
      this.usersClient.completeDispRegistration(this.dispToken).subscribe(
        data => {
          this.tokenIsValid = true;
          this.store.dispatch(new Login(data.token));
        },
        error => {
          this.errorMsg = JSON.parse(error.response)['non_field_errors'];
        }
      );
    });
  }
}
