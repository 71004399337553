import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decline-chat-dialog',
  templateUrl: './decline-chat-dialog.component.html',
  styleUrls: ['./decline-chat-dialog.component.scss']
})
export class DeclineChatDialogComponent implements OnInit {
  message: string;
  adminReply: string = '';

  constructor() { }

  ngOnInit() {
  }

}
