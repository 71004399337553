<div class="pre-auth-content remove-top-padding">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <h1 class="pb-sm-3">Bank ID Registration</h1>
        <div *ngIf="!tokenIsValid">
          <mat-spinner class="spinner-centre" *ngIf="!errorMsg"></mat-spinner>
          <div *ngIf="errorMsg">
            <p class="text-center">{{errorMsg}}</p>
            <p class="text-center">
              <a routerLink="/register">Please try again.</a>
            </p>
          </div>
        </div>
        <div *ngIf="tokenIsValid">
          <p class="text-center">
            Registration succeeded, logging in...
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

