import { Component, Inject } from '@angular/core';
import { UsersClient, TermsToAcceptDisplayModel } from '../../services/generated-api.service';
import { FormComponent } from '../../classes/form.component';
import { FieldOptions, FieldType } from '../field/field.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of, Observable } from 'rxjs';
import * as _ from "lodash";
import { AppToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-terms-of-use-acceptance-modal',
  templateUrl: './terms-of-use-acceptance-modal.component.html',
  styleUrls: ['./terms-of-use-acceptance-modal.component.scss']
})
export class TermsOfUseAcceptanceModalComponent extends FormComponent<any> {
  protected fields = [];

  constructor(
    public dialogRef: MatDialogRef<void>,
    @Inject(MAT_DIALOG_DATA) public data: TermsToAcceptDisplayModel[],
    @Inject(UsersClient) private usersClient: UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
  ) {
    super(toastr);
  }

  createForm() {
    let fieldOptions = _.fromPairs(this.data.map(a => [a.id, new FieldOptions({
      name: a.name,
      label: `I accept the updated <a href="${a.url}" download target="_blank">${a.name}</a>`,
      type: FieldType.checkbox,
      allowBlank: false,
      allowNull: false
    })]));

    this.fields = this.data.map(a => a.id);

    this.buildFromOptions(of(fieldOptions), of(this.data));
  }

  handleSubmitSuccess(obj: any): void {
    this.dialogRef.close();
  }

  submitData(formData: any): Observable<any> {
    return this.usersClient.acceptTerms(this.fields);
  }

}
