import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-picture-carousel-modal',
  templateUrl: './picture-carousel-modal.component.html',
  styleUrls: ['./picture-carousel-modal.component.scss']
})
export class PictureCarouselModalComponent {
  activeIndex = 0;
  images: string[];

  constructor(@Inject(NgbActiveModal) public activeModal: NgbActiveModal) { }

  closeModal() {
    this.activeModal.close();
  }

}
