import { EventEmitter, Inject, OnDestroy, OnInit, Directive } from '@angular/core';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { ConfigService } from '../../services/config.service';
import { Store } from '@ngxs/store';
import { AuthState } from '../../store/states';

export enum UploaderState {
  init,
  selectFile,
  crop,
  uploading,
}

export interface UploadedFile {
  file: UploadFile;
  url: string;
}


@Directive()
export abstract class BaseUploadComponent implements OnInit, OnDestroy {
  maxFilesize: number;
  busy = false;
  files: UploadFile[] = [];
  uploadInput = new EventEmitter<UploadInput>();
  dragOver: boolean;
  error: string;
  containerName : string;
  state = UploaderState.selectFile;
  uploaderState = UploaderState;
  options: UploaderOptions;
  protected immediate = false;
  private optionsDefault = {
    concurrency: 1,
    maxUploads: 1,
    allowedContentTypes: null
  };

  constructor (
    @Inject(ConfigService) protected configService: ConfigService,
    @Inject(Store) protected store: Store) {
  }

  ngOnInit() {
    this.maxFilesize = this.configService.settings.maxFileUploadSize;
    this.state = UploaderState.selectFile;
  }

  ngOnDestroy(): void {
    this.uploadInput.emit({type: 'cancelAll'} as UploadInput);
  }

  uploadFailed() {
    this.busy = false;
    this.error = 'Oh no, the upload failed.';
    this.state = UploaderState.selectFile;  // Reset uploader state
  }

  /**
   * Called when a file has been added.
   * @param {UploadFile} file
   */
  protected abstract addedToQueue(file: UploadFile);

  protected uploadFile(file: UploadFile, importType?: string) {
    const token = this.store.selectSnapshot(AuthState.token);

    this.state = UploaderState.uploading;
    this.busy = true;
    this.error = null;

    let url = '';
    switch (importType) {
      case "supplier":
        url = './api/suppliers/importSuppliers';
        break;
      case "mailing-list":
        url = './api/uploads/import/mailing-list';
        break;
      case "task":
        url = './api/weeks-task/importTasks'
        break;
      case "material-catalogs":
        url = './api/weeks-material-catalog/importMaterialCatalogs';
        break;
      case "materials":
        url = './api/weeks-materials/import-materials';
        break;
      default:
        url = `./api/uploads/${this.containerName}`;
        break;
    } 

    const event: UploadInput = {
      type: 'uploadFile',
      url: url,
      method: 'POST',
      headers: { 'Authorization': 'bearer ' + token }, 
      file: file,
      data: { filename: file.name }
    };

    this.uploadInput.emit(event);
  }

  protected importData(file: UploadFile, importType?: string) {
    const token = this.store.selectSnapshot(AuthState.token);

    this.state = UploaderState.uploading;
    this.busy = true;
    this.error = null;
    let url = '';    
    switch (importType) {
      case "supplier":
        url = './api/suppliers/importSuppliers';
        break;
      case "mailing-list":
        url = './api/uploads/import/mailing-list';
        break;
      case "task":
        url = './api/weeks-task/importTasks';
        break;
      case "material-catalogs":
        url = './api/weeks-material-catalog/importMaterialCatalogs';        
        break;
      case "materials":
        url = './api/weeks-materials/import-materials';
        break;
      default:
        url = `./api/uploads/${this.containerName}`;
        break;
    }    

    const event: UploadInput = {
      type: 'uploadFile',
      url: url,
      method: 'POST',
      headers: { 'Authorization': 'bearer ' + token },
      file: file,
      data: { filename: file.name }
    };

    this.uploadInput.emit(event);
  }

  /**
   * Called once the upload is done.
   * @param {UploadFile} file
   */
  protected abstract uploadDone(file: UploadFile);

  protected immediateUploadDone(file: UploadFile) {}


  /**
   * Handles upload output events from `ngFileDrop` and `ngFileSelect`.
   * @param {UploadOutput} output
   */
  onUploadOutput(output: UploadOutput, importType?: string): void {
    const token = this.store.selectSnapshot(AuthState.token);
    let url = '';
    switch (importType) {
      case "supplier":
        url = './api/suppliers/importSuppliers';
        break;
      case "mailing-list":
        url = './api/uploads/import/mailing-list';
        break;
      case "task":
        url = './api/weeks-task/importTasks';
        break;
      case "material-catalogs":
        url = './api/weeks-material-catalog/importMaterialCatalogs';
        break;
      case "materials":
        url = './api/weeks-materials/import-materials';
        break;
      default:
        url = `./api/uploads/${this.containerName}`;
        break;
    }    

    if (output.type === 'allAddedToQueue' && this.immediate) { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      const event: UploadInput = {
        type: 'uploadAll',
        url: url,
        method: 'POST',
        headers: { 'Authorization': 'bearer ' + token }, 
      };
      this.uploadInput.emit(event);
      return;
    }

    if (output.type === 'addedToQueue'  && typeof output.file !== 'undefined') { // add file to array when added
      this.addedToQueue(output.file);
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected') {
      this.error = 'Invalid file. Please upload a supported file type.';
    } else if (output.type === 'done') {
      if (output.file.responseStatus === 201) {
        this.uploadDone(output.file);
      } else {
        this.uploadFailed();
      }
    }
  }

  protected submitDisabled(): boolean {
    return this.files.length === 0;
  }

}
