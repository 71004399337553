import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-value-display',
  templateUrl: './value-display.component.html',
})
export class ValueDisplayComponent {
  @Input() value: any;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  isBoolean(): boolean {
    return typeof(this.value) === 'boolean';
  }

  hasContent() {
    return this.wrapper.nativeElement.childNodes.length > 0;
  }
}
