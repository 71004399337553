<header class="dashboard-header fixed-top">
  <div class="logo-bar">
    <span class="navbar-toggle" (click)="hamburgerClicked()"><fa-icon icon="bars"></fa-icon></span>
    <a class="navbar-brand" routerLink="./">
      <img *ngIf="!sandboxToggle && !alternateNameToggle" src="./assets/images/munio-logo.svg" alt="Munio">
      <img *ngIf="sandboxToggle && !alternateNameToggle" src="./assets/images/munio-sandbox.svg" alt="Sandbox">
      <img *ngIf="!sandboxToggle && alternateNameToggle" src="{{alternateNameLogoUrl}}" alt="{{alternateName}}">
      <img *ngIf="sandboxToggle && alternateNameToggle" src="{{alternateNameSandboxLogoUrl}}" alt="Sandbox">
    </a>
  </div>

  <!--
  <div class="balance">
    <fa-icon icon="coins"></fa-icon>
    <strong class="amount">0.00</strong><br>
    <small class="text-uppercase">Available</small>
  </div>
  -->

  <div class="notifications pt-1" *ngIf="user.userTypeId != 1 && showMessages" routerLinkActive="active">
    <a routerLink="./messages">
      <fa-icon icon="bell"></fa-icon>
    </a>
    <div class="badge" style="color: black">Messages</div>
  </div>

  <div class="header-actions">
    <button id="profileList" *ngIf="!sandboxToggle" class="action" mat-flat-button style="padding: 0;background-color: transparent" [ngbPopover]="accountContent" #accountPopover="ngbPopover" placement="bottom-right" popoverClass="account-popover" appClosePopover>
      <div class="user-profile">
        <app-profile-pic class="profile-pic-sm" [user]="(account$ | async)">
        </app-profile-pic>
        <div class="centered-container">
          <span class="logged-in-user-name" *ngIf="(userContext$ | async)?.isActingOnBehalfOfAnotherUser">{{(userContext$ | async)?.loggedInUser | userName}}</span>
          <span class="on-behalf-of" *ngIf="(userContext$ | async)?.isActingOnBehalfOfAnotherUser">on behalf of</span>
          <span class="name">{{(account$ | async) | userName}}</span>
        </div>
        <div class="centered-container">
          <fa-icon icon="chevron-down"></fa-icon>
        </div>
      </div>
    </button>
    <div *ngIf="sandboxToggle">
      <a id="tour-step-4" href="{{baseUrl}}/register" class="btn block btn-block-xs btn-primary">Register a real account</a>
    </div>
  </div>
</header>

<ng-template #accountContent>
  <div class="account-info" *ngIf="account$ | async as account">
    <div class="name"><strong>{{account | userName}}</strong></div>
    {{ account.email }}
    <ul>
      <li class="top" routerLinkActive="active">
        <a routerLink="./profile" (click)="accountPopover.close()">
          <fa-icon id="manageProfile" icon="user-cog"></fa-icon> Manage Profile
        </a>
      </li>
      <li class="top" *ngFor="let dashboard of dashboards$ | async" routerLinkActive="active">
        <ng-container>
          <a [routerLink]="dashboard.url">
            <fa-icon [icon]="dashboard.icon"></fa-icon> {{dashboard.name}}
          </a>
        </ng-container>
      </li>
      <li class="top" routerLinkActive="active" *ngIf="!(userContext$ | async)?.isActingOnBehalfOfAnotherUser">
        <a routerLink="./change-password" (click)="accountPopover.close()">
          <fa-icon icon="key"></fa-icon> Change Password
        </a>
      </li>
      <li class="bottom" *ngIf="!(userContext$ | async)?.isActingOnBehalfOfAnotherUser">
        <a routerLink="" (click)="logout()"><fa-icon icon="sign-out-alt"></fa-icon> Log Out</a>
      </li>
      <li class="bottom" *ngIf="(userContext$ | async)?.isActingOnBehalfOfAnotherUser">
        <a routerLink="" (click)="leaveInvestorContext()">
          <fa-icon icon="sign-out-alt"></fa-icon>Exit Investor View</a>
      </li>
    </ul>
  </div>
</ng-template>
