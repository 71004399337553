import { Injectable, Inject } from '@angular/core';
import { ConfigService } from './config.service';

declare let twq: Function;

@Injectable({
  providedIn: 'root'
})


export class SocialAnalyticsService {

  constructor(@Inject(ConfigService) private configService: ConfigService) { }

  loadService() {
    this.loadTwitterService();
    this.loadFacebookService();
    this.loadLinkedInService();
  }

  private loadLinkedInService() {
    const linkedInTrackingId = this.configService.settings.linkedInTrackingId;

    window["_linkedin_data_partner_ids"] = window["_linkedin_data_partner_ids"] || [];
    window["_linkedin_data_partner_ids"].push(linkedInTrackingId);

    const noscript = document.createElement('noscript');
    const img = document.createElement('img');

    img.height = 1;
    img.width = 1;
    img.style.display = "none";
    img.src = `https://px.ads.linkedin.com/collect/?pid=${linkedInTrackingId}&fmt=gif`;

    noscript.appendChild(img);
    document.head.appendChild(noscript);
  }

  private loadTwitterService() {
    const twitterTrackingId = this.configService.settings.twitterTrackingId;
    if(!window["twq"]) { return; }

    window["twq"]('init',twitterTrackingId);
    window["twq"]('track','PageView');
  }

  private loadFacebookService() {
    const facebookTrackingId = this.configService.settings.facebookTrackingId;
    if(!window["fbq"]) { return; }


    window["fbq"]('init', facebookTrackingId);
    window["fbq"]('track', 'PageView');

    const noscript = document.createElement('noscript');
    const img = document.createElement('img');

    img.height = 1;
    img.width = 1;
    img.style.display = "none";
    img.src = `https://www.facebook.com/tr?id=${facebookTrackingId}&ev=PageView&noscript=1`;

    noscript.appendChild(img);
    document.head.appendChild(noscript);
  }
}