<div mat-dialog-content>
  <div class="container">
    <div class="row justify-content-center">
      <h1 mat-dialog-title>Log In</h1>
    </div>
    <div class="row justify-content-center">
      <app-profile-pic class="profile-pic-md" [user]="account"></app-profile-pic>
    </div>
    <div class="row justify-content-center account-email">
      <p>{{account?.emailAddress}}</p>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form; else loading" novalidate class="login-form">

    <app-form-errors [formGroup]="form"></app-form-errors>
    <app-form-field [fieldOption]="options['password']" autocomplete="current-password"></app-form-field>
    <app-form-field [fieldOption]="options['verificationToken']"></app-form-field>

    <p *ngIf="account.mfaTypeId == 2" class="mtn text-right small"><a href="" onclick="return false;" (click)="sendMfaSms()">Resend verification code</a></p>
  </form>


  <ng-template #loading>
    <div>
      <div class="shadow-field"></div>
    </div>
  </ng-template>

</div>
<div mat-dialog-actions align="center">
  <button class="btn btn-default" [mat-dialog-close]>Cancel</button>
  <app-submit (clicked)="onSubmit()" [busy]="busy" label="Log In" btnClass="btn btn-primary"></app-submit>

  <!--
  <button *ngIf="!sandboxToggle" mat-flat-button class="bankId" [disabled]="bankIdBusy" (click)="bankIdLogin()">
    <span *ngIf="bankIdBusy">Busy</span>
    <span *ngIf="!bankIdBusy">Bank ID</span>
  </button>
  -->
</div>
