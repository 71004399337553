import * as data from "../shared/services/generated-api.service";

export interface AuthStateModel {
  token?: string;
  actingOnBehalfOfUserId?: string;
}

/**
 * Dashboard types to be able to select Icons etc.
 */
export enum DashboardType {
  Investor,
  Broker,
  Admin,
  Supplier,
  QualityAssessor
}

export interface Dashboard {
  type: DashboardType;
  url: string | string[];
  name: string;
  icon: string;
}

export interface AppStateModel {
  sideNavOpen: boolean;
  userContext?: UserContextModel;
}

export class UserContextModel {
  public loggedInUser? : data.IUserAccountModel;
  public actingOnBehalfOfUser?: data.IUserAccountModel;

  public get user() {
    return this.actingOnBehalfOfUser || this.loggedInUser;
  }

  public get isActingOnBehalfOfAnotherUser() {
    return !!this.actingOnBehalfOfUser;
  }

  public get canTransact() {
    // Investors can only transact directly - no impersonation allowed...
    if (this.actingOnBehalfOfUser || this.user.investorStatusId === 3) return false;
    return true;
  }

  public get mustLiquidate() {
    if (this.user.investorRequiresTakeOnConfirmation) return true;
    return false;
  }


  public get canAdminTransactOnBehalfOf() {
    // There are some cases where Admins can transact on behalf of Investors    
    if ((this.actingOnBehalfOfUser && this.loggedInUser.userTypeId == 1) || !this.actingOnBehalfOfUser)
    {
      return true;
    }

    return false;
  }

  public get dashboards() {
    const result = [];
    if (this.user.isAdmin) result.push({ type: DashboardType.Admin, url: '/admin', name: "Admin Dashboard", icon: "tools" } as Dashboard);
    if (this.user.brokerId) result.push({ type: DashboardType.Broker, url: '/broker/investors', name: "My Investors", icon: "users" } as Dashboard);
    if (this.user.supplierId) result.push({ type: DashboardType.Supplier, url: '/supplier', name: "My Work", icon: "drafting-compass" } as Dashboard);
    if (this.user.qualityAssessorId) result.push({ type: DashboardType.QualityAssessor, url: '/qualityAssessor', name: "My Work", icon: "drafting-compass" } as Dashboard);
    if (this.user.investorId) result.push({ type: DashboardType.Investor, url: '/investor', name: "My Investments", icon: "chart-line" } as Dashboard);
    return result;
  }
}
