<div class="pre-auth-content remove-top-padding">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <!-- UID and Token is invalid -->
        <div *ngIf="!tokenIsValid">
          <mat-spinner class="spinner-centre" *ngIf="!errorMsg"></mat-spinner>
          <div *ngIf="errorMsg">
            <h2 class="pb-sm-3">{{errorMsg}}</h2>
            <h5 class="text-center mt-5">
              <a routerLink="/login" class="btn btn-primary">Log In</a>
            </h5>
          </div>
        </div>

        <!-- UID and Token is valid -->
        <div *ngIf="tokenIsValid">
          <h1 class="pb-sm-2">
            <span class="small">Hi {{account.name_first}}</span><br>
            Welcome to {{companyName}}
          </h1>
          <h2 class="pb-sm-2">Create your password to access your account</h2>

          <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form && !successMsg" novalidate>
            <app-form-errors [formGroup]="form"></app-form-errors>
            <app-form-field [fieldOption]="options['newPassword']" autocomplete="new-password"></app-form-field>
            <app-submit [busy]="busy" btnClass="btn btn-primary btn-lg"></app-submit>
          </form>
          <div class="loading-blocks" *ngIf="!form">
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-btn shadow-btn-lg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
