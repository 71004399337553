<div class="modal-header">
  <h5 class="modal-title text-uppercase text-primary">{{ title }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<ng-template #loading>
  <div class="modal-body modal-body-loading">
    <app-loader></app-loader>
  </div>
</ng-template>
<div class="modal-body">
  <div [ngClass]="{'hidden': state !== uploaderState.selectFile}">
    <div class="drop-container" [ngClass]="{'is-drop-over': dragOver}"
         ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
      <fa-icon size="3x" icon="cloud-upload-alt"></fa-icon>
      <label class="upload-button btn btn-sm btn-outline-primary">
        <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
        Choose a file
      </label>
      or drag and drop it here.
    </div>
    <p>
      <app-upload-file-requirements></app-upload-file-requirements>
    </p>
  </div>
  
  <div *ngIf="state === uploaderState.uploading && !error">
    <div class="upload-item" *ngFor="let f of files;">
      <p class="mb-2 small">Uploading file</p>
      <ngb-progressbar type="success" [value]="f?.progress?.data?.percentage">
        {{ f?.progress?.data?.percentage < 100 ? f?.progress?.data?.percentage + '%' : 'Done' }}
      </ngb-progressbar>
    </div>
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="error">
    {{error}}
  </div>

</div>

