import { Component, Inject, OnInit } from "@angular/core";
import * as api from "../../../../../shared/services/generated-api.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppToasterService } from "../../../../../shared/services/toaster.service";

@Component({
  selector: "app-message-detail-modal",
  templateUrl: "./message-detail-modal.component.html",
  styleUrls: ["./message-detail-modal.component.scss"]
})
export class MessageDetailModalComponent implements OnInit {

  messageHtml: string;
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: api.EmailDisplayModel,
    @Inject(api.UploadsClient) private uploadsClient: api.UploadsClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(api.EmailsClient) private emailsClient: api.EmailsClient,
  ) { }

  ngOnInit() {
    this.uploadsClient.getHtmlFileContent("private-documents", this.data.id).subscribe(html => {
      this.messageHtml = html,
      this.isLoading = false,
      this.loadMessage()
    }, () =>
      this.handleError());
  }

  private loadMessage() {
    if (!this.isLoading) {
      document.getElementById("message-viewer").innerHTML = this.messageHtml;
    }
  }

  protected viewDocument(url: string) {
    this.uploadsClient.secureUrl(new api.SecureBlobUrlRequest({
      url: url,
      container: "private-documents"
    })).subscribe(secureUrl => {
      window.open(secureUrl, "_blank");
    },
      () => this.handleError());
  }

  private handleError() {
    this.toastr.error("Failed to load message.");
  }

}
