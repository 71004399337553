import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RetryRequestInterceptorService } from './retry-request-interceptor.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryRequestInterceptorService,
      multi: true
    },
  ],
})
export class RetryRequestsModule { }
