<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
  <app-form-errors [formGroup]="form"></app-form-errors>
  <div class="alert alert-success" role="alert" *ngIf="form && form.errors && form.errors.server && form.errors.server[0].indexOf('verified') > -1">
    If you did not receive your verification mail, you can <a href="/confirm-email">request a new link here</a>.
  </div>
  <app-form-field [fieldOption]="options['password']" autocomplete="current-password"></app-form-field>
  <p class="mtn text-right small"><a routerLink="/forgot-password" class="forgot-password">Forgot password?</a></p>

  <button id="nextPassword" mat-flat-button color="primary" class="float-right mb-4 mt-4" matStepperNext>{{ requiresVerification ? 'Next' : 'Log in' }}</button>
</form>
<!--<button *ngIf="!sandboxToggle" mat-flat-button class="bankId" (click)="bankIdLogin()">Log in with Bank ID</button>-->

<div class="loading-blocks" *ngIf="!form">
  <div class="shadow-field shadow-field-lg"></div>
  <div class="shadow-btn shadow-btn-lg"></div>
  <div class="shadow-btn shadow-btn-lg"></div>
</div>
