import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AppState } from '../../store/states';
import { NavigateToDashboardFromIsAdminGuard, SetUserStateFromIsAdminGuard } from '../../store/actions';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(
    @Inject(Store) private store: Store,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /*
    The following code will attempt to get the user account details.
    If it is not in the store it will call an action to fetch it.
    It will filter the select until the data is available, and only take the first emit that has a value.
    The guard will wait until the data is available.
    */
    return this.store.select(AppState.accountDetails).pipe(
      tap(account => {
        if (!account) {
          this.store.dispatch(new SetUserStateFromIsAdminGuard());
        }
      }),
      filter(account => Boolean(account)),
      first(),
      map(account => account.isAdmin),
      tap(canActivate => {
        if (!canActivate) {
          this.store.dispatch(new NavigateToDashboardFromIsAdminGuard());
        }
      }),
    );
  }
}
