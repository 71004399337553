import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { FormComponent } from "../../shared/classes/form.component";
import * as api from "../../shared/services/generated-api.service";
import { FieldOptions, FieldType, InputType } from "../../shared/components/field/field.component";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"]
})
export class ContactUsComponent extends FormComponent<api.UserRegistrationResponse> implements OnInit {
  successMsg: string;
  protected fields = ["jobTitle", "fullName", "emailAddress", "reasonForInterest", "recaptcha"];

  constructor(
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(Router) private router: Router,
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createForm() {
    let fieldOptions = {
      "jobTitle": new FieldOptions({
        name: "jobTitle",
        label: "Job Title",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: true,
        readOnly: false
      }),
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "fullName": new FieldOptions({
        name: "fullName",
        label: "Full Name",
        type: FieldType.input,
        inputType: InputType.text,
        maxLength: 255,
        allowBlank: false,
        allowNull: true,
        readOnly: false
      }),
      "reasonForInterest": new FieldOptions({
        name: "reasonForInterest",
        label: "Message",
        minRows: 3,
        type: FieldType.textArea,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "recaptcha": new FieldOptions({
        name: "recaptcha",
        label: "Recaptcha",
        type: FieldType.recaptcha,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    this.buildFromOptions(of(fieldOptions));
  }

  submitData(): Observable<api.ContactUsResponse> {
    return this.usersClient.contactUs({...this.form.value});
  }

  protected handleSubmitError(error: any) {
    this.form.controls.recaptcha.reset();
    if (error.status === 400) {
      this.attachFormErrors(this.form, JSON.parse(error.response));
    }
  }

  handleSubmitSuccess(respData: api.UserRegistrationResponse) {
    this.successMsg = respData.message;
  }
}
