import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-route-resolve-error',
  templateUrl: './route-resolve-error.component.html',
  styleUrls: ['./route-resolve-error.component.scss']
})
export class RouteResolveErrorComponent implements OnInit, OnDestroy {
  status: number;
  statusText: string;
  subscription = new Subscription();

  constructor(@Inject(ActivatedRoute) protected route: ActivatedRoute) { }

  ngOnInit() {
    this.subscription.add(this.route.params.subscribe(params => {
      this.status = parseInt(params['status'], 10);
      this.statusText = params['statusText'];
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  reload() {
    // @ts-ignore
    window.location.reload(false);
  }

}
