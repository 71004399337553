import { Pipe, PipeTransform } from '@angular/core';

export interface PrettyNumberOptions {
  decimalPlaces: number,
  suffix: string
}

@Pipe({
  name: 'prettyNumber'
})
export class PrettyNumberPipe implements PipeTransform {

  default = <PrettyNumberOptions>{ decimalPlaces: 1, suffix: "" };

  transform(value: number, decimalPlaces?: number, suffix?: string): any {

    const options = <PrettyNumberOptions>{ ...this.default, decimalPlaces, suffix };

    if (Math.abs(value) > 1000000) {
      return `${(value / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: options.decimalPlaces })}M${options.suffix ? " " : ""}${options.suffix || ""}`;
    }
    else if (Math.abs(value) > 1000) {
      return `${(value / 1000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: options.decimalPlaces })}k${options.suffix ? " " : ""}${options.suffix || ""}`;
    }
    else {
      return `${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: options.decimalPlaces })}${options.suffix ? " " : ""}${options.suffix || ""}`;
    }
  }
}
