<div class="pre-auth-content remove-top-padding">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <h1 class="pb-sm-3">Email Verification</h1>

        <div *ngIf="!tokenIsValid">
          <mat-spinner class="spinner-centre" *ngIf="tokenIsValid === null"></mat-spinner>
          <div *ngIf="tokenIsValid === false">
            <div class="alert alert-danger" role="alert">
              This e-mail confirmation link has expired or is invalid.
            </div>
            <br>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form && !successMsg" novalidate>
              <app-form-errors [formGroup]="form"></app-form-errors>
              <app-form-field [fieldOption]="options['emailAddress']" autocomplete="email"></app-form-field>
              <app-form-field [fieldOption]="options['recaptcha']"></app-form-field>
              <app-submit [busy]="busy" label="Send new request" [disabled]="!form.valid" btnClass="btn btn-primary btn-lg"></app-submit>
            </form>
            <div class="loading-blocks" *ngIf="!form">
              <div class="shadow-field shadow-field-lg"></div>
              <div class="shadow-captcha"></div>
              <div class="shadow-btn shadow-btn-lg"></div>
            </div>
          </div>

          <div *ngIf="newRequestSucceeded" class="text-center pb-4">
            <p><img src="img/thumbs-up.svg" alt="" height="99" /></p>
            <h2 class="smaller">If the email address supplied is associated with an account on this system, an email will arrive in your inbox shortly with a confirmation link.</h2>
            <br>
          </div>
        </div>

        <div *ngIf="tokenIsValid" class="text-center pb-4">
          <p><img src="./assets/images/thumbs-up.svg" alt="" height="99" /></p>
          <h2 class="smaller">Your email address has been successfully confirmed.</h2>
          <br>
          <a class="btn block btn-block-xs btn-primary" routerLink="/login">Log In</a>
        </div>
      </div>
    </div>
  </div>
</div>
