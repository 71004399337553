import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IBreadcrumb } from '../breadcrumbs.shared';
import { BreadcrumbsService } from '../service/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  template:
`<ol *ngIf="crumbs.length" class="breadcrumb">
    <li *ngFor="let crumb of crumbs; let last = last" [ngClass]="{ 'active': last }" class="breadcrumb-item">
      <a *ngIf="!last" [routerLink]="crumb.path">{{ crumb.text }}</a>
      <span *ngIf="last">{{ crumb.text }}</span>
    </li>
  </ol>`,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  /** If breadcrumbs should be displayed if there is only one crumb */
  @Input() displaySingle = false;

  constructor(public service: BreadcrumbsService) {}

  crumbs: IBreadcrumb[];
  subscriptions: Subscription = new Subscription();

  public ngOnInit(): void {
    this.subscriptions.add(this.service.crumbs$.subscribe(x => {
      if (!this.displaySingle && x.length === 1) {
        this.crumbs = x.slice(1);
      } else {
        this.crumbs = x;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
