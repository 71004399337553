import { Component, Inject } from '@angular/core';
import * as api from "../../../shared/services/generated-api.service";
import { Observable, of } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
import { BankAccountAddedModalComponent } from '../bank-account-added-modal/bank-account-added-modal.component';
import { UserContextModel } from '../../../store/models';
import { AppState } from '../../../store/states';
import { Select } from '@ngxs/store';
import { FormComponent } from '../../classes/form.component';
import { AppToasterService } from '../../services/toaster.service';
import { ModalService } from '../../services/modal.service';
import {FieldOptions, FieldType, InputType} from '../field/field.component';
import {DocumentUploadModalComponent} from "../document-upload-modal/document-upload-modal.component";

@Component({
  selector: 'app-bank-account-edit-modal',
  templateUrl: './bank-account-edit-modal.component.html',
  styleUrls: ['./bank-account-edit-modal.component.scss']
})
export class BankAccountEditModalComponent extends FormComponent<api.BankAccountInputModel> {

  protected fields = [];
  @Select(AppState.userContext) userContext$: Observable<UserContextModel>;
  userContext: UserContextModel;
  private usesBankId = false;
  constructor(
    public dialogRef: MatDialogRef<BankAccountEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public bankAccount: api.BankAccountInputModel,
    @Inject(api.BankAccountsClient) private bankAccountsClient: api.BankAccountsClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(api.LookupsClient) private lookupsClient: api.LookupsClient,
    @Inject(MatDialog) private dialog: MatDialog,
    @Inject(ModalService) protected modalService: ModalService
  ) {
    super();
  }

  ngOnInit() {

    this.userContext$.subscribe(value => {
      this.usesBankId = value.user.usesBankId;
    });

    super.ngOnInit();
  }

  createForm() {
    let model = this.form ?
      { ...this.bankAccount, ...this.form.value } :
      this.bankAccount;

    let isVerificationDocOptional = this.usesBankId;

    const baseFieldOptions = {
      "name": new FieldOptions({
        name: "name",
        label: "Description",
        hint: "Unique description used to identify this bank account.",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "bankAccountTypeId": new FieldOptions({
        name: "bankAccountTypeId",
        label: "Bank Account Type",
        type: FieldType.choice,
        inputType: InputType.text,
        choices$: this.lookupsClient.getBankAccountTypes(),
        defaultValue: 1, // default to international
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    const norwegianFields = {
      "accountNumber": new FieldOptions({
        name: "accountNumber",
        label: "Account Number",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "verificationDocumentLink": new FieldOptions({
        name: "verificationDocumentLink",
        label: "Upload Verification Documentation",
        hint: "Bank statement or similar document to verify account details and ownership",
        type: FieldType.file,
        fileUrl$: this.getFileUrl(),
        allowBlank: isVerificationDocOptional,
        allowNull: isVerificationDocOptional,
        readOnly: false
      })
    };

    const internationalFields = {
      "accountHolderName": new FieldOptions({
        name: "accountHolderName",
        label: "Account Holder Name",
        hint: "The name on this bank account must match the investor's name.",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "accountNumber": new FieldOptions({
        name: "accountNumber",
        label: "IBAN",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "bankName": new FieldOptions({
        name: "bankName",
        label: "Bank Name",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "bankAddress": new FieldOptions({
        name: "bankAddress",
        label: "Bank Address",
        hint: "The address of your bank, including the country.",
        type: FieldType.textArea,
        allowBlank: false,
        allowNull: false,
        readOnly: false,
        minRows: 4
      }),
      "swiftbic": new FieldOptions({
        name: "swiftbic",
        label: "SWIFT Bank Identification Code (Optional)",
        type: FieldType.input,
        inputType: InputType.text,
        maxLength: 11,
        allowBlank: true,
        allowNull: true,
        readOnly: false
      }),
      "verificationDocumentLink": new FieldOptions({
        name: "verificationDocumentLink",
        label: "Upload Verification Documentation",
        hint: "Bank statement or similar document to verify account details and ownership",
        type: FieldType.file,
        fileUrl$: this.getFileUrl(),
        allowBlank: isVerificationDocOptional,
        allowNull: isVerificationDocOptional,
        readOnly: false
      })
    };

    let fieldOptions =
      model.bankAccountTypeId === 1 ?
      { ...baseFieldOptions, ...internationalFields } : model.bankAccountTypeId === 2 ?
        { ...baseFieldOptions, ...norwegianFields } :
        { ...baseFieldOptions };

    this.fields = _.keys(fieldOptions);

    this.buildFromOptions(of(fieldOptions), of(model));

    let changeSubscription = this.form.controls["bankAccountTypeId"].valueChanges.subscribe((val) => {
      changeSubscription.unsubscribe();
      this.form.patchValue({ "bankAccountTypeId": val });
      this.createForm();

    });
  }

  handleSubmitSuccess(): void {

    if (!this.bankAccount["id"]) {
      this.dialog.open(BankAccountAddedModalComponent, {
        data: { accountName: this.form.value.name, usesBankId: this.usesBankId },
        panelClass: "header-mat-dialog"
      });
    }

    this.dialogRef.close(true);
  }

  submitData(formData): Observable<any> {
    if (this.bankAccount["id"]) {
      return this.bankAccountsClient
        .update(this.bankAccount["id"], <any>{ ...this.apiData, ...formData });
    } else {
      return this.bankAccountsClient
        .create(<any>{ ...this.apiData, ...formData });
    }
  }

  cancel() {
    this.dialogRef.close(true);
  }

  getFileUrl() {
    return () => {
      const modalRef = this.modalService.open(DocumentUploadModalComponent, { windowClass: "modal-9", backdropClass: "modal-backdrop-9" });
      modalRef.componentInstance.document = { label: "Bank Account Document" };
      modalRef.componentInstance.private = true;

      return modalRef.result
    }
  }
}

