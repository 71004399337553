import { Component, Inject, HostBinding, Input } from '@angular/core';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class ProfilePicComponent {
  @Input() user: { firstName: string, lastName: string, userTypeId: number };
  @HostBinding('class') @Input('class') class = "purple-background";

  constructor(
    @Inject(ConfigService) private configService: ConfigService,
  ) { }

  getInitials(user) {
    if (!user) return "??";

    return `${user.firstName && user.firstName.charAt(0)}${user.lastName && user.lastName.charAt(0)}`;
  }

  getBackgroundClass(user) {
    if (!user) return "purple-background";

    if (this.configService.settings.featureToggles.sandbox)
      return "gray-background";

    switch (user.userTypeId) {
      case 1:
        return "purple-light-background";
      case 2:
        return "purple-background";
      case 3:
        return "blue-background";
      case 4:
        return "green-background";
      default:
        return "purple-background";
    }
  }
}
