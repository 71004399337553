import { HttpClientModule, HttpClientXsrfModule, HttpErrorResponse } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Injectable } from '@angular/core'
//FIXME import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import { LoadingBarRouterModule } from '@ngx-loading-bar/router'
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha'
import { DragulaModule } from 'ng2-dragula'
import { ToastrModule } from 'ngx-toastr'
import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { AppRouting } from './app.routing'
import { RetryRequestsModule } from './libs/retry-requests/retry-requests.module'
import { ServerErrorModule } from './libs/server-error-notification/server-error.module'
import { BreadcrumbsModule } from './libs/breadcrumbs/breadcrumbs.module'
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component'
import { HeaderUnauthenticatedComponent } from './components/header-unauthenticated/header-unauthenticated.component'
import { LoginComponent } from './pages/login/login.component'
import { PasswordResetComponent } from './pages/password-reset/password-reset.component'
import { RegisterComponent } from './pages/register/register.component'
import { ContactUsComponent } from './pages/contact-us/contact-us.component'
import { ConfigService } from './services/config.service'
import { SharedModule } from './shared/shared.module'
import { HomeComponent } from './pages/home/home.component'
import { SetPasswordComponent } from './pages/set-password/set-password.component'
import { registerLocaleData } from '@angular/common'
import localeEnGB from '@angular/common/locales/en-GB'
import { UnauthenticatedComponent } from './pages/unauthenticated/unauthenticated.component'
import { NgxsModule } from '@ngxs/store'
import { AppState, AuthState } from './store/states'
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin'
import { SwiperModule } from "swiper/angular";
//FIXME import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
//FIXME import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights'
import { UserDetailsStepComponent } from './pages/register/sandbox/user-details-step/user-details-step.component'
import { DetailsStepComponent } from './pages/register/shared/details-step/details-step.component'
import { FinalStepComponent } from './pages/register/shared/final-step/final-step.component'
import { SandboxRegisterComponent } from './pages/register/sandbox/sandbox-register.component'
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component'
import { VerifyDispComponent } from './pages/verify-disp/verify-disp.component'
import { LoginEmailComponent } from './pages/login/login-email/login-email.component'
import { LoginVerificationComponent } from './pages/login/login-verification/login-verification.component'
import { LoginAuthComponent } from './pages/login/login-auth/login-auth.component'
import { DisableMfaComponent } from './pages/disable-mfa/disable-mfa.component'
import { RequestDisableMfaComponent } from './pages/request-disable-mfa/request-disable-mfa.component'
import { CompleteDispRegistrationComponent } from './pages/complete-disp-registration/complete-disp-registration.component'
import { LandingComponent } from './pages/landing/landing.component'
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent'
//FIXME import { EmailEditorModule } from 'angular-email-editor'
import { CollectContactDetailsModal } from './components/collect-contact-details-modal/collect-contact-details-modal.component';
import {RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

// Locales should be registered.
registerLocaleData(localeEnGB)

@Injectable()
export class AppInsightsErrorHandler implements ErrorHandler {
  constructor(
    //FIXME @Inject(AppInsightsService) private appInsightsService: AppInsightsService
  ) {}

  handleError(err: any): void {
    // Never log HTTP Client errors to Sentry.
    if (!(err instanceof HttpErrorResponse)) {
      //FIXME this.appInsightsService.trackException(err)
    }
    console.error(err)
  }
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#0F1740',
      text: 'white',
      link: '#EDF2F6',
    },
    button: {
      background: '#00877B',
      text: 'white',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    link: 'Learn more',
    href:
      'https://muniodemo.blob.core.windows.net/uploaded-documents/Munio_Cookie_Policy.pdf',
    policy: 'Cookie Policy',
  },
}

const toastrModuleConfig = {
  closeButton: true,
  positionClass: 'toast-bottom-full-width',
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        PasswordResetComponent,
        ContactUsComponent,
        CollectContactDetailsModal,
        HeaderUnauthenticatedComponent,
        HomeComponent,
        SetPasswordComponent,
        UnauthenticatedComponent,
        UserDetailsStepComponent,
        DetailsStepComponent,
        FinalStepComponent,
        SandboxRegisterComponent,
        ConfirmEmailComponent,
        VerifyDispComponent,
        LoginEmailComponent,
        LoginVerificationComponent,
        LoginAuthComponent,
        DisableMfaComponent,
        RequestDisableMfaComponent,
        CompleteDispRegistrationComponent,
        LandingComponent,
    ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    RetryRequestsModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // https://angular.io/guide/http#configuring-custom-cookieheader-names
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    AppRouting,
    ToastrModule.forRoot(toastrModuleConfig),
    ServerErrorModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    //FIXME AgmCoreModule.forRoot({ apiKey: 'initialKey' }),
    DragulaModule.forRoot(),
    BreadcrumbsModule.forRoot(),
    NgxsModule.forRoot([AuthState, AppState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: 'auth',
      storage: StorageOption.SessionStorage,
    }),
    //FIXME
    // NgxsReduxDevtoolsPluginModule.forRoot({
    //     name: 'Munio',
    //     disabled: environment.production,
    // }),
    NgxsLoggerPluginModule.forRoot({disabled: environment.production}),
    //FIXME
    // ApplicationInsightsModule.forRoot({
    //   instrumentationKeySetLater: true,
    // }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FontAwesomeModule,
    //FIXME EmailEditorModule,
    SwiperModule,
  ],
    providers: [
        ConfigService,
      //FIXME AppInsightsService,
        {
            provide: ErrorHandler,
            useClass: environment.production ? AppInsightsErrorHandler : ErrorHandler,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigService) => {
                return () => config.load();
            },
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useFactory: (config: ConfigService) => {
                return {
                    siteKey: config.settings.recaptchaSiteKey,
                } as RecaptchaSettings;
            },
            deps: [ConfigService],
        },
        {
            provide: LOCALE_ID,
            useFactory: () => 'en-GB', // Locale only applied when using factory.
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
