import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { JwtRenewService } from './shared/services/jwt-renew.service';
import { ModalService } from './shared/services/modal.service';
import { Select, Store } from '@ngxs/store';
import { CloseSideNav } from './store/actions';
import { AppState } from './store/states';
import { ConfigService } from './services/config.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { SocialAnalyticsService } from './services/social-analytics.service';

@Component({
  selector: 'app-root',
  template: `
    <ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private appName = this.configService?.settings?.projectName || "Munio";

  @Select(AppState.sideNavOpen)
  sideNavOpen$: Observable<boolean>;

  @HostBinding('class.sidenav-active')
  sideNavOpen: boolean;

  constructor(
    @Inject(JwtRenewService) private jwtRefreshService: JwtRenewService,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Title) private titleService: Title,
    @Inject(ModalService) private modalService: ModalService,
    @Inject(ConfigService) public configService: ConfigService,
    @Inject(Store) private store: Store,
    @Inject(GoogleAnalyticsService) private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(SocialAnalyticsService) private socialAnalyticsService: SocialAnalyticsService,
    private ccService: NgcCookieConsentService
  ) {
    this.googleAnalyticsService.loadService();
    this.socialAnalyticsService.loadService();
  }

  ngOnInit() {
    this.jwtRefreshService.start();

    // Change the page title once the route change was successful.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      const title = data['title'] ? `${data['title']} - ${this.appName}` : this.appName;
      this.titleService.setTitle(title);
    });
    
    // Close all modals on start of route changes.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
    ).subscribe(() => this.modalService.closeAll());

    // Scroll to top of page on navigation end, close side nav.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      debounceTime(50)  // Prevent multiple state changes.
    ).subscribe(() => {
      window.scrollTo(0, 0);
      if (this.sideNavOpen) {
        this.store.dispatch(new CloseSideNav());
      }
    });

    this.sideNavOpen$.subscribe(sideNavOpen => this.sideNavOpen = sideNavOpen);
  }

  ngOnDestroy() {
    this.jwtRefreshService.stop();
  }
}
