import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent {
  @Input() steps: string[];
  @Input() activeIndex: number;

  getClass(index: number) {
    if (index < this.activeIndex) {
      return 'done';
    } else if (index === this.activeIndex) {
      return 'active';
    }
  }

}
