<div id="home" class="home-banner no-top-padding">
    <div class="home-banner-bg">
        <div class="container-fluid">
            <div class="intro">
              <h3 data-aos="fade-up" data-aos-delay="50">
                <b>Munio</b>
              </h3>
                <h1 data-aos="fade-up" data-aos-delay="100">
                  Supporting private investments
                </h1>
                <h2 data-aos="fade-up" data-aos-delay="150">
                  MUNIO is a Private Capital investment platform that efficiently connects investors, HNW individuals, family offices, private equity and venture capital, with investable projects.
                </h2>
            </div>
        </div>
        <img class="green-leaves" src="/img/emails/munio-logo.png">
    </div>
</div>
