import { Component, OnInit, Inject } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { CollectContactDetailsModal } from '../../components/collect-contact-details-modal/collect-contact-details-modal.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  sandboxUrl;
  marketingQueryParams: any;

  constructor(
    @Inject(ConfigService) private configService: ConfigService,
    @Inject(MatDialog) protected dialog: MatDialog,
    @Inject(GoogleAnalyticsService) protected googleAnalyticsService: GoogleAnalyticsService,

  ) {
    this.sandboxUrl = this.configService.settings.sandboxUrl + "/register";
    if (this.configService.settings.featureToggles.sandbox) {
      window.location.href = this.configService.settings.prodUrl;
    }
  }

  ngOnInit() {}

  collectContactDetails(sendProspectus: boolean) {
    const dialogRef = this.dialog.open(CollectContactDetailsModal, { data: { getProspectus: sendProspectus } });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.location.href = this.configService.settings.prospectusDocumentUrl;
        this.googleAnalyticsService.emitAnalyticsEvent("prospectus", "engagement", "prospectus_viewed");
      }
    })
  }

}
