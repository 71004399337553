import { Component, Inject, EventEmitter, Output } from "@angular/core";
import * as api from "../../services/generated-api.service";
import { of, Observable } from "rxjs";
import { FormComponent } from "../../classes/form.component";
import { AppToasterService } from "../../services/toaster.service";
import { FieldOptions, FieldType, InputType } from "../field/field.component";
import * as _ from "lodash";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfigService } from "../../../services/config.service";
import { Select } from "@ngxs/store";
import { AppState } from "../../../store/states";
import { take, switchMap, map } from "rxjs/operators";

@Component({
  selector: "app-update-email-modal",
  templateUrl: "./update-email-modal.component.html",
  styleUrls: ["./update-email-modal.component.scss"]
})

export class UpdateEmailModalComponent extends FormComponent<any> {

  protected fields = [];
  bankIdBusy = false;
  isMfaEnabled = this.configService.settings.featureToggles.enableMfa;
  isBankId: boolean;

  @Select(AppState.accountDetails) account$: Observable<api.UserAccountModel>;

  constructor(
    private dialogRef: MatDialogRef<UpdateEmailModalComponent>,
    @Inject(api.InvestorsClient) private investorsClient: api.InvestorsClient,
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(MAT_DIALOG_DATA) public account: api.UserAccountModel,
    @Inject(ConfigService) private configService: ConfigService
  ) {
    super();
  }

  ngOnInit() {
    this.usersClient.requireMfa().subscribe(a => {
      this.isMfaEnabled = this.isMfaEnabled && a;
      this.isBankId = this.account.dispIdentifier != null;
      if (this.isMfaEnabled && this.account.mfaTypeId == 2) {
        this.sendMfaSms();
      };
      super.ngOnInit();
    });
  }

  createForm() {

    let verificationTokenLength = 6;
    let verificationHint = "This is the rolling code generated by your chosen authenticator app";

    if (this.account.mfaTypeId == 2) {
      verificationTokenLength = 7;
      verificationHint = "You will receive an SMS with your verification code. This code is valid for 3 minutes";
    }

    let sharedFields = {
      "newEmailAddress": new FieldOptions({
        name: "newEmailAddress",
        label: "New Email Address",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    let standardUserFields = {
      "password": new FieldOptions({
        name: "password",
        label: "Password",
        type: FieldType.input,
        inputType: InputType.password,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    let verificationFields = {
      "verificationToken": new FieldOptions({
        name: "verificationToken",
        label: "Verification Code",
        hint: verificationHint,
        type: FieldType.input,
        inputType: InputType.text,
        minLength: verificationTokenLength,
        maxLength: verificationTokenLength,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    let fieldOptions = { ...sharedFields }

    if (!this.isBankId) {
      fieldOptions = { ...fieldOptions, ...standardUserFields };

      if (this.isMfaEnabled) {
        fieldOptions = { ...fieldOptions, ...verificationFields };
      }
    }

    this.fields = _.keys(fieldOptions);
    this.buildFromOptions(of(fieldOptions));

  }

  sendMfaSms() {
    return this.usersClient.sendSmsVerification(this.account.emailAddress)
      .subscribe();
  }

  submitData() {
    if (this.isBankId) {
      return this.usersClient.updateDispEmail(
        this.form.value.newEmailAddress
      ).pipe(map(() => {
        this.dialogRef.close(true);
      },
        error => {
          this.handleError(error);
        }));

      // Todo : add BankId verification
/*    this.usersClient.requestDispEmailUpdate(
      this.form.value.newEmailAddress,
      new api.LoginRequest({ emailAddress: this.account.emailAddress })
    ).subscribe(url => {
      this.form.reset();
      setTimeout(() => window.location.href = url, 0);
      this.dialogRef.close();
      },
        error => {
          this.handleError(error);
        });*/
  } else {
    return this.usersClient.updateEmail(
      this.form.value.newEmailAddress,
      new api.LoginRequest({
        emailAddress: this.account.emailAddress,
        password: this.form.value.password,
        verificationToken: this.form.value.verificationToken
        })
    ).pipe(map(() => {
      this.dialogRef.close(true);
    },
      error => {
        this.handleError(error);
      }));
    }
  }

  handleSubmitSuccess() {
    this.dialogRef.close(true);
  }

  public handleError(error: any) {
    if (error.status === 400) {
      this.attachFormErrors(this.form, JSON.parse(error.response));
    };
    return;
  }

}
