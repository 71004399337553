import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-dialog',
  templateUrl: './unsaved-dialog.component.html',
  styleUrls: ['./unsaved-dialog.component.scss']
})
export class UnsavedDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<UnsavedDialogComponent>,
  ) { }

  leave() {
    this.dialogRef.close(true);
  }

  stay() {
    this.dialogRef.close(false);
  }
}
