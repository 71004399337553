import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FieldOptions } from '../field/field.component';

@Component({
  selector: 'app-field-required-status',
  templateUrl: './field-required-status.component.html',
  styleUrls: ['./field-required-status.component.scss']
})
export class FieldRequiredStatusComponent implements OnChanges {
  @Input() fieldOption: FieldOptions;
  fieldControl: AbstractControl;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fieldOption'] !== undefined) {
      if (changes['fieldOption'].currentValue === undefined) {
        throw new Error('fieldOption cannot be undefined.');
      } else {
        this.fieldControl = changes['fieldOption'].currentValue.control;
      }
    }
  }

  isRequired() {
    return this.fieldOption && this.fieldOption.fieldRequired() && !this.fieldControl.value;
  }

}
