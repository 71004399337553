import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as data from "../../shared/services/generated-api.service";
import { AppToasterService } from '../../shared/services/toaster.service';
import { Store } from '@ngxs/store';
import { Login } from '../../store/actions';
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-verify-disp',
  templateUrl: './verify-disp.component.html',
  styleUrls: ['./verify-disp.component.scss']
})
export class VerifyDispComponent implements OnInit {
  uid: string;
  dispToken: string;
  errorMsg: string;
  tokenIsValid = false;

  constructor(
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(Router) protected router: Router,
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(Store) private store: Store,
    @Inject(MatDialog) private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dispToken = params['token'];
      this.usersClient.completeDispAuthentication(this.dispToken).subscribe(
        data => {
          this.tokenIsValid = true;
          if (data.shouldDeactivatePassword) {
            this.confirmPasswordDeactivation(data.token);
          } /*else if (data.newEmailToUpdate != null) {
            this.updateDispEmail(data.newEmailToUpdate);
          }*/ else {
            this.store.dispatch(new Login(data.token));
          }
        }, error => {
          this.errorMsg = JSON.parse(error.response)['non_field_errors'];
        }
      );
    });
  }

  confirmPasswordDeactivation(token: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.message = `You have successfully authenticated with BankID<br/>If you plan to use BankID for all subsequent logins, consider deactivating your password for additional security.`;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new Login(token)).subscribe(
          () => 
            this.usersClient.deactivatePassword().subscribe(
              () => this.toastr.success('Password login deactivated'),
              () => this.toastr.error('Failed to deactivate password login.')
            ));
      }
      else this.store.dispatch(new Login(token));
    }, () => null);
  }

  // Todo : add BankId verification
/*  updateDispEmail(newEmail: string) {
    this.toastr.success('You have successfully authenticated with BankID<br/>Your email will be updated');
    return this.usersClient.updateDispEmail(newEmail)
      .pipe(map(() => {
        this.toastr.success("Email updated successfully")
        this.navigateToInvestorProfile();
      },
        error => {
          this.toastr.error("Unable to update email. Please try again");
          this.navigateToInvestorProfile();
      }));
  }

  navigateToInvestorProfile() {
    this.router.navigate(["../../modules/investor/profile/profile"]);
  }*/

}
