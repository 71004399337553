import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


export interface Image {
  id: string;
  url: string;
  type: string;
  embedded: any;
}

@Component({
  selector: 'app-image-swiper-modal',
  templateUrl: './image-swiper-modal.component.html',
  styleUrls: ['./image-swiper-modal.component.scss']
})
export class ImageSwiperModalComponent {
  public images: Image[];
  public index: number;

  constructor(@Inject(NgbActiveModal) public activeModal: NgbActiveModal) { }

}
