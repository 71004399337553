import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-errors',
  template: `
    <div *ngFor="let message of getServerErrors()" class="alert alert-danger"  role="alert">
      {{ message }}
    </div>
  `
})
export class FormErrorsComponent {
  @Input() formGroup: UntypedFormGroup;

  constructor() { }

  /**
   * Recursively get form errors. Will append nest form errors.
   * @param {FormGroup} formGroup
   * @param serverErrors
   */
  private attachFormErrors(formGroup: UntypedFormGroup, serverErrors) {
    if (formGroup.errors && formGroup.errors.hasOwnProperty('server')) {
      serverErrors = serverErrors.concat(formGroup.errors['server']);
    }

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof UntypedFormGroup) {
        serverErrors = this.attachFormErrors(control, serverErrors);
      }
    });

    return serverErrors;
  }

  getServerErrors() {
    const serverErrors = [];
    return this.attachFormErrors(this.formGroup, serverErrors);
  }

}
