import { Component, OnInit, OnChanges, Inject, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormComponent } from "../../../../shared/classes/form.component";
import * as api from "../../../../shared/services/generated-api.service";
import { FieldOptions, FieldType, InputType } from "../../../../shared/components/field/field.component";
import { Observable, of } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";
import { ConfigService } from '../../../../services/config.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss']
})
export class FinalStepComponent extends FormComponent<any> implements OnInit, OnChanges {

  successMsg: string;
  protected fields = [];
  sandboxToggle = false;
  projectName = "MUNIO";
  referralCode: string;

  @Input() investorTypeId: number;
  @Output() formSubmitted = new EventEmitter<UntypedFormGroup>();

  constructor(
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(ConfigService) private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  ngOnInit() {
    this.sandboxToggle = this.configService.settings.featureToggles.sandbox;
    this.projectName = this.configService.settings.projectName;
    this.referralCode = this.cookieService.get('referralCode');

    super.ngOnInit();
  }

  ngOnChanges(changes) {
    this.createForm();
  }

  createForm() {
    this.usersClient.getNewTermsToAccept().subscribe(result => {

      let fieldOptions: _.Dictionary<FieldOptions> = {};

      // Add in referral code as an additional field except on the sandbox...
      if (!this.sandboxToggle) {
        fieldOptions.referralCode = new FieldOptions({
          name: "referralCode",
          label: "Referral Code",
          type: FieldType.input,
          inputType: InputType.text,
          allowBlank: true,
          allowNull: true,
          readOnly: false,
          hint: `Referred to ${this.projectName}? If so, please enter your referral code`
        });
      }

      // Start with all the Terms of Use checkboxes...
      fieldOptions = _.merge(fieldOptions, _.fromPairs(result.map(a => [a.id, new FieldOptions({
        name: a.name,
        label: `I accept the <a href="${a.url}" download target="_blank" onClick="return false;">${a.name}</a>`,
        type: FieldType.checkbox,
        allowBlank: false,
        allowNull: false
      })])));


      // Add in Recaptcha as an additional field...
      fieldOptions.recaptcha = new FieldOptions({
        name: "recaptcha",
        label: "Recaptcha",
        type: FieldType.recaptcha,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      });

      this.fields = _.keys(fieldOptions);
      this.buildFromOptions(of(fieldOptions), of({ referralCode: this.referralCode }));

    });
  }

  submitData(): Observable<any> {
    return of(this.formSubmitted.emit(this.form));
  }

  public handleSubmitError(error: any) {
    this.form.controls.recaptcha.reset();
    if (error.status === 400) {
      this.attachFormErrors(this.form, JSON.parse(error.response));
    }
  }

  handleSubmitSuccess(respData: any) {
  }
}
