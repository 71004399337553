import { Component, OnInit, Inject } from '@angular/core';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  alternateNameToggle = false;
  alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";

  constructor(
    @Inject(ConfigService) private configService: ConfigService) { }

  ngOnInit() {
    this.alternateNameToggle = this.configService.settings.featureToggles.useAlternateProjectName;
    let alternateLogoId = this.configService.settings.featureToggles.alternateProjectLogoId;

    if (this.alternateNameToggle) {
      switch (alternateLogoId) {
        case 1:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";
          break;
        case 2:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-2.svg";
          break;
      }      
    }
  }

}
