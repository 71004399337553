<div class="text-uppercase" mat-dialog-title>
  <span class="font-weight-bold">Bank Account Added</span>
  <button mat-button class="dialog-close" aria-label="Close" [mat-dialog-close]>
    &#x2716;
  </button>
</div>

<div mat-dialog-content>
  <br />
  <br />
  {{bankAccount.accountName}} has been added.  <span *ngIf="!bankAccount.usesBankId">Please allow 1 - 2 working days for the account to be verified, before you may make a withdrawal.</span>
  <br />
  <br />
  <div mat-dialog-actions align="end">
    <button [mat-dialog-close] mat-flat-button color="primary">Done</button>
  </div>
  <br />
</div>

