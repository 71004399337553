import { Component, Inject } from '@angular/core';
import { FormComponent } from '../../classes/form.component';
import { FieldOptions, FieldType, InputType } from '../field/field.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as api from "../../../shared/services/generated-api.service";
import { of, Observable } from 'rxjs';
import * as _ from "lodash";
import { AppToasterService } from '../../services/toaster.service';
import { Login } from '../../../store/actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-enable-mfa-modal',
  templateUrl: './enable-mfa-modal.component.html',
  styleUrls: ['./enable-mfa-modal.component.scss']
})
export class EnableMfaModalComponent extends FormComponent<any> {

  protected fields = [];

  contactNumber: string;
  totpLink: string;
  totpCode: string;
  hasPreviousTotpSeed: boolean;
  username: string;
  password: string;
  captureCodeManually = false;

  stepIndex = 1;

  public isAppMfa = true;

  constructor(
    public dialogRef: MatDialogRef<void>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(api.LookupsClient) private lookupsClient: api.LookupsClient,
    @Inject(Store) private store: Store,
  ) {
    super(toastr);
  }

  ngOnInit() {
    this.contactNumber = this.data.contactNumber;
    this.totpLink = this.data.totpLink;
    this.totpCode = this.data.totpCode;
    this.hasPreviousTotpSeed = this.data.hasPreviousTotpSeed;
    this.username = this.data.username;
    this.password = this.data.password;

    super.ngOnInit();
  }

  createForm() {

    let fieldOptions = {
      "mfaType": new FieldOptions({
        name: "mfaType",
        label: "Authentication Type",
        type: FieldType.radio,
        defaultValue: 2,
        inputType: InputType.text,
        choices$: this.lookupsClient.getMfaTypes(),
        allowBlank: false,
        allowNull: false
      }),
      "totp": new FieldOptions({
        name: "totp",
        label: "Verification Code",
        type: FieldType.input,
        inputType: InputType.text,
        minLength: 6,
        maxLength: 6,
        allowBlank: false,
        allowNull: false
      }),
      "smsCode": new FieldOptions({
        name: "smsCode",
        label: "Verification Code",
        type: FieldType.input,
        inputType: InputType.text,
        minLength: 7,
        maxLength: 7,
        allowBlank: false,
        allowNull: false
      }),
      "contactNumber": new FieldOptions({
        name: "contactNumber",
        label: "Contact Number",
        type: FieldType.phoneNumber,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false
      })
    };

    this.fields = _.keys(fieldOptions);

    this.buildFromOptions(
      of(fieldOptions));

    this.getFieldControl("totp").disable();
    this.getFieldControl("smsCode").disable();

    if (this.isAppMfa) {
      this.getFieldControl("mfaType").setValue(1);
      this.getFieldControl("contactNumber").disable();
    }
    else {
      this.getFieldControl("mfaType").setValue(2);
      this.getFieldControl("contactNumber").enable();

      if (this.getFieldControl("contactNumber").status == 'INVALID') {
        this.getFieldControl("contactNumber").setValue(this.contactNumber);
      }
    }

    this.form.controls["mfaType"].valueChanges.subscribe(val => this.onMfaTypeChanges(val));
  }

  toggleCaptureCodeManually() {
    this.captureCodeManually = !this.captureCodeManually;
  }

  onMfaTypeChanges(val: any) {
    if (!this.isAppMfa && this.form.controls["contactNumber"].touched && this.form.controls["contactNumber"].valid) {
      this.contactNumber = this.form.controls["contactNumber"].value.internationalNumber;
    }

    this.isAppMfa = val == 1;
    this.createForm();
    }

  handleStepIndex2() {
    this.stepIndex = 2
    this.createForm();
  }

  handleStepIndex3() {

    if (!this.form.valid) {
      if (!this.isAppMfa) {
        this.form.controls["contactNumber"].markAllAsTouched();
      }
      return;
    }

    if (this.isAppMfa) {
      this.getFieldControl("totp").enable();
    }
    else {
      this.getFieldControl("smsCode").enable();
    }

    this.stepIndex = 3

    if (this.form.value.contactNumber != undefined) {
      this.contactNumber = this.form.value.contactNumber.internationalNumber.replace(/\s/g, "");
    }

    if (this.form.controls["mfaType"].value == 2) {
      let authyUserModel = new api.AuthyUserInputModel();
      authyUserModel.emailAddress = this.username;
      authyUserModel.password = this.password;
      authyUserModel.contactNumber = this.form.value.contactNumber;

      return this.usersClient.registerSmsMfa(authyUserModel)
        .subscribe(
          () => { },
          err => this.toastr.error(err));
    }
  }

  handleSubmitSuccess(obj: any): void {
    this.toastr.success("Two-Step Authentication Successfully Enabled for your Account.");
    this.dialogRef.close();
    this.store.dispatch(new Login(obj.token));
  }

  submitData(formData: any): Observable<any> {
    let verificationCode = this.isAppMfa
      ? this.form.value.totp
      : this.form.value.smsCode;

    let enableMfaRequest = new api.EnableMfaRequest();
    enableMfaRequest.emailAddress = this.username;
    enableMfaRequest.password = this.password;
    enableMfaRequest.verificationToken = verificationCode;
    enableMfaRequest.mfaTypeId = this.isAppMfa ? 1 : 2;

    return this.usersClient.enableMfa(enableMfaRequest)
  }


  resendSms() {
    let authyUserModel = new api.AuthyUserInputModel();
    authyUserModel.emailAddress = this.username;
    authyUserModel.password = this.password;
    authyUserModel.contactNumber = this.form.value.contactNumber;

    return this.usersClient.resendRegisterSmsMfaVerification(authyUserModel)
      .subscribe();
  }

  public totpCodeCopied() {
    this.toastr.success('Code Copied to Clipboard.');
  }

}
