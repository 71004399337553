import { Component, OnChanges, Inject, Output, EventEmitter, Input } from '@angular/core';
import { FormComponent } from "../../../../shared/classes/form.component";
import * as api from "../../../../shared/services/generated-api.service";
import { FieldOptions, FieldType, InputType } from "../../../../shared/components/field/field.component";
import { Observable, of } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import { map } from 'rxjs/operators';
import * as _ from "lodash";

@Component({
  selector: 'app-details-step',
  templateUrl: './details-step.component.html',
  styleUrls: ['./details-step.component.scss']
})
export class DetailsStepComponent extends FormComponent<any> implements OnChanges {

  protected fields = [];

  @Input() isStandardRegistration: boolean;
  @Input() isIndividualInvestor: boolean;
  @Input() isAccountManager: boolean;
  @Output() formSubmitted = new EventEmitter<UntypedFormGroup>();
  @Output() formValid = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.ngForm.resetForm();
    this.createForm();
  }

  createForm() {

    let sharedFieldOptions = {
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    }; 

    let standardSharedFieldOptions = {
      "firstName": new FieldOptions({
        name: "firstName",
        label: "First Name",
        type: FieldType.input,
        inputType: InputType.text,
        maxLength: 255,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "lastName": new FieldOptions({
        name: "lastName",
        label: "Last Name",
        type: FieldType.input,
        inputType: InputType.text,
        maxLength: 255,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "password": new FieldOptions({
        name: "password",
        label: "Password",
        type: FieldType.input,
        inputType: InputType.password,
        minLength: 10,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    let sharedCompanyFieldOptions = {
      "companyName": new FieldOptions({
        name: "companyName",
        label: "Company Name",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "authorizedToActOnBehalfOfCompany": new FieldOptions({
        name: "authorizedToActOnBehalfOfCompany",
        label: "I am authorized to act on behalf of this organization",
        type: FieldType.checkbox,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
    };

    let standardCompanyFieldOptions = {
      "companyRegNo": new FieldOptions({
        name: "companyRegNo",
        label: "Company Reg No",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: true,
        allowNull: true,
        readOnly: false
      }),
      "companyOwner": new FieldOptions({
        name: "companyOwner",
        label: "Company Owner",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: true,
        allowNull: true,
        readOnly: false
      })
    };

    let accountManagerFieldOptions = {
      "advisoryNumber": new FieldOptions({
        name: "advisoryNumber",
        label: "Advisory Number",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
    };

    let currentFieldOptions = { ...sharedFieldOptions };     // All registration needs an email

    if (this.isStandardRegistration) {
      currentFieldOptions = { ...currentFieldOptions, ...standardSharedFieldOptions }; // All standard registration needs a first name, last name and password 
      if (!this.isIndividualInvestor) { 
        currentFieldOptions = { ...currentFieldOptions, ...sharedCompanyFieldOptions, ...standardCompanyFieldOptions }; // Companies need company name, company reg no, company owner and authorisation
      }
    } else {
      if (!this.isIndividualInvestor)
      currentFieldOptions = { ...currentFieldOptions, ...sharedCompanyFieldOptions }; // Bank ID companies only use company name and authorisation
    }

    if (this.isAccountManager) {
      currentFieldOptions = { ...currentFieldOptions, ...accountManagerFieldOptions } // Account managers must enter their advisory number
    }

    this.fields = _.keys(currentFieldOptions);

    let formValue = this.form ?
      { ...this.form.value } :
      {};

    this.buildFromOptions(of(currentFieldOptions), of(formValue));
  }

  submitData(): Observable<any> {
    return of(this.formSubmitted.emit(this.form));
  }

  public handleSubmitError(error: any) {
    if (error.status === 400) {
      this.attachFormErrors(this.form, JSON.parse(error.response));
    };

    return;
  }

  handleSubmitSuccess() {
    this.formValid.emit(true);
  }
}
