import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

const RETURN_QUERY_PARAM = 'return';
const PERSISTENT_REDIRECT = 'postLoginRedirect';

@Injectable()
export class RedirectService {
  private returnUrl: string;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params[RETURN_QUERY_PARAM];
      // Persistent redirect is stored and consumed upon next log in...
      if (params[PERSISTENT_REDIRECT]) {
        localStorage.setItem(PERSISTENT_REDIRECT, params[PERSISTENT_REDIRECT]);
      }
    });
  }

  /**
   * Redirects to a URL while adding a `return` query parameter in order to redirect back to the original URL.
   * This is mostly be used in auth guards or services when a log in is required.
   *
   * When using the function in a guard, the `fromUrl` needs to be provided as the router state has not been applied and
   * is only accessible within the guard.
   *
   * @param toURL
   * @param fromURL
   */
  redirect(toURL: string, fromURL?: string) {
    if (!fromURL) {
      fromURL = this.router.routerState.snapshot.url;
    }
    // Do not redirect to page if already on the page.
    if (!fromURL.startsWith(toURL)) {
      const extras = {queryParams: {}};
      extras.queryParams[RETURN_QUERY_PARAM] = fromURL;
      this.router.navigate([toURL], extras);
    }
  }

  navigateToReturn(fallbackUrl: string | string[] = '/', allowRedirect: boolean) {
    let url = fallbackUrl;

    if (allowRedirect) {
      let persistentRedirectUrl = localStorage.getItem(PERSISTENT_REDIRECT);
      if (persistentRedirectUrl) {
        url = persistentRedirectUrl;
        localStorage.removeItem(PERSISTENT_REDIRECT); // Clear out persistent redirect after it is consumed...
      }
      else {
        url = this.returnUrl || fallbackUrl;
      }
    }

    if (typeof url === 'string' || url instanceof String) {
      // @ts-ignore
      this.router.navigateByUrl(url);
    } else {
      this.router.navigate(url);
    }
  }

}
