import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ConfigService } from "../../services/config.service";
import { MatStepper } from "@angular/material/stepper";
import * as api from "./../../shared/services/generated-api.service";
import { AppToasterService } from "../../shared/services/toaster.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { FinalStepComponent } from "./shared/final-step/final-step.component";
import { DetailsStepComponent } from "./shared/details-step/details-step.component"; 
import{ GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {

  @ViewChild(DetailsStepComponent) details: DetailsStepComponent;
  @ViewChild(FinalStepComponent) finalStep: FinalStepComponent;
  @ViewChild(MatStepper) stepper: MatStepper;

  marketingQueryParams: any;

  sandboxToggle = false;

  registrationFlow: string;
  registrationType: string;
  investorType: string;

  isStandardRegistration: boolean;
  isIndividualInvestor: boolean;
  isAccountManager: boolean;

  formValid = false;
  submitting = false;
  successMsg: string;

  constructor(
    protected activatedRoute: ActivatedRoute,
    @Inject(Router) protected router: Router,
    @Inject(ConfigService) private configService: ConfigService,
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService, 
    @Inject(GoogleAnalyticsService) protected googleAnalyticsService: GoogleAnalyticsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon("investor", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/register/ic_investor_default_register.svg"));
    this.matIconRegistry.addSvgIcon("accountManager", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/register/ic_account_manager_default_register.svg"));
    this.matIconRegistry.addSvgIcon("individual", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/register/ic_individual_default_register.svg"));
    this.matIconRegistry.addSvgIcon("company", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/register/ic_institution_default_register.svg"));
  }

  ngOnInit() {
    this.sandboxToggle = this.configService.settings.featureToggles.sandbox;
    this.marketingQueryParams = this.activatedRoute.snapshot.queryParams;
  }

  investorDetailsSubmitted() {
    this.submitting = true;
    let userTypeId = this.isAccountManager
      ? 3
      : 2;

    let investorTypeId = this.isIndividualInvestor
      ? 1
      : 2;

    if (this.isStandardRegistration) {
      this.usersClient.register(
        this.marketingQueryParams["utm_source"],
        this.marketingQueryParams["utm_medium"],
        this.marketingQueryParams["utm_campaign"],
        this.marketingQueryParams["utm_term"],
        this.marketingQueryParams["utm_content"],
        { userTypeId: userTypeId, investorTypeId: investorTypeId, acceptTermsOfUse: true, ...this.details.form.value, ...this.finalStep.form.value })
        .subscribe(
           success => {
            this.successMsg = success.message;
            this.googleAnalyticsService.emitAnalyticsEvent("register", "engagement", "registration_complete");
          },
          error => {
            this.googleAnalyticsService.emitAnalyticsEvent("register", "engagement", "registration_failed");
            if (error.status === 400) {
              this.toastr.error("Please correct all validation errors and try again.");
              var errors = JSON.parse(error.response);
              if (errors['password'] || errors['emailAddress']) {
                this.stepper.selectedIndex = 2;
                this.details.handleSubmitError(error);
                this.finalStep.handleSubmitError(error);
              }
            }
          })
        .add(() => {
          this.submitting = false;
        });
    } else {
      this.usersClient.requestDispRegistration({ userTypeId: userTypeId, investorTypeId: investorTypeId, acceptTermsOfUse: true, ...this.details.form.value, ...this.finalStep.form.value })
        .subscribe(
          response => {
            this.successMsg = "Redirecting you to BankID provider for authentication...";
            setTimeout(() => window.location.href = response, 0);
          },
          error => {
            if (error.status === 400) {
              this.toastr.error("Please correct all validation errors and try again.");
              var errors = JSON.parse(error.response);
              if (errors['emailAddress']) {
                this.stepper.selectedIndex = 2;
                this.details.handleSubmitError(error);
                this.finalStep.handleSubmitError(error);
              }
            }
          })
        .add(() => {
          this.submitting = false;
        });
    }
  }

  processValues() {
    this.isStandardRegistration = (this.registrationFlow == 'standard');
    this.isIndividualInvestor = (this.investorType == 'individual');
    this.isAccountManager = (this.registrationType == 'accountManager');
    this.next();
  }

  validateStep(result: boolean) {
    this.formValid = result;
    this.next();
  }

  goBack() {
    this.stepper.previous();
  }

  goBackPage() {
    this.router.navigate(["/"]);
  }

  next() {
    setTimeout(() => this.stepper.next(), 0);
  }

  stepChanged(event: any, stepper: any) {
    if(event.selectedIndex > event.previouslySelectedIndex) { 
      this.googleAnalyticsService.emitAnalyticsEvent("register", "engagement", event.previouslySelectedStep.label);
    }
  }
}
