import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bank-account-added-modal',
  templateUrl: './bank-account-added-modal.component.html',
  styleUrls: ['./bank-account-added-modal.component.scss']
})
export class BankAccountAddedModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public bankAccount: { accountName: string, usesBankId: boolean },
  ) { }


}
