<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
  <app-form-errors [formGroup]="form"></app-form-errors>

  <div *ngIf="!isAccountManager">
    <h2>Investor Details</h2>
  </div>
  <div *ngIf="isAccountManager">
    <h2>Account Manager Details</h2>
  </div>

  <div>
    <app-form-field [fieldOption]="options['firstName']" *ngIf="isStandardRegistration"></app-form-field>
    <app-form-field [fieldOption]="options['lastName']" *ngIf="isStandardRegistration"></app-form-field>
    <app-form-field [fieldOption]="options['companyName']" *ngIf="!isIndividualInvestor"></app-form-field>
    <app-form-field [fieldOption]="options['companyRegNo']" *ngIf="!isIndividualInvestor && isStandardRegistration"></app-form-field>
    <app-form-field [fieldOption]="options['companyOwner']" *ngIf="!isIndividualInvestor && isStandardRegistration"></app-form-field>
    <app-form-field [fieldOption]="options['emailAddress']"></app-form-field>
    <app-form-field [fieldOption]="options['password']" *ngIf="isStandardRegistration"></app-form-field>
    <app-form-field [fieldOption]="options['advisoryNumber']" *ngIf="isAccountManager"></app-form-field>
    <app-form-field [fieldOption]="options['authorizedToActOnBehalfOfCompany']" *ngIf="!isIndividualInvestor"></app-form-field>
  </div>

  <button mat-flat-button color="primary" class="float-right" matStepperNext>Next</button>
</form>
