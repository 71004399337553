import { Component } from '@angular/core';
import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-markdown-field',
  templateUrl: './markdown-field.component.html',
  styleUrls: ['./markdown-field.component.scss']
})
export class MarkdownFieldComponent extends FieldComponent {

}
