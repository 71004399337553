import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormComponent } from '../../../shared/classes/form.component';
import { FieldOptions, FieldType, InputType } from "../../../shared/components/field/field.component";
import { Store } from '@ngxs/store';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
})
export class LoginEmailComponent extends FormComponent<any> implements OnInit {

  protected fields = ['emailAddress', 'password'];
  @Output() formSubmitted = new EventEmitter<UntypedFormGroup>();

  constructor(    
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  createForm() {
    let fieldOptions = {
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "password": new FieldOptions({
        name: "autocomplete-password",
        label: "Autocomplete Password",
        type: FieldType.input,
        inputType: InputType.password,
        allowBlank: true,
        allowNull: true,
        readOnly: false
      }),
    };

    this.buildFromOptions(of(fieldOptions));
  }

  ngOnInit() {
    super.ngOnInit();
  }

  submitData(): Observable<any> {
    return of(this.formSubmitted.emit(this.form));
  }

  handleSubmitSuccess(respData: any) {
  }
}
