/// <reference types="@types/googlemaps" />
//FIXME import { LazyMapsAPILoader } from '@agm/core';
import { Inject, Injectable } from '@angular/core';
import { from ,  Observer ,  Observable } from 'rxjs';
// noinspection ES6UnusedImports
import { switchMap } from 'rxjs/operators';

/**
 * GeocodingService class.
 * https://developers.google.com/maps/documentation/javascript/
 */
@Injectable() export class GeoCodingService {


  constructor(
    //FIXME @Inject(LazyMapsAPILoader) private mapsApiLoader: LazyMapsAPILoader
    )
  {}

  // FIXME
  // /**
  //  * Reverse geocoding by location.
  //  *
  //  * Wraps the Google Maps API geocoding service into an observable.
  //  *
  //  * @param latLng Location
  //  * @return An observable of GeocoderResult
  //  */
  // geocode(latLng: google.maps.LatLng): Observable<google.maps.GeocoderResult[]> {
  //   const observable: Observable<google.maps.GeocoderResult[]> = Observable.create((observer: Observer<google.maps.GeocoderResult[]>) => {
  //     const geocoder = new google.maps.Geocoder();
  //     // Invokes geocode method of Google Maps API geocoding.
  //     geocoder.geocode({ location: latLng },
  //       (
  //         (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
  //           if (status === google.maps.GeocoderStatus.OK) {
  //             observer.next(results);
  //             observer.complete();
  //           } else {
  //             console.error('Geocoding service: geocoder failed due to: ' + status);
  //             observer.error(status);
  //           }
  //         })
  //     );
  //   });
  //
  //   return from(this.mapsApiLoader.load()).pipe(
  //     switchMap(() => observable));
  // }

  //FIXME
  // /**
  //  * Geocoding service.
  //  *
  //  * Wraps the Google Maps API geocoding service into an observable.
  //  *
  //  * @param address The address to be searched
  //  * @return An observable of GeocoderResult
  //  */
  // codeAddress(address: string): Observable<google.maps.GeocoderResult[]> {
  //   const observable: Observable<google.maps.GeocoderResult[]> = Observable.create(
  //     (observer: Observer<google.maps.GeocoderResult[]>) => {
  //       const geocoder = new google.maps.Geocoder();
  //       // Invokes geocode method of Google Maps API geocoding.
  //       geocoder.geocode({ address: address },
  //         (
  //           (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
  //             if (status === google.maps.GeocoderStatus.OK) {
  //               observer.next(results);
  //               observer.complete();
  //             } else {
  //               console.error(
  //                 'Geocoding service: geocode was not successful for the following reason: ' + status
  //               );
  //               observer.error(status);
  //             }
  //           })
  //       );
  //     });
  //
  //   return from(this.mapsApiLoader.load()).pipe(
  //     switchMap(() => observable)
  //   );
  // }

}
