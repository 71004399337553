<ng-container *ngFor="let control of controls; let i = index">
  <div>
    <mat-checkbox [formControl]="control" color="primary" (change)="checkboxChange(i)"
                  [attr.id]="inputId">{{ enumDef[i].name }}</mat-checkbox>
  </div>




</ng-container>
<div *ngIf="!enumDef" class="loading-blocks">
  <div class="shadow-checkbox"></div>
  <div class="shadow-checkbox"></div>
</div>
