<div class="form-group">

  <ng-container *ngIf="!hidden">
    <!-- Inputs -->
    <ng-container [ngSwitch]="fieldOption?.type">


      <!--If this gets implemented we can make this better https://github.com/angular/material2/issues/7737-->
      <!-- Standard Input Field -->
      <ng-container *ngSwitchCase="FieldType?.input">
        <mat-form-field>

          <input *ngIf="!fieldOption.mask" matInput id="{{fieldOption.name}}" placeholder="{{getLabel()}}" autocomplete="{{autocomplete}}" [type]="show ? 'text' : fieldOption.inputType.toString()" [formControl]="fieldControl"
                 [attr.id]="inputId" (keydown)="fieldOption.keyDown ? fieldOption.keyDown($event) : return" [min]="minValue" [max]="maxValue" [required]="fieldOption.fieldRequired()">

          <input *ngIf="fieldOption.mask" matInput id="{{fieldOption.name}}" placeholder="{{getLabel()}}" autocomplete="{{autocomplete}}" [type]="show ? 'text' : fieldOption.inputType.toString()" [mask]=fieldOption.mask [formControl]="fieldControl"
                 [attr.id]="inputId" (keydown)="fieldOption.keyDown ? fieldOption.keyDown($event) : return" [min]="minValue" [max]="maxValue" [required]="fieldOption.fieldRequired()">

          <mat-icon *ngIf="togglePassword()" matSuffix (click)="show = !show">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

        </mat-form-field>
        <password-strength-meter *ngIf="passwordStrengthMeter" [password]="fieldControl.value"></password-strength-meter>
      </ng-container>

      <!-- Currency Field -->
      <ng-container *ngSwitchCase="FieldType?.currency">

        <mat-form-field>

          <input matInput id="{{fieldOption.name}}" placeholder="{{getLabel()}}" [formControl]="fieldControl" [attr.id]="inputId" currencyMask [options]="{ precision: fieldOption?.currencyPrecision || 0, prefix: fieldOption?.currencyPrefix || 'ZAR ' }" [min]="minValue" [max]="maxValue" [required]="fieldOption.fieldRequired()" autocomplete="off">

          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

        </mat-form-field>
      </ng-container>

      <!-- Text area Field -->
      <ng-container *ngSwitchCase="FieldType?.textArea">

        <mat-form-field>

          <textarea matInput id="{{fieldOption.name}}" placeholder="{{getLabel()}}" mat-autosize [matAutosizeMinRows]="fieldOption?.minRows" [matAutosizeMaxRows]="fieldOption?.maxRows" [formControl]="fieldControl" [attr.id]="inputId" [required]="fieldOption.fieldRequired()"> </textarea>

          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

        </mat-form-field>
      </ng-container>

      <!-- Phone Number Field -->
      <ng-container *ngSwitchCase="FieldType?.phoneNumber">


        <mat-form-field>
          <mat-label *ngIf="fieldOption?.fieldRequired()" class="floating-label">{{getLabel()}} *</mat-label>
          <mat-label *ngIf="!fieldOption?.fieldRequired()" class="floating-label">{{getLabel()}}</mat-label>

          <input matInput id="{{fieldOption.name}}" [type]="show ? 'text' : fieldOption.inputType.toString()" [formControl]="fieldControl"
                 [attr.id]="inputId" (keydown)="fieldOption.keyDown ? fieldOption.keyDown($event) : return" [required]="fieldOption.fieldRequired()">


          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

        </mat-form-field>



        <!-- FIXME -->
<!--        <ngx-intl-tel-input id="{{fieldOption.name}}"-->
<!--                            [preferredCountries]="fieldOption?.preferredCountries"-->
<!--                            [enablePlaceholder]="true"-->
<!--                            [maxLength]="15"-->
<!--                            [phoneValidation]="true"-->
<!--                            [searchCountryFlag]="false"-->
<!--                            [enableAutoCountrySelect]="true"-->
<!--                            [formControl]="fieldControl"-->
<!--                            [attr.id]="inputId"-->
<!--                            [required]="fieldOption?.fieldRequired()">-->
<!--        </ngx-intl-tel-input>-->

        <mat-hint class="hint" [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
        <mat-error class="error-message" *ngIf="!fieldControl.valid && fieldControl.touched">Please enter a valid phone number</mat-error>

      </ng-container>

      <!-- Date Field -->
      <div *ngSwitchCase="FieldType?.date">
        <mat-form-field>

          <input matInput id="{{fieldOption.name}}" placeholder="{{getLabel()}}" [matDatepicker]="picker"
                 [formControl]="fieldControl"
                 [min]="minDate" [max]="maxDate" [attr.id]="inputId" [required]="fieldOption.fieldRequired()">

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker (monthSelected)="monthSelected($event, picker)" startView={{fieldOption.dateStartView}}></mat-datepicker>
          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

        </mat-form-field>
      </div>

      <!-- Time Field -->
      <div *ngSwitchCase="FieldType?.time">

        <mat-label *ngIf="fieldOption?.fieldRequired()" class="floating-label">{{getLabel()}} *</mat-label>
        <mat-label *ngIf="!fieldOption?.fieldRequired()" class="floating-label">{{getLabel()}}</mat-label>

        <ngb-timepicker id="{{fieldOption.name}}"
                        [formControl]="fieldControl"
                        [required]="fieldOption.fieldRequired()"
                        [attr.id]="inputId"
                        [min]="minTime"
                        [max]="maxTime">
        </ngb-timepicker>

        <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
        <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

      </div>

      <!-- Choice Field -->
      <div *ngSwitchCase="FieldType?.choice">
        <mat-form-field>
          <mat-select id="{{fieldOption.name}}" placeholder="{{getLabel()}}" [formControl]="fieldControl"
                      [attr.id]="inputId" [required]="fieldOption.fieldRequired()">
            <mat-option *ngIf="!fieldOption.fieldRequired()">None</mat-option>
            <mat-option *ngFor="let choice of (fieldOption.choices$ | async)" [value]="choice.id">
              {{choice.name}}
            </mat-option>
          </mat-select>

          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

        </mat-form-field>
      </div>

      <!-- Radio Field -->
      <div *ngSwitchCase="FieldType?.radio">

        <mat-radio-group id="{{fieldOption.name}}" [formControl]="fieldControl" [name]="fieldOption.name"
                         [required]="fieldOption.fieldRequired()">
          <mat-radio-button *ngFor="let choice of (fieldOption.choices$ | async)" [value]="choice.id" [attr.id]="inputId">
            {{choice.name}}
          </mat-radio-button>
        </mat-radio-group>

        <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
        <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>


      </div>

      <!-- Autocomplete search Field -->
      <div *ngSwitchCase="FieldType?.autocompleteSearch">
        <mat-form-field>
          <input id="{{fieldOption.name}}" placeholder="{{getLabel()}}" [required]="fieldOption.fieldRequired()" matInput appMatAutocompleteSelect [type]="fieldOption.inputType.toString()" [formControl]="fieldControl" [attr.id]="inputId" [matAutocomplete]="auto">
          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="autocompleteDisplay.bind((this))" autoActiveFirstOption>
          <mat-option *ngFor="let option of (searchResults$ | async)" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <!-- Checkbox -->
      <div *ngSwitchCase="FieldType?.checkbox" class="form-check">
        <mat-checkbox id="{{fieldOption.name}}"
                      [formControl]="fieldControl" color="primary"
                      [required]="!fieldOption.allowBlank"
                      [attr.id]="inputId">
          <span [innerHtml]="getLabel()"></span>
        </mat-checkbox>
        <!-- it looks very ugly on a checkbox, disabling because do we really need this -->
        <!--<mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>-->
      </div>

      <!-- Recaptcha -->
      <div *ngSwitchCase="FieldType?.recaptcha" class="recaptcha">
        <re-captcha name="captcha" [formControl]="fieldControl"></re-captcha>
        <!-- Hidden input to make field errors works -->
        <input id="{{fieldOption.name}}" class="form-control" type="hidden" [formControl]="fieldControl"
               [attr.id]="inputId">
        <!-- Error feedback -->
        <!-- Recaptcha error look a bit odd, disabling -->
        <!--<div *ngIf="hasFieldErrors()" class="invalid-feedback">{{getErrorMessage()}}</div>-->
      </div>

      <!-- File upload -->
      <div *ngSwitchCase="FieldType?.file">
        <mat-form-field>
          <input id="{{fieldOption.name}}"
                 matInput
                 readonly
                 placeholder="{{getLabel()}}"
                 [formControl]="fieldControl"
                 [attr.id]="inputId"
                 [required]="fieldOption.fieldRequired()">
          <button type="button" matSuffix class="mat-mini-fab mat-primary" style="padding-top: 8px" (click)="getFileUrl()">
            <mat-icon aria-label="Upload File">attach_file</mat-icon>
          </button>
          <mat-hint [ngStyle]="{color: hintColor}" *ngIf="hint || fieldOption.hint">{{getHint()}}</mat-hint>
          <mat-error *ngIf="hasFieldErrors()">{{getErrorMessage()}}</mat-error>

        </mat-form-field>
      </div>

    </ng-container>
  </ng-container>
  <ng-container *ngIf="hidden">
    <input type="hidden" [formControl]="fieldControl" class="form-control">
  </ng-container>
</div>
