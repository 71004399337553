import { Injectable, Inject } from '@angular/core';
import { ConfigService } from '../services/config.service';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})


export class GoogleAnalyticsService {

  constructor(@Inject(ConfigService) private configService: ConfigService) { }

  loadService() {
    const googleAnalyticsTrackingId = this.configService.settings.googleAnalyticsTrackingId;
    const googleTagManagerTrackingId = this.configService.settings.googleTagManagerTrackingId;
    const crossDomainGTMTrackingId = this.configService.settings.crossDomainGTMTrackingId;

    this.loadGTMServices(googleTagManagerTrackingId);
    this.loadGTMServices(crossDomainGTMTrackingId);

    const ga_script = document.createElement('script');
    ga_script.async = true;
    ga_script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTrackingId}`;
    document.body.appendChild(ga_script);


    window["dataLayer"] = window["dataLayer"] || [];

    window["gtag"] = function() {
        window["dataLayer"].push(arguments);
    };

    window["gtag"]('js', new Date());
    window["gtag"]('config', googleAnalyticsTrackingId);
    window["gtag"]({'gtm.start': new Date().getTime(), event: 'gtm.js'});
  }

  loadGTMServices(trackingId : string) {
    const gtm_script = document.createElement('script');
    gtm_script.async = true;
    gtm_script.src=`https://www.googletagmanager.com/gtm.js?id=${trackingId}`;
    document.body.appendChild(gtm_script);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.width = "0";
    iframe.height = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${trackingId}`;

    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  }

  public emitAnalyticsEvent(
    eventAction: string, 
    eventCategory: string, 
    eventLabel: string = null,  
    eventValue: number = null ) {

      const googleAnalyticsTrackingId = this.configService.settings.googleAnalyticsTrackingId;
      
      gtag('event', eventAction, {
        event_category: eventCategory, 
        event_label: eventLabel, 
        value: eventValue
      });
      gtag('config', googleAnalyticsTrackingId, {
        page_title : eventAction,
        page_path: `/${eventAction}/${eventLabel}`
      });
    }
}
