import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { FormComponent } from "../../shared/classes/form.component";
import * as api from "../../shared/services/generated-api.service";
import { AppToasterService } from "../../shared/services/toaster.service";
import { Store } from "@ngxs/store";
import { FieldOptions, FieldType, InputType } from "../../shared/components/field/field.component";
import { ConfigService } from "../../services/config.service";

@Component({
  selector: "app-password-reset-confirm",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"]
})
export class PasswordResetComponent extends FormComponent<api.LoginResponse> implements OnInit {
  uid: string;
  passwordResetToken: string;
  errorMsg: string;
  tokenIsValid = false;
  protected fields = ["newPassword"];
  companyName = "Munio";

  constructor(
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(Router) protected router: Router,
    @Inject(ConfigService) private configService: ConfigService,
    @Inject(AppToasterService) protected toastr: AppToasterService,
  ) {
    super();
  }

  ngOnInit() {
    this.companyName  = this.configService.settings.companyName;

    this.route.params.subscribe(params => {
      this.passwordResetToken = params["token"];
      this.usersClient.validatePasswordResetToken(this.passwordResetToken).subscribe(
        () => { this.tokenIsValid = true; },
        error => {
          this.errorMsg = JSON.parse(error.response)["non_field_errors"];
        }
      );
    });

    super.ngOnInit();
  }

  createForm() {
    let fieldOptions = {
      "newPassword": new FieldOptions({
        name: "newPassword",
        label: "New Password",
        type: FieldType.input,
        inputType: InputType.password,
        hint: "Your password must contain at least 10 characters",
        minLength: 10,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
    };

    this.buildFromOptions(of(fieldOptions));
  }

  submitData() {
    const data = {
      passwordResetToken: this.passwordResetToken,
      ...this.form.value
    } as api.PasswordChangeWithTokenInputModel;
    return this.usersClient.completePasswordReset(data);
  }

  handleSubmitSuccess(data: api.LoginResponse) {
    this.router.navigate(["/log-in"]);
    this.toastr.success("Your password has been changed successfully.");
    this.ngForm.resetForm();
  }

}
