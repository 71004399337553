import { Component, Inject, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { FormComponent } from "../../shared/classes/form.component";
import * as api from "../../shared/services/generated-api.service";
import { FieldOptions, FieldType, InputType } from "../../shared/components/field/field.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-password-reset",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"]
})
export class ForgotPasswordComponent extends FormComponent<api.PasswordResetResponse> implements OnInit {
  successMsg = null;
  protected fields = ["emailAddress", "recaptcha"];

  constructor(
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  createForm() {
    let fieldOptions = {
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "recaptcha": new FieldOptions({
        name: "recaptcha",
        label: "Recaptcha",
        type: FieldType.recaptcha,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    this.activatedRoute.queryParams.subscribe(a => {
      let model = {
        emailAddress: a["emailAddress"]
      };

      this.buildFromOptions(of(fieldOptions), of(<any>model));
    });
  }

  submitData(): Observable<api.PasswordResetResponse> {
    return this.usersClient.resetPassword({...this.form.value});
  }

  handleSubmitSuccess(resp: api.PasswordResetResponse) {
    this.successMsg = resp.success;
  }
}
