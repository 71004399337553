import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as data from "../../services/generated-api.service";
import { Select, Store } from '@ngxs/store';
import { Logout, ToggleSideNav, ExitInvestorState } from '../../../store/actions';
import { Dashboard, DashboardType, UserContextModel } from '../../../store/models';
import { AppState } from '../../../store/states';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-header-authenticated',
  templateUrl: './header-authenticated.component.html',
  styleUrls: ['./header-authenticated.component.scss']
})
export class HeaderAuthenticatedComponent implements OnInit {

  @Select(AppState.accountDetails) account$: Observable<data.UserAccountModel>;
  @Select(AppState.userContext) userContext$: Observable<UserContextModel>;
  @Select(AppState.dashboards) dashboards$: Observable<Dashboard[]>;
  DashboardType = DashboardType;
  sandboxToggle = false;
  showMessages = false;
  alternateNameToggle = false;
  alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";
  alternateNameSandboxLogoUrl = "./assets/images/alternate-sandbox-1.svg";
  alternateName = "";
  baseUrl = "";
  user: data.UserAccountModel;

  @ViewChild('accountPopover') public accountPopover: NgbPopover;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(Store) private store: Store,
    @Inject(ConfigService) private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.sandboxToggle = this.configService.settings.featureToggles.sandbox;
    this.alternateNameToggle = this.configService.settings.featureToggles.useAlternateProjectName;
    this.alternateName = this.configService.settings.alternateCompanyName;
    this.showMessages = this.configService.settings.featureToggles.showMessages;

    let alternateLogoId = this.configService.settings.featureToggles.alternateProjectLogoId;

    if (this.alternateNameToggle) {
      switch (alternateLogoId) {
        case 1:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";
          this.alternateNameSandboxLogoUrl = "./assets/images/alternate-sandbox-1.svg";
          break;
        case 2:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-2.svg";
          this.alternateNameSandboxLogoUrl = "./assets/images/alternate-sandbox-2.svg";
          break;
      }
    }

    this.account$.subscribe(user => this.user = user);

    if (this.sandboxToggle) this.baseUrl = this.configService.settings.prodUrl;
    // Close account popover on navigation change.
    else
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
    ).subscribe(() => this.accountPopover.close());

  }

  hamburgerClicked() {
    this.store.dispatch(new ToggleSideNav());
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  leaveInvestorContext() {
    this.store.dispatch(new ExitInvestorState());
  }
}
