import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import * as api from "../../../shared/services/generated-api.service";
import { MatStepper } from '@angular/material/stepper';
import { AppToasterService } from "../../../shared/services/toaster.service";
import { UserDetailsStepComponent } from "./user-details-step/user-details-step.component";
import { FinalStepComponent } from "../shared/final-step/final-step.component";
import { Store } from '@ngxs/store';
import { Login } from '../../../store/actions';


@Component({
  selector: 'app-sandbox-register',
  templateUrl: './sandbox-register.component.html',
  styleUrls: ['./sandbox-register.component.scss']
})
export class SandboxRegisterComponent {

  @ViewChild(UserDetailsStepComponent) userDetails: UserDetailsStepComponent;
  @ViewChild(FinalStepComponent) finalStep: FinalStepComponent;
  @ViewChild(MatStepper) stepper: MatStepper;
  investorTypeId = 0;

  submitting = false;
  loggingIn = false;

  constructor(
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(Store) private store: Store,
  ) {}

  investorTypeIdSelected() {
    setTimeout(() => this.stepper.selectedIndex = 1, 0);
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  stepChanged(event, stepper) {
    if (event.selectedIndex === 0) {
      this.investorTypeId = 0;
    }
  }

  public investorDetailsSubmitted() {
    this.submitting = true;
    this.usersClient.registerSandbox({ acceptTermsOfUse: true, ...this.userDetails.form.value, ...this.finalStep.form.value })
      .subscribe(
        data => {
          this.loggingIn = true;
          this.store.dispatch(new Login(data.token));
        },
        error => {
          if (error.status === 400) {
            this.toastr.error("Please correct all validation errors and try again.");
            this.stepper.selectedIndex = 1;
            this.userDetails.handleSubmitError(error);
            this.finalStep.handleSubmitError(error);
          }
        })
      .add(() => {
        this.submitting = false;
      });
  }
}
