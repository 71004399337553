import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shadow-page-header',
  templateUrl: './shadow-page-header.component.html',
  styleUrls: ['./shadow-page-header.component.scss']
})
export class ShadowPageHeaderComponent implements OnInit {
  @Input() showSpinner = true;

  constructor() { }

  ngOnInit() {
  }

}
