import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import Swiper from "swiper/types/swiper-class";

// import Swiper core and required components
import SwiperCore , {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';

export interface MediaItem {
  id: string;
  url: string;
  type: string;
  embedded: any;
  yCoordinate: number;
  xCoordinate: number;
}

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

@Component({
  selector: 'app-media-swiper',
  templateUrl: './media-swiper.component.html',
  styleUrls: ['./media-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaSwiperComponent {
  @Input() public mediaItems: MediaItem[];
  @Input() public index: number;
  currentIndex: number = 0;

  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;

  ngOnInit() {
    this.currentIndex = this.index;
  }

  public onIndexChange(index: number): void {
    this.currentIndex = index;
  }

  public getCurrentIndex(): number {
    let index: number;
    if (this.currentIndex >= 0) {
      index = this.currentIndex;
    } else if (this.index >= 0) {
      index = this.index;
    } else {
      throw Error('Unable to determine image index.');
    }
    return index + 1;
  }
}
