<div class="card full-height">
  <div class="card-header">
    <span class="text-uppercase h3">Messages</span>
  </div>

  <div class="card-body">
    <div class="table-filter-layout">
      <div class="card table-content">
        <div class="table-responsive">
          <table mat-table #table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="sentAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header  class="w-25">Date</th>
              <td mat-cell *matCellDef="let element">
<!--                <div class="row show-mobile-only">-->
<!--                  <div class="col-6 h6 flush cell-key">Date</div>-->
<!--                  <div class="col-6 cell-value"><app-value-display [value]="element.sentAt | date: 'short'"></app-value-display></div>-->
<!--                </div>-->
                <span class=""><app-value-display [value]="element.sentAt | date: 'short'"></app-value-display></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-75">Subject</th>
              <td mat-cell *matCellDef="let element">
<!--                <div class="row show-mobile-only">-->
<!--                  <div class="col-6 h6 flush cell-key">Subject</div>-->
<!--                  <div class="col-6 cell-value"><app-value-display [value]="element.subject"></app-value-display></div>-->
<!--                </div>-->
                <span class=""><app-value-display [value]="element.subject"></app-value-display></span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="has-link" (click)="viewMessage(row)"></tr>
          </table>
        </div>

        <div *ngIf="dataLength === 0 && !isLoadingData" class="mat-table-no-results py-3 py-sm-5">
          <fa-icon class="fa-3x icon-format p-3" icon='envelope'></fa-icon>
          <br>
          You do not have any messages
        </div>

        <div class="mat-table-no-results py-3 py-sm-5" *ngIf="isLoadingData">
          <mat-spinner class="spinner-centre"></mat-spinner>
        </div>

        <mat-paginator #paginator
                       [length]="dataLength"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
    </div>


  </div>
</div>
