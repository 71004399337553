<div>
  <a href="" onclick="return false;" (click)="toggleFilter()"><fa-icon icon="window-close"></fa-icon></a>
</div>
<br />

<div class="search-fields">
  <input *ngIf="allowSearchKeyword" class="form-control form-control-sm" placeholder="Search keywords" (keyup.enter)="triggerDataReload()" [formControl]="searchCtrl">
  <button mat-button color="primary" class="filter-toggle"
          [ngbPopover]="filterPopover"
          #filterPopoverRef="ngbPopover"
          placement="bottom-right"
          popoverClass="filters"
          title="Filter">
    <fa-icon icon="filter" aria-hidden="true"></fa-icon> Add filter
  </button>
  <div class="wide-screen-filter">
    <ng-container *ngTemplateOutlet="filterPopover"></ng-container>
  </div>
  <button mat-flat-button color="primary" (click)="triggerDataReload()" title="Search"><fa-icon icon="search"></fa-icon> Search</button>
  <button mat-flat-button color="accent" *ngIf="allowExport" (click)="exportData()"><fa-icon icon="file-excel"></fa-icon>&nbsp;&nbsp;Export</button>
<!--  <button mat-flat-button color="accent" *ngIf="allowEmail" (click)="emailData()"><fa-icon icon="at"></fa-icon>&nbsp;&nbsp;Send Mail</button>-->
</div>
<div class="clear-fields">
  <app-filter-count [activeFiltersCount]="activeFiltersCount"></app-filter-count>&nbsp;&nbsp;
  <button mat-stroked-button color="secondary" class="small" (click)="clearSearchFilters()" title="Clear search & filters">
    Clear all
  </button>
</div>
