<div class="remove-top-padding login-container">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <div *ngIf="stepper && stepper.selectedIndex !== 0">
          <button id="backButton" type="button" (click)="goBack(stepper)" class="back-button"><fa-icon icon="arrow-left"></fa-icon></button>
        </div>
        <h1 class="pb-sm-3">Log In</h1>

        <mat-horizontal-stepper *ngIf="requiresVerification" #stepper (selectionChange)="stepChanged($event, stepper)" linear class="stepper-no-text stepper-login" [style.display]="isLoginSubmitting ? 'none' : 'block'">
          <mat-step label="login_email_filled" [stepControl]="email.form">
            <app-login-email #email></app-login-email>
          </mat-step>
          <mat-step label="login_password_filled" [stepControl]="auth.form">
            <app-login-auth #auth [autocompletePassword]="email?.form?.value?.password" (formSubmitted)="authSubmitted()"></app-login-auth>
          </mat-step>
          <mat-step label="login_verification_code_filled" [stepControl]="verification.form">
            <app-login-verification #verification [mustEnableMfa]="!isMfaEnabled" [mfaTypeId]="mfaTypeId" [contactNumber]="contactNumber"
                                    (openEnableMfaDialog)="openEnableMfaDialog()"
                                    (resendSmsMfa)="resendSms()" (formSubmitted)="verificationSubmitted()"></app-login-verification>
          </mat-step>
        </mat-horizontal-stepper>

        <mat-horizontal-stepper *ngIf="!requiresVerification" #stepper (selectionChange)="stepChanged($event, stepper)" linear class="stepper-no-text stepper-login-sandbox" [style.display]="isLoginSubmitting ? 'none' : 'block'">
          <mat-step label="login_email_filled" [stepControl]="email.form">
            <app-login-email #email></app-login-email>
          </mat-step>
          <mat-step label="login_password_filled" [stepControl]="auth.form">
            <app-login-auth #auth [autocompletePassword]="email?.form?.value?.password" (formSubmitted)="authSubmitted()"></app-login-auth>
          </mat-step>
        </mat-horizontal-stepper>

          <div *ngIf="isLoginSubmitting">
            <mat-spinner class="spinner-centre"></mat-spinner>
          </div>
        </div>
      </div>
  </div>
</div>
