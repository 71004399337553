import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';


@Directive({
  selector: '[appBackgroundImage]'
})
export class BackgroundImageDirective implements OnChanges, AfterViewInit {
  private el: HTMLElement;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  @Input() appBackgroundImage: string;

  ngAfterViewInit() {
    if (this.appBackgroundImage) {
      this.setBackgroundImage(this.appBackgroundImage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appBackgroundImage']) {
      if (changes.appBackgroundImage.currentValue) {
        this.setBackgroundImage(changes.appBackgroundImage.currentValue);
      } else {
        this.removeBackgroundImage();
      }
    }
  }

  private setBackgroundImage(url: string) {
    this.el.style.backgroundImage = 'url(' + url + ')';
  }

  private removeBackgroundImage() {
    this.el.style.backgroundImage = undefined;
  }
}
