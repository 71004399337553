//FIXME import { AgmCoreModule, LazyMapsAPILoader } from "@agm/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule, InjectionToken } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTreeModule } from "@angular/material/tree";
import { MomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
//FIXME import { NgxIntlTelInputModule } from "ngx-intl-tel-input"

import { RouterModule } from "@angular/router";
import { NgbAlertModule, NgbDatepickerModule, NgbPopoverModule, NgbProgressbarModule, NgbTooltipModule, NgbCarouselModule, NgbDropdownModule, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { DragulaModule } from "ng2-dragula";
import { NgxFilesizeModule } from "ngx-filesize";
import { ImageCropperModule } from "ngx-img-cropper";
import { MarkdownModule } from "ngx-markdown";
import { NgxUploaderModule } from "ngx-uploader";
import { BreadcrumbsModule } from "../libs/breadcrumbs/breadcrumbs.module";
import { FieldRequiredStatusComponent } from "./components/field-required-status/field-required-status.component";
import { FieldComponent } from "./components/field/field.component";
import { FormErrorsComponent } from "./components/form-errors/form-errors.component";
import { HeaderAuthenticatedComponent } from "./components/header-authenticated/header-authenticated.component";
import { IncompleteProfileBannerComponent } from "./components/incomplete-profile-banner/incomplete-profile-banner.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { PictureUploadModalComponent } from "./components/picture-upload-modal/picture-upload-modal.component";
import { VideoUploadModalComponent } from "./components/video-upload-modal/video-upload-modal.component";
import { DocumentUploadModalComponent } from "./components/document-upload-modal/document-upload-modal.component";
import { DataImportModalComponent } from "./components/data-import-modal/data-import-modal.component";
import { FileImportModalComponent } from "./components/file-import-modal/file-import-modal.component";
import { ProfilePicComponent } from "./components/profile-pic/profile-pic.component";
import { SubmitComponent } from "./components/submit/submit.component";
import { UnsavedDialogComponent } from "./components/unsaved-dialog/unsaved-dialog.component";
import { LoginDialogComponent } from "./components/login-dialog/login-dialog.component";
import { ValueDisplayComponent } from "./components/value-display/value-display.component";
import { ChatroomComponent } from "./components/chatroom/chatroom.component";
import { ClosePopoverDirective } from "./directives/close-popover.directive";
import { SideNavParentLiDirective } from "./directives/parent-li.directive";
import { AuthenticatedGuard } from "./guards/authenticated-guard.service";
import { IsAdminGuard } from "./guards/is-admin.guard";
import { UnAuthenticatedGuard } from "./guards/un-authenticated-guard.service";
import { UnsavedChangesGuard } from "./guards/unsaved-changes-guard.service";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { CsrfInterceptorService } from "./interceptors/csrf-interceptor.service";
import { GeoCodingService } from "./services/geo-coding.service";
import { JwtRenewService } from "./services/jwt-renew.service";
import { ModalService } from "./services/modal.service";
import { RedirectService } from "./services/redirect.service";
import { AppToasterService } from "./services/toaster.service";
import { EmbedVideoService } from "./services/embed-video.service";
import { CheckMarkComponent } from "./components/check-mark/check-mark.component";
import { EnumFilterMultiSelectComponent } from "./components/enum-filter-multi-select/enum-filter-multi-select.component";
import { GenericRadioButtonComponent } from "./components/generic-radio-button/generic-radio-button.component";
import { MarkdownFieldComponent } from "./components/markdown-field/markdown-field.component";
import { ShadowPageHeaderComponent } from "./components/shadow-page-header/shadow-page-header.component";
import { BackgroundImageDirective } from "./directives/background-image.directive";
import { PictureDeleteConfirmModalComponent } from "./components/picture-delete-confirm-modal/picture-delete-confirm-modal.component";
import { PictureCarouselModalComponent } from "./components/picture-carousel-modal/picture-carousel-modal.component";
import { ImageSwiperModalComponent } from "./components/image-swiper-modal/image-swiper-modal.component";
import { MediaSwiperComponent } from "./components/media-swiper/media-swiper.component";
import { SwiperModule } from "swiper/angular";
import { StepsComponent } from "./components/steps/steps.component";
import { DeleteConfirmDialogComponent } from "./components/delete-confirm-dialog/delete-confirm-dialog.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { DeclineChatDialogComponent } from "./components/decline-chat-dialog/decline-chat-dialog.component";
import { RouteResolveErrorComponent } from "./components/route-resolve-error/route-resolve-error.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { GenericSearchClient } from "./services/search-api.service";
import { PasswordStrengthMeterComponent } from './components/password-strength-meter/password-strength-meter.component';
import { API_BASE_URL } from "./services/generated-api.service";
import { PrettyNumberPipe } from "./pipes/pretty-number.pipe";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { MatAutocompleteForceSelectionDirective } from "./directives/mat-autocomplete-force-selection.directive";
import { MatAutocompleteSelectDirective } from "./directives/mat-autocomplete-select.directive";
import { FilterCountComponent } from "./components/filter-count/filter-count.component";
import { UserInceptionInterceptor } from "./interceptors/user-inception.interceptor";
import { TermsOfUseAcceptanceModalComponent } from "./components/terms-of-use-acceptance-modal/terms-of-use-acceptance-modal.component";
import { ComboChartComponent } from "./components/combo-chart/combo-chart.component";
import { ComboSeriesVerticalComponent } from "./components/combo-chart/combo-series-vertical.component";
import { EnableMfaModalComponent } from "./components/enable-mfa-modal/enable-mfa-modal.component";
import { UserNamePipe } from "./pipes/user-name.pipe";
import { UploadFileRequirementsComponent } from "./components/upload-file-requirements/upload-file-requirements.component";
import { CookieService } from 'ngx-cookie-service';
import { SearchFilterComponent } from "./components/search-filter/search-filter.component";
import { NgxMaskModule } from 'ngx-mask';
import { UpdateEmailModalComponent } from "./components/update-email-modal/update-email-modal.component";
import { HeaderSideNavService } from "./../services/header-sidenav.service";

import { NgcCookieConsentModule } from 'ngx-cookieconsent';

import { MessageDetailModalComponent } from "../modules/investor/pages/messages/message-detail-modal/message-detail-modal.component";
import { MessagesListComponent } from "../modules/investor/pages/messages/messages-list/messages-list.component";
//FIXME import {NgxKjuaModule} from "ngx-kjua";
//FIXME import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BankAccountAddedModalComponent} from "./components/bank-account-added-modal/bank-account-added-modal.component";
import {BankAccountEditModalComponent} from "./components/bank-account-edit-modal/bank-account-edit-modal.component";

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ".",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: ","
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // Forms
    FormsModule,
    ReactiveFormsModule,
    // Recaptcha
    RecaptchaModule,
    RecaptchaFormsModule,
    NgSelectModule,
    CurrencyMaskModule,
    MarkdownModule.forRoot(),
    SwiperModule,
    NgxFilesizeModule,
    // Bootstrap
    NgbPopoverModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    NgbAlertModule,
    NgbCarouselModule,
    NgxChartsModule,
    NgbTimepickerModule,
    // Material
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatCardModule,
    MatChipsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatRadioModule,
    MatSliderModule,
    MatStepperModule,
    MomentDateModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatTreeModule,
    MatSlideToggleModule,
    // Image uploads
    NgxUploaderModule,
    ImageCropperModule,
    // Password
    //FIXME AgmCoreModule,
    DragulaModule,
    BreadcrumbsModule,
    NgxMaskModule.forRoot({validation: true, dropSpecialCharacters: false}),
    NgcCookieConsentModule,
    //FIXME NgxKjuaModule,
    //FIXME NgxIntlTelInputModule,
    FontAwesomeModule,
  ],
    declarations: [
        // Components
        FieldComponent,
        FieldRequiredStatusComponent,
        FormErrorsComponent,
        HeaderAuthenticatedComponent,
        IncompleteProfileBannerComponent,
        LoaderComponent,
        PictureUploadModalComponent,
        VideoUploadModalComponent,
        DocumentUploadModalComponent,
        DataImportModalComponent,
        FileImportModalComponent,
        ProfilePicComponent,
        SubmitComponent,
        UnsavedDialogComponent,
        CheckMarkComponent,
        EnumFilterMultiSelectComponent,
        GenericRadioButtonComponent,
        MarkdownFieldComponent,
        ShadowPageHeaderComponent,
        PictureDeleteConfirmModalComponent,
        PictureCarouselModalComponent,
        ImageSwiperModalComponent,
        MediaSwiperComponent,
        ValueDisplayComponent,
        ChatroomComponent,
        RouteResolveErrorComponent,
        PageNotFoundComponent,
        LoginDialogComponent,
        FilterCountComponent,
        PasswordStrengthMeterComponent,
        StepsComponent,
        DeleteConfirmDialogComponent,
        ConfirmDialogComponent,
        DeclineChatDialogComponent,
        TermsOfUseAcceptanceModalComponent,
        ComboChartComponent,
        ComboSeriesVerticalComponent,
        EnableMfaModalComponent,
        UploadFileRequirementsComponent,
        SearchFilterComponent,
        UpdateEmailModalComponent,
      BankAccountAddedModalComponent,
      BankAccountEditModalComponent,

        //Pages
        ChangePasswordComponent,
        MessageDetailModalComponent,
        MessagesListComponent,
        // Directives
        ClosePopoverDirective,
        SideNavParentLiDirective,
        BackgroundImageDirective,
        MatAutocompleteForceSelectionDirective,
        MatAutocompleteSelectDirective,
        // Pipes
        PrettyNumberPipe,
        UserNamePipe,
    ],
    exports: [
        // Angular
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // 3rd Party
        NgSelectModule,
        NgxChartsModule,
        NgxUploaderModule,
        ImageCropperModule,
      //FIXME AgmCoreModule,
        CurrencyMaskModule,
        MarkdownModule,
        DragulaModule,
        SwiperModule,
        NgxFilesizeModule,
        FontAwesomeModule,
        // Bootstrap
        NgbPopoverModule,
        NgbDropdownModule,
        NgbProgressbarModule,
        NgbTooltipModule,
        NgbDatepickerModule,
        NgbAlertModule,
        NgbCarouselModule,
        NgbTimepickerModule,
        // Material
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatIconModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatCardModule,
        MatChipsModule,
        MatTooltipModule,
        MatExpansionModule,
        MatRadioModule,
        MatSliderModule,
        MatStepperModule,
        MomentDateModule,
        MatProgressBarModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatTreeModule,
        MatSlideToggleModule,
      //FIXME NgxIntlTelInputModule,
        // App services
        BreadcrumbsModule,
        // App components
        FieldComponent,
        FieldRequiredStatusComponent,
        FormErrorsComponent,
        HeaderAuthenticatedComponent,
        IncompleteProfileBannerComponent,
        LoaderComponent,
        PictureUploadModalComponent,
        VideoUploadModalComponent,
        DocumentUploadModalComponent,
        DataImportModalComponent,
        FileImportModalComponent,
        ProfilePicComponent,
        SubmitComponent,
        UnsavedDialogComponent,
        CheckMarkComponent,
        EnumFilterMultiSelectComponent,
        GenericRadioButtonComponent,
        MarkdownFieldComponent,
        ShadowPageHeaderComponent,
        PictureDeleteConfirmModalComponent,
        PictureCarouselModalComponent,
        ImageSwiperModalComponent,
        MediaSwiperComponent,
        ValueDisplayComponent,
        ChatroomComponent,
        StepsComponent,
        DeleteConfirmDialogComponent,
        ConfirmDialogComponent,
        DeclineChatDialogComponent,
        RouteResolveErrorComponent,
        PageNotFoundComponent,
        LoginDialogComponent,
        FilterCountComponent,
        ComboChartComponent,
        ComboSeriesVerticalComponent,
        UploadFileRequirementsComponent,
        SearchFilterComponent,
        UpdateEmailModalComponent,
        MessageDetailModalComponent,
        MessagesListComponent,
        // App directives
        ClosePopoverDirective,
        SideNavParentLiDirective,
        BackgroundImageDirective,
        // App pipes
        PrettyNumberPipe,
        UserNamePipe
    ],
    providers: [
        // Guards
        AuthenticatedGuard,
        IsAdminGuard,
        UnAuthenticatedGuard,
        UnsavedChangesGuard,
        HeaderSideNavService,
        // Interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CsrfInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserInceptionInterceptor,
            multi: true
        },
        {
            provide: API_BASE_URL,
            useValue: ""
        },
        // API clients
        GenericSearchClient,
        { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        CookieService,
    ]
})
export class SharedModule {

  // Ensure the services are singletons in lazy loaded modules
  // https://angular.io/guide/singleton-services#forroot
  // https://goo.gl/mMTLk6

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        JwtRenewService,
        ModalService,
        RedirectService,
        AppToasterService,
        GeoCodingService,
        EmbedVideoService,
        //FIXME LazyMapsAPILoader,
      ]
    };
  }
}
