import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ParamMap } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, first, map, startWith, switchMap } from 'rxjs/operators';
import { PagedResult } from "../services/search-api.service";
import { GenericSearchClient } from "../services/search-api.service";
import { AppToasterService } from '../../shared/services/toaster.service';
import { SendEmailComponent } from "../../modules/admin/components/send-email/send-email.component";

/**
 * Casts a Params object to a HttpParams object. Params key values may be an array.
 * @param params
 */
export function paramsToHttpParams(params: Params): HttpParams {
  let httpParams = new HttpParams();
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach(item => httpParams = httpParams.append(key, item));
    } else {
      httpParams = httpParams.append(key, value);
    }
  }
  return httpParams;
}

/**
 * Base component to be used with components which uses the MatTable component.
 */
@Directive()
export abstract class ListComponent implements OnInit, OnDestroy {
  /**
   * `displayedColumns` should contain the list of columns to be displayed in the table. The names of the columns should following the
   * same naming structure as fields in a Django QuerySet of the API it is interacting with. The reason for this is the column name is
   * used to append the ordering to the API.
   *
   * A column might display more that one Django model field. The name of the column should be in the same format as the ordering filter of
   * the API. Nested field relationships should be separated with two underscored (__) like in Django QuerySet filters. When the table
   * column should be ordered by multiple fields, the fields should be seperated by a comma just like the ordering filter. When
   *
   * @example
   * ['user__name_last,user_name_first,user__name_second']
   *
   * The example above would apply ordering on the user, first by last name, followed by first name and then second name. When the
   * direction of ordering is descending the component will add a dash (-) in front of all column fields when appending the API query
   * parameters.
   *
   * @example
   * ?ordering=-user__name_last,-user_name_first,-user__name_second
   */
  abstract displayedColumns: string[];
  dataSource = new MatTableDataSource();
  dataLength = 0;
  /** When an API request is ongoing */
  isLoadingData = true;
  /** When the first API request is ongoing. This is used to display shadow placeholder on component init. */
  isLoadingInitialData = true;
  /** Default page size of the table */
  pageSize = 20;
  /** Page size option available to the user to select */
  pageSizeOptions = [5, 10, 20, 50, 100];
  reload$ = new Subject();
  searchCtrl: UntypedFormControl = new UntypedFormControl();

  /** Amount of filter applied to the results */
  activeFiltersCount = null;

  /** Options available for the boolean select input in filters */
  booleanFilterOptions = [
    { value: true, name: 'Yes' },
    { value: false, name: 'No' }
  ];

  /** Flag indicating if the search filters should be shown */
  showFilter = false;

  /** Minimum width of screen resolution for the search filter to show up by default */
  minWindowWidth = 1600;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filterPopoverRef') filterPopover: NgbPopover;

  protected subscriptions = new Subscription();

  /** If query parameters should be added to the URL/Route. Component should not add URLs in pages that has more than one ListComponent.*/
  protected addQueryParametersToUrl = true;

  protected constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected genericSearchClient: GenericSearchClient<any>,
    protected dialog: MatDialog,
    protected toastr: AppToasterService
  ) { }

  /**
   * ngAfterViewInit should retrieve the paging, search and filter states from the query parameters before retrieving the data from the API
   * or listening for component changes.
   */
  ngOnInit() {
    // Only listen for the first event, after that components will emit changes that will change the query parameters in the URL.
    // Acting on these changes will trigger a circular loop between events and result in multiple API calls.
    const sub = this.activatedRoute.queryParamMap
      .pipe(first())
      .subscribe(queryParams => {
        if (this.paginator) {
          // Get the page index
          const page = queryParams.get('page');
          if (page) {
            this.paginator.pageIndex = Number.parseInt(page) - 1;
          }

          // Get the page size
          const pageSize = queryParams.get('pageSize');
          if (pageSize) {
            this.pageSize = Number.parseInt(pageSize);
          }
        }

        if (this.sort) {
          // Get the ordering
          const ordering = queryParams.get('orderBy');
          if (ordering) {
            var orderPortions = ordering.split(" ");
            // Ensure that the column exists.
            if (this.displayedColumns.includes(orderPortions[0])) {
              this.sort.active = orderPortions[0];
              this.sort.direction = orderPortions[1] as SortDirection;
            }
          }
        }

        // Get the search string
        const search = queryParams.get('search');
        if (search) {
          this.searchCtrl.setValue(search);
        }

        this.setFilters(queryParams);

        // Only after unsubscribing from query parameter changes subscribe to component events.
        this.subscribeToEvents();
      });
    this.subscriptions.add(sub);

    // by default, the filter toggle is false. If the screen is large enough, set it to true
    if (window.innerWidth >= this.minWindowWidth) {
      this.toggleFilter();
    }
  }

  ngOnDestroy() {
    // Prevent memory leak.
    this.subscriptions.unsubscribe();
  }

  /**
   * Subscribes to component event changes which should set the state of the paginator and reload data from the API.
   */
  protected subscribeToEvents() {
    // Reset page index when any filter parameter changes
    if (this.paginator) {
      const paginatorObservables = [this.reload$];

      if (this.sort) {
        paginatorObservables.push(this.sort.sortChange);
      }

      this.subscriptions.add(
        merge(...paginatorObservables).subscribe(() => this.paginator.pageIndex = 0)
      );
    }

    // Reload data on event changes.
    const dataReloadObservables = [this.reload$];

    if (this.sort) {
      dataReloadObservables.push(this.sort.sortChange);
    }

    if (this.paginator) {
      dataReloadObservables.push(this.paginator.page);
    }

    const sub = merge(...dataReloadObservables).pipe(
      startWith(null),
      switchMap(() => {
        if (this.filterPopover) {
          this.filterPopover.close();
        }
        this.isLoadingData = true;
        this.dataSource.data = null;

        const queryParams: Params = {};  // Param values may be an array!!!

        if (this.sort.direction) {
          queryParams['orderBy'] = this.sort.active
            .split(',')
            .map(field => `${field} ${this.sort.direction}`)
            .join(',');
        }

        if (this.searchCtrl.value) {
          queryParams['search'] = this.searchCtrl.value;
        }

        // The pager page_size does not always have a value assigned.
        queryParams['pageSize'] = this.paginator.pageSize || this.pageSize;
        queryParams['page'] = this.paginator.pageIndex + 1;

        this.prepareFilterQueryParams(queryParams);

        this.addParamsToActiveRoute(queryParams);

        this.setActiveFiltersCount();

        return this.getData(paramsToHttpParams(queryParams));
      }),
      map(response => {
        this.isLoadingData = false;
        this.isLoadingInitialData = false;

        if (response as PagedResult<any>) {
          this.dataLength = response.rowCount;
          return response.results;
        }

        return response;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          this.isLoadingData = false;
          this.isLoadingInitialData = false;
          return of([]);
        }
        throw error;
      })
    ).subscribe(data => this.dataSource.data = data);

    this.subscriptions.add(sub);
  }

  /**
   * Sets an instance variable of boolean type from a queryParam value.
   * @param queryParamValue The value of the query parameters
   * @param variableName The name of the instance variable.
   * @param fallback The default value to use if no query parameter is present.
   */
  protected setBooleanFromQueryParam(queryParamValue: string | null, variableName: string, fallback?: boolean) {
    if (queryParamValue === null) {
      this[variableName] = fallback || null;
    } else if (queryParamValue === 'false') {
      this[variableName] = false;
    } else if (queryParamValue === 'true') {
      this[variableName] = true;
    }
  }

  /**
   * Sets the active filter count. Should be called every time the data is refreshed.
   */
  protected setActiveFiltersCount() {
    function hasValue(value: any) {
      if (value instanceof Array) {
        return value.length > 0;
      }
      // a value which is false is considered to be a filter value.
      return Boolean(value) || value === false;
    }

    this.activeFiltersCount = 0;

    if (this.searchCtrl.value) {
      this.activeFiltersCount += 1;
    }

    this.getFilters().forEach(item => {
      if (hasValue(item)) {
        this.activeFiltersCount += 1;
      }
    });
  }

  /**
   * Emits a reload event that fetches the data from the API based on the page, sorting, filters and search state.
   */
  public triggerDataReload() {
    this.reload$.next();
  }

  /**
   * Toggles the search filters
   */
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  /**
   * Should be called when the the search input and filters should be cleared or reset.
   */
  clearSearchFilters() {
    this.searchCtrl.setValue('');
    this.clearFilters();
    this.reload$.next();
  }

  /**
   * Sets the URL with query parameters.
   * @param queryParams
   */
  protected addParamsToActiveRoute(queryParams: Params) {
    if (this.addQueryParametersToUrl) {
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams, replaceUrl: true });
    }
  }

  /**
   * Should return the API data.
   * @param queryParams
   */
  protected abstract getData(queryParams: HttpParams): Observable<any>;

  /**
   * Adds the filter state to the query parameters before adding it to the router.
   * @param queryParams
   */s
  protected abstract prepareFilterQueryParams(queryParams: Params);

  /**
   * Should set all filter instance variables from the query parameters map when a navigation change is made in the router.
   * @param queryParams
   */
  protected abstract setFilters(queryParams: ParamMap);

  /**
   * Should return a list of filter instance variables.
   */
  protected abstract getFilters(): any[];

  /**
   * Should set all the instance variables for filters to `null`.
   */
  protected abstract clearFilters();

  protected buildEnumSelection(queryParams: ParamMap, queryParamName: string, definition: any[], selection: any[]) {
    // Don't add duplicate names.
    queryParams.getAll(queryParamName).forEach(type => {
      if (!selection.find(i => i.id === type)) {
        const e = definition.find(i => i.id == type);
        if (e) { selection.push(e); }
      }
    });
  }

  exportData() {
    const queryParams: Params = {};

    if (this.sort.direction) {
      queryParams['orderBy'] = this.sort.active
        .split(',')
        .map(field => `${field} ${this.sort.direction}`)
        .join(',');
    }

    if (this.searchCtrl.value) {
      queryParams['search'] = this.searchCtrl.value;
    }

    this.prepareFilterQueryParams(queryParams);

    return this.doExport(paramsToHttpParams(queryParams)).subscribe();
  }

  emailData(listName: String) {
    const queryParams: Params = {};

    if (this.searchCtrl.value) {
      queryParams['search'] = this.searchCtrl.value;
    }

    this.prepareFilterQueryParams(queryParams);
    
    return this.doEmail(listName, paramsToHttpParams(queryParams));
  }

  protected doExport(queryParams: HttpParams): Observable<any> {
    return of({});
  };

  protected doEmail(listName: String, queryParams: HttpParams): void { 
    this.genericSearchClient.search(`api/${listName}`, queryParams).subscribe(result => {
      const dialogRef = this.dialog.open(SendEmailComponent, { data: result.rowCount });
      dialogRef.updateSize("100vw", "100vh");
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.genericSearchClient.sendEmail(`api/${listName}/send-email`, queryParams, result)
            .subscribe(result => {
              this.toastr.success(`Email has been sent to ${result} investors`);
            });
        }
      });
    });
  }
}
