<div *ngIf="!loggingIn">
  <div *ngIf="stepper.selectedIndex !== 0">
    <button type="button" (click)="goBack(stepper)" class="back-button"><fa-icon icon="arrow-left"></fa-icon></button>
  </div>
  <h1>Registration</h1>
  <h6 class="content-center">This is a sandbox environment that uses demo money</h6>
  <mat-horizontal-stepper #stepper (selectionChange)="stepChanged($event, stepper)" linear class="stepper-no-text  stepper-login">
    <mat-step [stepControl]="userDetails.form">
      <app-user-details-step #userDetails></app-user-details-step>
    </mat-step>

    <mat-step [stepControl]="finalStep.form">
      <app-final-step #finalStep (formSubmitted)="investorDetailsSubmitted()" [style.display]="submitting ? 'none' : 'block'"></app-final-step>
      <div *ngIf="submitting">
        <mat-spinner class="spinner-centre"></mat-spinner>
      </div>
    </mat-step>

  </mat-horizontal-stepper>
  <p class="text-center mt-4">
    Already tried our Sandbox? <a routerLink="/login-sandbox">Log In</a>
  </p>
</div>

<div *ngIf="loggingIn" class="text-center pb-4">
  <p><img src="./assets/images/thumbs-up.svg" alt="" height="99" /></p>
  <h1 class="smaller">You have successfully registered. </h1>
  <br>
  <h1 class="smaller">Logging in... </h1>
</div>

