import { Component, Inject } from '@angular/core';
import { UploaderOptions, UploadFile } from 'ngx-uploader';
import { BaseUploadComponent } from '../../classes/base-upload.component';
import { ConfigService } from '../../../services/config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as api from "../../services/generated-api.service";

@Component({
  selector: 'app-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss']
})
export class DocumentUploadModalComponent extends BaseUploadComponent {
  document: any;
  returnDocName: false;
  immediate = true;
  fileSizeInMB = 20;
  options = {
    concurrency: 1,
    maxUploads: 1,
    allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
    maxFileSize: this.fileSizeInMB*1024*1024
  } as UploaderOptions;

  constructor (
    @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(ConfigService) protected configService: ConfigService,
    @Inject(Store) protected store: Store,
  ) {
    super(configService, store);
  }

  get private() { return this.containerName === "private-documents"; }
  set private(value) {
    this.containerName = value ? "private-documents" : "uploaded-documents";
  }

  protected addedToQueue(file: UploadFile) {
    this.uploadFile(file);
  }

  protected uploadDone(file: UploadFile) {
    const responseObject = new api.EmailAttachmentInputModel({
      fileName: file.name,
      fileMimeType : file.type,
      fileUrl: file.response,
    });
    const returnedResponse = this.returnDocName == null || this.returnDocName == undefined
      ? file.response
      : this.returnDocName ? responseObject : file.response;
    this.activeModal.close(returnedResponse);
  }
}
