import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators, FormGroupDirective } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject, Subscription, TimeoutError, zip } from 'rxjs';


import { debounceTime } from 'rxjs/operators';
import { FieldOptions, FieldType, InputType } from '../components/field/field.component';
import { AppToasterService } from '../services/toaster.service';
import { CanDeactivateComponent } from './can-deactivate.component';
import {json} from "d3";

export interface FormErrors { [key: string]: any; }

export interface BuildOptions {
  requiredFields?: string[];
  readOnlyFields?: string[];
}

/**
 * A component which contains helper methods to handle API server side errors.
 *
 *
 * The component can prevent route changes or page reloads with `UnsavedChangesGuard`. `canDeactivate` will be called to determine if
 * the route change or page reload is allowed. The Guard should be added to the route to prevent routing changed.
 * @example
 * { path: 'profile-edit', component: ProfileEditComponent, canDeactivate: [UnsavedChangesGuard],},
 */
@Directive()
export abstract class FormComponent<T> extends CanDeactivateComponent implements OnInit, OnDestroy {

  @ViewChild(FormGroupDirective) ngForm: FormGroupDirective;

  /** True when an API returned a 404 status. */
  notFound: boolean;
  /**
   * True when a API request is ongoing.
   */
  busy: boolean;
  /**
   * Set to `true` on the first form data submission to the API. Form errors like required fields should only be displayed when
   * touched or when the form has been submitted. The `submitted` state should be set to true to display errors on non touched field.
   */
  submitted = false;

  /** Initial values of the form as fetched from the API without any editing done via the form.*/
  apiData: T;
  /** Options of the API as built from the API OPTIONS request.*/
  options: {[fieldName: string]: FieldOptions} = {};
  /** Subject that emits form submissions */
  private submitSubject: Subject<any>;
  /** Subscription to the form submissions which will debounce form submits */
  protected subscriptions = new Subscription();

  /**
  To prevent sending empty data for form fields which is included in the API options but not on the front end, a list of fields used
  should be defined. Fields not present in the `fields` array would not be added to the reactive form.

  Nested fields are defined in format `parent.child`.
  */
  protected abstract fields: string[];

  /** If a toaster should be displayed when a form error from the server has been returned. Small forms where all the fields are visible
   * does not require a toaster to notify the user of the errors.
   */
  protected showToastrOnFormErrors = true;

  protected constructor(protected toastr?: AppToasterService) {
    super();
  }

  private FieldType = FieldType;

  /**
   * Email validator which does not return an error when the value is blank. The field might not be a required field and the email
   * validator should not return an error when the value is blank.
   */
  private static customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) { return null; }
    return Validators.email(control);
  }

  ngOnInit() {
    this.createForm();
    this.submitSubject = new Subject<any>();
    this.subscriptions.add(
      this.submitSubject.pipe(
        debounceTime(700)
      ).subscribe(() => this._onSubmit())
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  abstract createForm(): void;

  private constructFormGroup(
    fieldDict: {[key: string]: FieldOptions},
    data: any,
    options: BuildOptions = {},
    parentName: string = null
  ): UntypedFormGroup {
    const group: {[key: string]: UntypedFormControl | UntypedFormGroup} = {};

    for (const fieldName of Object.getOwnPropertyNames(fieldDict)) {
      const fieldPath = parentName ? `${parentName}.${fieldName}` : fieldName;

      // Only add fields which has been defined in `this.fields`.
      // If field has a parent field, the parent should implicitly be included.
      // Check if the exact field exists in the `fields` list.
      // Check if the field has any children by checking if any fields starts with `parent.`
      // The dot after the parent is necessary so that `parent_field` is not matched.
      if (this.fields.includes(fieldPath) || this.fields.filter(field => field.startsWith(`${fieldPath}.`)).length) {
        const fieldItem = fieldDict[fieldName];

        if (fieldItem.type === FieldType.nestedObject) {
          group[fieldName] = this.constructFormGroup(fieldItem.children, data[fieldName] || {}, options, fieldPath);
        } else {
          const validators = [];

          // Override required field from options
          if (options.requiredFields && options.requiredFields.includes(fieldPath) || fieldItem.fieldRequired()) {
            validators.push(Validators.required);
          }

          if (fieldItem.maxLength > 0) {
            validators.push(Validators.maxLength(fieldItem.maxLength));
          }
          if (fieldItem.minLength > 0) {
            validators.push(Validators.minLength(fieldItem.minLength));
          }
          if (fieldItem.inputType === InputType.email) {
            validators.push(FormComponent.customEmailValidator);
          }
          if (!(fieldItem.minValue === null || fieldItem.minValue === undefined)) {
            validators.push(Validators.min(fieldItem.minValue));
          }
          if (!(fieldItem.maxValue === null || fieldItem.maxValue === undefined)) {
            validators.push(Validators.max(fieldItem.maxValue));
          }
          if (!(fieldItem.nameProperty === null || fieldItem.nameProperty === undefined)) {
            fieldItem.nameValue = data[fieldItem.nameProperty];
          }

          // Override disabled field from options
          const disabled = options.readOnlyFields && options.readOnlyFields.includes(fieldPath) || fieldItem.readOnly;

          const control = new UntypedFormControl({value: data[fieldName], disabled: disabled}, validators);
          fieldItem.control = control;
          group[fieldName] = control;
        }
      }
    }
    return new UntypedFormGroup(group);
  }

  buildFromOptions(
    optionsObservable: Observable<{ [key: string]: FieldOptions }>,
    dataObservable: Observable<T>,
    options?: BuildOptions,
  );
  buildFromOptions(
    optionsObservable: Observable<{ [key: string]: FieldOptions }>,
    options?: BuildOptions,
  );
  /**
   * Builds a form for Django Rest API Options.
   *
   * Required field could be overridden by `requiredFields`. This is to help with conditional required field where
   * part of a form may be hidden depending on a selection or option.
   *
   * @param optionsObservable
   * @param arg2
   * @param arg3
   */
  buildFromOptions(
    optionsObservable: Observable<{ [key: string]: FieldOptions }>,
    arg2?,
    arg3?
  ) {
    let dataObservable: Observable<T>;
    let buildOptions: BuildOptions;

    if (arg2) {
      if (arg2 instanceof Observable) {
        dataObservable = arg2;
      } else if (arg2 instanceof Object) {
        buildOptions = arg2;
      } else {
        throw new Error('Not implemented');
      }
    }

    if (arg3) {
      if (arg3 instanceof Object) {
        buildOptions = arg3;
      } else {
        throw new Error('Not implemented');
      }
    }


    dataObservable = dataObservable || of({} as T);

    zip(
      dataObservable,
      optionsObservable
    ).subscribe(([data, options]) => {
      this.apiData = data;
      this.options = options;

      this.form = this.constructFormGroup(this.options, this.apiData, buildOptions);

      // Test that all the fields are added to FormControl.
      const excludedFields = this.fields.filter(field => {
        const o = this.getOption(field);
        return !(o && o.control);
      });
      if (excludedFields.length > 0) {
        const excludedString = excludedFields.join(', ');
        throw Error(`The following fields were not added to FormControl: ${excludedString}`);
      }

      this.formBuildComplete();
    }, error => {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        this.notFound = true;
      } else {
        throw error;
      }
    });
  }

  /**
   * Called when the form was built.
   */
  formBuildComplete() {}

  /**
   * Get the FieldOptions from this.options. Supports nested FieldOptions i.e. "user.home_language".
   * @param {string} optionPath
   * @returns {FieldOptions}
   */
  getOption(optionPath: string): FieldOptions {
    let tmp: any = this.options;
    const path = optionPath.split('.');
    for (const i of path) {
      if (tmp instanceof FieldOptions) {
        tmp = tmp.children[i];
      } else {
        tmp = tmp[i];
      }
    }
    return tmp;
  }

  fieldHasErrors(fieldPath: string): boolean {
    const control = this.getFieldControl(fieldPath);
    if (control && (control.touched || this.submitted) && control.errors) {
      return true;
    }
  }

  fieldErrors(fieldPath: string): FormErrors {
    const control = this.getFieldControl(fieldPath);
    if (this.fieldHasErrors(fieldPath)) {
      return control.errors;
    } else {
      return {};
    }
  }

  fieldIsRequired(fieldPath: string): boolean {
    const errors = this.fieldErrors(fieldPath);
    return errors['required'];
  }

  getFieldServerError(name: string): string | boolean {
    const errors = this.fieldErrors(name);
    if (!! errors['server'] && errors['server'].length > 0) {
      return errors['server'][0];
    }
  }

  /**
   * Find the form control. Can be nested.
   * :example
   * 'user.name'
   * @param {string} fieldPath
   * @returns {any}
   */
  getFieldControl(fieldPath: string): UntypedFormControl {
    let control: any = this.form;
    const path = fieldPath.split('.');
    for (const i of path) {
      control = control.get(i);
    }
    return control;
  }

  abstract handleSubmitSuccess(obj: T): void;

  protected attachFormErrors(formGroup: UntypedFormGroup, errors: {}) {
    if (errors.hasOwnProperty('non_field_errors')) {
      formGroup.setErrors({'server': errors['non_field_errors']});
    }

    for (const controlName of Object.getOwnPropertyNames(formGroup.controls)) {
      const controlObj = formGroup.controls[controlName];

      if (errors.hasOwnProperty(controlName)) {
        // Only attach error if the server returned one.
        if (controlObj instanceof UntypedFormGroup) {
          this.attachFormErrors(controlObj, errors[controlName]);
        } else if (controlObj instanceof UntypedFormControl) {
          controlObj.setErrors({'server': errors[controlName]});
          controlObj.markAsTouched({ onlySelf: true });
        } else {
          throw new Error(`${controlObj.constructor.name} is not implemented`);
        }
      }
    }
  }

  protected handleSubmitError(error: any) {
    window.scrollTo(0, 0);
    // todo: Handle all errors without using toastr.
    if (error instanceof TimeoutError && this.toastr) {
      this.toastr.timeoutError();
    } else if (error.status === 400) {
      if (this.toastr && this.showToastrOnFormErrors) {

        let errorDisplayed = false;

        if (error.response.length > 0) {
          const jsonError = JSON.parse(error.response);
          for (const fieldName in jsonError) {
            if (jsonError[fieldName] && jsonError[fieldName].length > 0) {
              this.toastr.error(jsonError[fieldName][0]);
              errorDisplayed = true;
              break; // Stop as soon as an error is found
            }
          }

          // fallback error
          if (!errorDisplayed) {
            this.toastr.error(error.response);
          }

        } else {
          this.toastr.formError();
        }


      }
      this.attachFormErrors(this.form, JSON.parse(error.response));
    } else if (error.status === 403) {
      this.form.setErrors({'server': ['You do not have permission to perform this action.']});
    }
  }

  /**
   * The abstract `submitData` method should be implemented and the form data posted to the API, storage etc.
   * @returns {Observable<T>}
   */
  abstract submitData(formData: any): Observable<any>;

  /**
   * Recursively set form controls as touched.
   * @param {FormGroup} formGroup
   */
  protected setFormAsTouched(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];
      control.markAsTouched();
      if (control instanceof UntypedFormGroup) {
        this.setFormAsTouched(control);
      }
    });
  }

  /**
   * Recursively set form controls as pristine.
   * @param {FormGroup} formGroup
   */
  protected setFormAsPristine(formGroup: UntypedFormGroup) {
    formGroup.setErrors(null);
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];
      control.markAsPristine();
      if (control instanceof UntypedFormGroup) {
        this.setFormAsPristine(control);
      }
    });
  }

  /** Called by the submit subscription */
  protected _onSubmit() {
    const formData = {...this.form.value};

    this.subscriptions.add(
      this.submitData(formData)
        .subscribe(
        success => {
          this.busy = false;
          this.setFormAsPristine(this.form);
            this.handleSubmitSuccess(success);
        },
        error => {
          this.busy = false;
          this.handleSubmitError(error);
        }
      )
    );
  }

  /**
   * This method should be called from the view when the form submit button has been clicked.
   */
  onSubmit() {
    this.setFormAsTouched(this.form);
    if (!this.form.valid) {
      if (this.toastr) {
        this.toastr.formError();
      }

      return false;
    }

    this.submitted = true;
    this.busy = true;
    this.submitSubject.next();
  }

  shouldShowField(fieldName: string) {
    return this.fields && this.fields.find(f => f === fieldName);
  }
}

