import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormComponent } from "../../../../shared/classes/form.component";
import { Router } from "@angular/router";
import { FieldOptions, FieldType, InputType } from "../../../../shared/components/field/field.component";
import { Observable, of } from "rxjs";
import { UntypedFormGroup } from '@angular/forms';
import * as _ from "lodash";

@Component({
  selector: 'app-user-details-step',
  templateUrl: './user-details-step.component.html',
  styleUrls: ['./user-details-step.component.scss']
})
export class UserDetailsStepComponent extends FormComponent<any> implements OnInit {
  successMsg: string;
  protected fields = [];

  @Output() formSubmitted = new EventEmitter<UntypedFormGroup>();

  constructor(
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createForm() {
    let fieldOptions = {
      "firstName": new FieldOptions({
        name: "firstName",
        label: "First Name",
        type: FieldType.input,
        inputType: InputType.text,
        maxLength: 255,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "lastName": new FieldOptions({
        name: "lastName",
        label: "Last Name",
        type: FieldType.input,
        inputType: InputType.text,
        maxLength: 255,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "password": new FieldOptions({
        name: "password",
        label: "Password",
        type: FieldType.input,
        inputType: InputType.password,
        hint: "Your password must contain at least 10 characters",
        minLength: 10,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    this.fields = _.keys(fieldOptions);
    this.buildFromOptions(of(fieldOptions));
  }

  submitData(): Observable<any> {
    return of(this.formSubmitted.emit(this.form));
  }

  public handleSubmitError(error: any) {
    if (error.status === 400) {
      this.attachFormErrors(this.form, JSON.parse(error.response));
    }
  }

  handleSubmitSuccess(respData: any) {

  }
}
