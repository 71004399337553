<div *ngIf=mediaItems class="media-swiper-wrap">
  <div style="height: inherit; border-radius: inherit;">
  <div *ngIf="mediaItems[currentIndex].type === 'image'" style="height: inherit; background-size: 100% 100%; border-radius: inherit;" [ngStyle]="{ 'background-image': 'url(' + mediaItems[currentIndex].url + ')' }"></div>
  <div *ngIf="mediaItems[currentIndex].type === 'video'" class="video-link" [innerHtml]="mediaItems[currentIndex].embedded" style="height: inherit; overflow-y: hidden; border-radius: inherit;"></div>
<!--    <swiper style="height: inherit; border-radius: inherit;" [index]="index" [config]="config" (indexChange)="onIndexChange($event)">-->
<!--        <div *ngFor="let mediaItem of mediaItems" style="height: inherit; border-radius: inherit;">-->
<!--            <div *ngIf="mediaItem.type === 'image'" style="height: inherit; background-size: 100% 100%; border-radius: inherit;" [ngStyle]="{ 'background-image': 'url(' + mediaItem.url + ')' }"></div>-->
<!--            <div *ngIf="mediaItem.type === 'video'" class="video-link" [innerHtml]="mediaItem.embedded" style="height: inherit; overflow-y: hidden; border-radius: inherit;"></div>-->
<!--&lt;!&ndash;            <div *ngIf="mediaItem.type === 'map'" style="height: inherit; border-radius: inherit;">&ndash;&gt;-->
<!--&lt;!&ndash;                <agm-map style="height: inherit;" [fullscreenControl]="true" [streetViewControl]="false" [latitude]="mediaItem.yCoordinate" [longitude]="mediaItem.xCoordinate" [mapTypeControl]="false" [zoom]="10" [styles]="googleMapsStyling">&ndash;&gt;-->
<!--&lt;!&ndash;                    <agm-marker style="width: 50px; height: 50px;" [latitude]="mediaItem.yCoordinate" [longitude]="mediaItem.xCoordinate" [iconUrl]="mediaItem.url">&ndash;&gt;-->
<!--&lt;!&ndash;                    </agm-marker>&ndash;&gt;-->
<!--&lt;!&ndash;                </agm-map>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->
<!--    </swiper>-->
<!--  <swiper #swiperVirtualRef [slidesPerView]="3" [spaceBetween]="50" [pagination]="{ type: 'fraction' }"-->
<!--          [virtual]="true" slideActiveClass="swiper-active" [centeredSlides]="true"-->
<!--          [navigation]="true" class="mySwiper" id="mySwiperID">-->
<!--    <ng-template swiperSlide *ngFor="let slide of mediaItems; index as i">-->
<!--      <img [alt]="slide.url" [src]="slide.url" />-->
<!--    </ng-template>-->
<!--  </swiper>-->
    <br>
<!--    <div class="text-center"><strong>{{getCurrentIndex()}} of {{mediaItems.length}}</strong></div>-->
</div>
