<div class="upload-modal">
  <div class="modal-header">
    <h5 class="modal-title text-uppercase text-primary">Import {{ entityTitle }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="drop-container" [ngClass]="{'hidden': files.length > 0, 'is-drop-over': dragOver}"
         ngFileDrop [options]="options" (uploadOutput)="onImportFile($event)" [uploadInput]="uploadInput">
      <fa-icon size="3x" icon="cloud-upload-alt"></fa-icon>
      <label class="upload-button btn btn-sm btn-primary">
        <input type="file" ngFileSelect [options]="options" (uploadOutput)="onImportFile($event)" [uploadInput]="uploadInput">
        Choose a file
      </label>
      or drag and drop it here.

    </div>

    <p>File needs to be a CSV, JSON or xlxs not bigger than 20MB.</p>
    <div class="alert alert-danger" role="alert" *ngIf="error">
      {{error}}
    </div>

    <div *ngIf="!this.error">
      <div class="upload-item" *ngFor="let f of files;">
        <p class="mb-2 small">Uploading file</p>
        <ngb-progressbar type="success" [value]="f?.progress?.data?.percentage">
          {{ f?.progress?.data?.percentage < 100 ? f?.progress?.data?.percentage + '%' : 'Done' }}
        </ngb-progressbar>
      </div>
    </div>

  </div>
</div>
