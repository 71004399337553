//https://stackoverflow.com/questions/55156541/how-to-make-tab-key-as-enter-key-in-angular-material/55159386#55159386

import { Directive, Input, AfterViewInit, OnDestroy, Optional, HostListener } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import {MatAutocompleteTrigger, MatAutocomplete} from '@angular/material/autocomplete';
import {NgControl} from '@angular/forms';

@UntilDestroy()
@Directive({ selector: '[appMatAutocompleteSelect]' })
export class MatAutocompleteSelectDirective implements AfterViewInit, OnDestroy {
  observable: any;
  @Input()
  matAutocomplete: MatAutocomplete;
  constructor(@Optional()
    private autoTrigger: MatAutocompleteTrigger,
    private readonly ngControl: NgControl) { }
  ngAfterViewInit() {
    this.observable = this.autoTrigger.panelClosingActions.pipe(
      untilDestroyed(this)
    ).subscribe(x => {
      //if the user typed the full text, select it for them
      if (this.autoTrigger.activeOption && this.ngControl.control.value && typeof this.ngControl.control.value === "string"
          && this.autoTrigger.activeOption.viewValue.toLowerCase() === this.ngControl.control.value.toLowerCase()) {
          this.ngControl.control.setValue(this.autoTrigger.activeOption.value);
      }
    })
  }

  @HostListener('keydown.tab', ['$event.target']) onBlur() {
    if (this.autoTrigger.activeOption) {
      this.ngControl.control.setValue(this.autoTrigger.activeOption.value);
    }
  }

  ngOnDestroy() { }
}
