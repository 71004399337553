import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Injectable, Inject, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { switchMap, debounceTime, shareReplay, tap, map, startWith } from 'rxjs/operators';
import { FormErrors } from '../../classes/form.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';
//FIXME import {CountryISO} from "ngx-intl-tel-input";


// nextId is used to generate a unique id per form field.
let nextId = 0;

export enum FieldType {
  checkbox,
  choice,
  radio,
  date,
  input,
  textArea,
  currency,
  nestedObject,
  recaptcha,
  autocompleteSearch,
  file,
  phoneNumber,
  time
}

export enum InputType {
  text = 'text',
  number = 'number',
  email = 'email',
  password = 'password'
}

const INPUT_INVALID_CLASS = 'is-invalid';
const INPUT_VALID_CLASS = 'is-valid';


export interface FieldOptionsArguments {
  name: string;
  label: string;
  hint?: string | (() => string);
  hintColor?: string;
  type: FieldType;
  inputType?: InputType;
  allowBlank?: boolean;
  allowNull?: boolean;
  readOnly?: boolean;
  minLength?: number;
  maxLength?: number;
  choices$?: Observable<{ id: any, name: string }[]>;
  search$?: (search: string) => Observable<{ id: any, name: string }[]>;
  fileUrl$?: () => Promise<any>;
  control?: UntypedFormControl;
  children?: {[key: string]: FieldOptions};
  keyDown?: (e : KeyboardEvent) => void;
  currencyPrefix?: string;
  currencyPrecision?: number;
  minValue?: number;
  maxValue?: number;
  nameProperty?: string;
  minRows?: number;
  maxRows?: number;
  dateStartView?: string;
  monthOnly?: Boolean;
  //FIXME preferredCountries?: CountryISO[];
  defaultValue?: any;
  mask?: string;
}

export interface TextArea {
  cols: number;
  rows: number;
}


export class FieldOptions {
  name: string;
  label: string;
  hint: string | (() => string);
  hintColor?: string;
  type?: FieldType;
  inputType: InputType;
  allowBlank = false;
  allowNull = false;
  readOnly = false;
  minLength?: number;
  maxLength?: number;
  choices$?: Observable<{ id: any, name: string }[]>;
  search$?: (search: string) => Observable<{ id: any, name: string }[]>;
  fileUrl$?: () => Promise<any>;
  control?: UntypedFormControl;
  children?: {[key: string]: FieldOptions};
  keyDown?: (e : KeyboardEvent) => void;
  currencyPrefix?: string;
  currencyPrecision?: number;

  // Integer validation.
  minValue?: number;
  maxValue?: number;

  nameProperty?: string;
  nameValue?: string;

  minRows?: number;
  maxRows?: number;

  dateStartView: string = "month";
  monthOnly: Boolean = false;
  //FIXME preferredCountries: CountryISO[] = [CountryISO.Norway, CountryISO.UnitedKingdom];

  defaultValue?: any;
  mask?: string;

  constructor (data?: FieldOptionsArguments) {
    if (data) {
      Object.keys(data).forEach(key => this[key] = data[key]);
    }
  }

  /**
   * Checks if the form field is allowed to be empty.
   * @returns {boolean}
   */
  fieldRequired() {
    // Make sure that `allowBlank` and `allowNull` is not undefined as the field would be marked as required.
    if (this.allowBlank === undefined) {
      console.error(`FieldOption "${this.name}" allowBlank is undefined.`);
    }
    if (this.allowNull === undefined) {
      console.error(`FieldOption "${this.name}" allowNull is undefined.`);
    }
    // Checkboxes should not be marked as required.
    return !this.allowBlank && !this.allowNull && this.type !== FieldType.checkbox;
  }
}

/**
 * Field component that displays the appropriate form input based on the `FieldOptions`.
 */
@Component({
  selector: 'app-form-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    //{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    //{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    //{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS  }
  ],
  encapsulation: ViewEncapsulation.None
})
export class FieldComponent implements OnInit, OnChanges {
  @Input() fieldOption: FieldOptions;
  /** Override the label of the field */
  @Input() label: string;
    /** Override the hint of the field */
  @Input() hint: string;
  /** Override the hintColor of the field */
  @Input() hintColor: string;
  /** Override the default required error message. */
  @Input() requiredErrorMsg: string;
  /** To set the `autocomplete` property of the input. */
  @Input() autocomplete: string;
  /** Minimum selectable date */
  @Input() minDate: NgbDateStruct;
  /** Maximum selectable date */
  @Input() maxDate: NgbDateStruct;
  /** Minimum selectable time */
  @Input() minTime: NgbTimeStruct;
  /** Maximum selectable time */
  @Input() maxTime: NgbTimeStruct;
  /** Will make the field hidden, but still display server errors */
  @Input() hidden: Boolean;
  @Input() passwordStrengthMeter: boolean;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() defaultValue?: any;
  //FIXME @Input() preferredCountries?: CountryISO[];
  fieldControl: AbstractControl;
  /** Needed to access the enum on the view. */
  FieldType = FieldType;
  /** Generated unique ID for an input */
  inputId: string;
  private readonly prefixId: number;

  nameValue?: string;
  searchResults$: Observable<{ id: any, name: string }[]>;
  searchResults: any;

  minRows?: number;
  maxRows?: number;

  /** A reference to ng-content that does content projection */
    @ViewChild('contentRef') contentRef;

  constructor(
  ) {
    this.prefixId = nextId++;
  }

  ngOnInit() {
    if (this.fieldOption.type === FieldType.autocompleteSearch) {
      this.initAutocomplete();
    }

    if (this.fieldOption.defaultValue != undefined) {
      this.fieldOption.control.setValue(this.fieldOption.defaultValue);
    }

  }

  initAutocomplete() {
    //client side autocomplete
    //choices$ must be set in the field options
    if (this.fieldOption.choices$) {
      this.fieldOption.choices$.subscribe(a => {
        this.searchResults = a;
        this.fieldControl.setValidators(selectedValidator(this.searchResults));  //this will overwrite all validators but assumption is other validators don't make sense for autocomplete
        this.searchResults$ = this.fieldControl.valueChanges
          .pipe(startWith(''),
            map(value => typeof value === 'string' ? value : this.autocompleteDisplay(value)),
            map(name => name
              ? (this.searchResults.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) === 0))
            : this.searchResults.slice()));
      });
    }

    //server side autocomplete
    //search$ must be set in the field options
    if (this.fieldOption.search$) {
      this.searchResults$ = this.fieldControl.valueChanges
        .pipe(startWith(''),  //this is nice for UX now (it loads the full list to start), but we should change most of these to the client side filtering
          debounceTime(300),
          switchMap(value => this.fieldOption.search$(value)),
          tap(results => {
            this.searchResults = results;
            this.fieldControl.setValidators(selectedValidator(this.searchResults)); //this will overwrite all validators but assumption is other validators don't make sense for autocomplete
          }),
          shareReplay(1));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fieldOption'] !== undefined) {
      if (changes['fieldOption'].currentValue === undefined) {
        throw new Error('fieldOption cannot be undefined.');
      } else {
        this.fieldControl = changes['fieldOption'].currentValue.control;

        if (this.fieldOption.type === FieldType.autocompleteSearch) {
          this.initAutocomplete();
        }

        if (!this.fieldControl) {
          throw Error(`${changes['fieldOption'].currentValue.name} does not have a control.`);
        }

        this.inputId = `${this.prefixId}_${this.fieldOption.name}`;
      }
    }
  }

  getErrorsContext () {
    return { fieldErrors: this.getFieldErrors(), serverError: this.getFieldServerError() };
  }

  getErrorMessage() {
    if (this.fieldControl.errors['required'])
      return this.requiredErrorMsg || 'This field cannot be blank';

    if (this.fieldControl.errors['minlength'])
      return `Ensure this field has at least ${this.fieldOption.minLength} characters.`;

    if (this.fieldControl.errors['maxlength'])
      return `Ensure this field has no more than ${this.fieldOption.maxLength} characters.`;

    if (this.fieldControl.errors['min'])
      return `Ensure this value is greater than or equal to ${this.minValue}.`;

    if (this.fieldControl.errors['max'])
      return `Ensure this value is less than or equal to ${this.maxValue.toLocaleString()}.`;

    if (this.fieldControl.errors['email'])
      return `Not a valid email address.`;

    if (this.fieldControl.errors['selected'] && this.fieldOption.type === FieldType.autocompleteSearch)
      return this.requiredErrorMsg || 'Please enter a value';

    const serverError = this.getFieldServerError();
    if (serverError)
      return serverError;

    return null;
  }

  getHint() {
    let hint = this.hint || this.fieldOption.hint;

    if (typeof (hint) === 'string')
      return hint;
    else
      return hint();
  }

  private _getLabel() {
    return this.label ? this.label : this.fieldOption.label;
  }

  /**
   * Return the label of the field. The label provided by the component input takes precedence of the `fieldOption` label.
   * @returns {string}
   */
  getLabel(addRequired = false) {
    const label = this._getLabel();
    return addRequired && this.fieldOption.fieldRequired() ? `${label} *` : label;
  }

  getPlaceholder() {
    return this._getLabel();
  }

  hasFieldErrors(): boolean {
    return Boolean(this.fieldControl && this.fieldControl.errors && this.fieldControl.touched);
  }

  /**
   * Returns the field errors if the field has been touched.
   * @returns {FormErrors}
   */
  getFieldErrors(): FormErrors {
    if (this.hasFieldErrors()) {
      return this.fieldControl.errors;
    } else {
      return {};
    }
  }

  /**
   * Returns the first server error if a server error exists in the fieldControl.
   * @returns {string | boolean}
   */
  getFieldServerError(): string | boolean {
    if (this.hasFieldErrors() && this.fieldControl.errors['server'] && this.fieldControl.errors['server'].length > 0) {
      return this.fieldControl.errors['server'][0];
    }
    return false;
  }

  /**
 * Return the class name for the input depending if the field is valid or invalid.
 * @returns {string}
 */
  getValidityClass(): string {
    if (this.fieldControl && this.fieldControl.errors && this.fieldControl.touched) {
      return INPUT_INVALID_CLASS;
    } else {
      return INPUT_VALID_CLASS;
    }
  }

  getContentValidityClass(): string {
    if (this.contentRef.nativeElement.childNodes.length > 0 && this.fieldControl && this.fieldControl.errors && this.fieldControl.touched) {
      return INPUT_INVALID_CLASS;
    } else {
      return INPUT_VALID_CLASS;
    }
  }

  /**
 * Returns true if the password toggle should be shown.
 * @returns {string | boolean}
 */
  togglePassword(): boolean {
    return this.fieldOption.inputType === InputType.password;
  }

  autocompleteDisplay(optionId): string | undefined {
    if (optionId == undefined || optionId == null) return null;

    if (this.searchResults !== undefined && this.searchResults !== null && this.searchResults.length) {
      const result = this.searchResults.find(x => x.id === optionId);
      if (result) {
        return result.name;
      }
    }

    return this.fieldOption.nameValue;

  }

  getFileUrl() {
    this.fieldOption.fileUrl$().then(
      (fileUrl: string) => {
        this.fieldControl.setValue(fileUrl);
      },
      () => null
    );
  }

  monthSelected(date: Moment, datepicker: MatDatepicker<Moment>) {
    if (this.fieldOption.monthOnly) {
      this.fieldControl.setValue(date);
      datepicker.close();
    }
  }
}

export function selectedValidator(options: { id: any, name: string }[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    const selected = options
      .map(option => option.id)
      .find(option => option === control.value);

    return selected ? null : { 'selected': { value: control.value } };

  };
}



