import { Component, Inject, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';
import { UploadFile } from 'ngx-uploader';
import { ConfigService } from '../../../services/config.service';
import { Store } from '@ngxs/store';
import { BaseUploadComponent, UploaderState } from '../../classes/base-upload.component';


export interface AppCropperUploadSettings {
  width: number;
  height: number;
  rounded?: boolean;
  compressRatio?: number;
  /** The name of the file as uploaded to S3 */
  filename?: string;
}


@Component({
  selector: 'app-picture-upload-modal',
  templateUrl: './picture-upload-modal.component.html',
  styleUrls: ['./picture-upload-modal.component.scss']
})
export class PictureUploadModalComponent extends BaseUploadComponent {
  /** The title of the modal */
  title: string;
  settings: AppCropperUploadSettings;
  cropperSettings: CropperSettings;
  cropperData = {};
  image: any;
  showCropper = false;

  @ViewChild('cropper')
  cropper: ImageCropperComponent;

  constructor (
    @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(ConfigService) protected configService: ConfigService,
    @Inject(Store) protected store: Store,
  ) {
    super(configService, store);
    this.containerName = "uploaded-images"
  }

  protected addedToQueue(file: UploadFile) {
    this.image = new Image();
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (loadEvent: any) => {
      this.image.src = loadEvent.target.result;
    };

    this.image.onload = () => {
      this.cropperSettings = new CropperSettings({
        width: this.settings.width,
        height: this.settings.height,
        croppedWidth: this.settings.width,
        croppedHeight: this.settings.height,
        compressRatio: this.settings.compressRatio || 0.9,
        minWidth: this.settings.width,
        minHeight: this.settings.height,
        canvasWidth: 800,
        noFileInput: true,
        rounded: this.settings.rounded || false,
      });
      if (this.settings && (this.image.width < this.settings.width || this.image.height < this.settings.height)) {
        this.uploadInput.emit({ type: 'remove', id: file.id });
        this.error = `Image size should be a minimum of ${this.settings.width}x${this.settings.height} pixels.`;
      } else {
        this.cropperSettings.canvasHeight = Math.round(this.image.height / (this.image.width / this.cropperSettings.canvasWidth));
        this.error = null;
        this.state = UploaderState.crop;
        this.showCropper = true;
      }
    };

    myReader.readAsDataURL(file.nativeFile);
  }

  protected  uploadDone(file: UploadFile) {
    this.activeModal.close(file.response);
  }

  private dataURLtoFile(dataURL, filenamePrefix): File {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    let fileExtension = null;

    if (mime === 'image/png') {
      fileExtension = 'png';
    } else if (mime === 'image/jpeg') {
      fileExtension = 'jpg';
    } else {
      throw new Error(`MIME type ${mime} not implemented.`);
    }

    const filename = `${filenamePrefix}.${fileExtension}`;

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  upload() {
    if (!this.settings.filename) {
      throw new Error('A filename should be set.');
    }
    const croppedFile = this.dataURLtoFile(this.cropperData['image'], this.settings.filename);
    const file = this.files[0];
    file.nativeFile = croppedFile;
    file.name = croppedFile.name;
    this.uploadFile(file);
  }

  submitDisabled() {
    return this.state !== UploaderState.crop; 
  }
}
