<div *ngIf="messageToggle" class="chat-container">
    <div class="chat-header">
        <fa-icon class="show-mobile-only" icon="bars" style="float: left;" (click)="toggleSidebar()"></fa-icon>
        Conversations
        <fa-icon icon="times" style="float: right;" (click)="toggleMessagePopUp()"></fa-icon>
    </div>
    <div class="row chat-body">
        <div [ngClass]="isMobileSidebarActive ? 'col-4 conversation-list mobile-sidebar-active' : 'col-4 conversation-list'">
            <div class="user-profile-info">
                <app-profile-pic class="profile-pic-sm" [user]="{ firstName: user.firstName.toUpperCase(), lastName: user.lastName.toUpperCase(), userTypeId: user.isAdmin ? 3 : 1}"></app-profile-pic>
                <div class="user-profile-name">{{user.firstName}} {{user.lastName}}</div>
                <div class="user-profile-email">{{user.emailAddress}}</div>
            </div>
            <hr/>
            <div matTooltip="{{chatInfo.name}}" (click)="setChat(chatInfo.id)" [ngClass]="chatInfo.id == currentChat.id ? 'conversation-item active' : 'conversation-item'" *ngFor="let chatInfo of chatsInfo">
                {{chatInfo.name}} 
            </div>
        </div>
        <div class="col-8 chat-area">
            <div class="chat-info-section" *ngIf="!currentChat.isActive">
                <div class="chat-info-description">This chat is currently not available</div>
            </div>
            <app-loader *ngIf="loading"></app-loader>
            <div *ngIf="!loading" class="messages-container" id="messages-container">
                <div *ngIf="messages.currentPage < messages.pageCount" class="see-more-container" (click)="getMoreMessages()">
                    See more
                </div>
                <div class="message-container" #chatMessageElement *ngFor="let message of messages.results.slice().reverse()">
                    <div *ngIf="message.statusId == 4" class="message-deleted">This message has been removed.</div>
                    <app-profile-pic *ngIf="message.statusId == 1 || message.statusId == 2 || message.statusId == 3" class="profile-pic-xs" [user]="{ firstName: message.fromUserFirstName.toUpperCase(), lastName: message.fromUserLastName.toUpperCase(), userTypeId: message.fromUserIsAdmin ? 3 : 1 }"></app-profile-pic>
                    <div *ngIf="message.statusId == 1 || message.statusId == 2 || message.statusId == 3" [ngClass]="allowDelete ? 'message-body message-body-admin-width' : 'message-body'">
                        <div class="message-time-stamp">{{message.createdOn | date : 'short' }}</div>
                        <div class="message-sender" matTooltip="{{message.fromUserFirstName}} {{message.fromUserLastName}}">{{message.from}}</div>
                        <div class="message-text">{{message.body}}</div>
                        <div class="message-status" *ngIf="!allowDelete">
                            <fa-icon icon="check"></fa-icon>
                            <fa-icon *ngIf="message.statusId == 2" icon="check"></fa-icon>
                        </div>
                        <div class="admin-reply-message" *ngIf="message.adminReply">
                            <hr/>
                            {{message.adminReply}}
                            <fa-icon icon="reply"></fa-icon>
                        </div>
                    </div>
                    <fa-icon *ngIf="currentChat.isActive && message.id && allowDelete && message.statusId == 2" class="message-delete-icon" icon="trash-alt" (click)="deleteMessage(message.id)"></fa-icon>
                    <fa-icon *ngIf="currentChat.isActive && message.id && allowDelete && message.statusId == 1" class="message-decline-icon" icon="times" (click)="declineMessage(message.id)"></fa-icon>
                    <fa-icon *ngIf="currentChat.isActive && message.id && allowDelete && message.statusId == 1" class="message-approve-icon" icon="check" (click)="approveMessage(message.id)"></fa-icon>
                </div>
            </div>
            <div *ngIf="currentChat.isActive" class="message-area-footer">
                <textarea (keyup.enter)="sendMessageToChat()" class="input-text-area" placeholder="Enter a message" [(ngModel)]="draftMessage"></textarea>
                <button class="send-button" (click)="sendMessageToChat()">Send</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="currentChat && !messageToggle" class="open-chat-button" (click)="toggleMessagePopUp()">
    <fa-icon class="chat-icon" icon="comment-alt"></fa-icon>
</div>
