<div>
  <div class="strength-meter">
    <div class="strength-meter-fill" [style.background]="getMeterFillColor(passwordStrength)" [attr.data-strength]="passwordStrength"></div>
  </div>
  <ng-container *ngIf="enableFeedback && feedback">
    <small class="password-feedback" *ngIf="feedback.warning">
      {{feedback.warning}}
    </small>
    <small class="password-feedback" *ngIf="feedback.suggestions && feedback.suggestions.length > 0">
      <ng-container *ngFor="let suggetion of feedback.suggestions">{{suggetion}}</ng-container>
    </small>
  </ng-container>
</div>
