import { Injectable, Inject } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { AppToasterService } from "../services/toaster.service";
import * as api from "../services/generated-api.service";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class InvestorNotificationsService {
  private connection: signalR.HubConnection;
  private investorId: string;

  constructor(
    @Inject(AppToasterService) protected toastr: AppToasterService,
  ) {
    this.depositReceived.subscribe(message => this.toastr.success(`Deposit for ${message.tokenSymbol} ${message.amount.toLocaleString()} has been received.`));
    this.withdrawalProcessed.subscribe(message => this.toastr.success(`Withdrawal of ${message.tokenSymbol} ${message.amount.toLocaleString()} has been processed.`));
    this.marketplaceListingStatusChanged.subscribe(message => this.toastr.info(`Marketplace listing has been ${message.statusName}.`));

    this.investorChatMessagePending.subscribe(pendingMessage => this.toastr.success(`Your message has been sent. You will see two ticks once it has been published.`));
    this.investorChatMessageApproved.subscribe(approvedMessage => this.toastr.success(`Your message has been published.`));
    this.investorChatMessageDeclined.subscribe(declinedMessage => this.toastr.info(`You have received a private response to your message.`));
    this.investorChatMessageDeleted.subscribe(deletedMessage => this.toastr.info(`Your message has been removed.`));
  }

  public connect(investorId: string) {
    this.investorId = investorId;

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`/investor/hub`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.onclose((err) => setTimeout(() => this.tryConnect(), 5000));

    this.connection.on("depositReceived", message => this.depositReceived.next(message));
    this.connection.on("withdrawalProcessed", message => this.withdrawalProcessed.next(message));
    this.connection.on("productPublished", (product: api.ProductDisplayModel) => this.productPublished.next(product));
    this.connection.on("productPurchased", (productId: string, amount: number) => this.productPurchased.next({ productId, amount }));
    this.connection.on("transactionsUpdated", () => this.transactionsUpdated.next());
    this.connection.on("marketplaceListingStatusChanged", message => this.marketplaceListingStatusChanged.next(message));
    this.connection.on("marketplaceListingsUpdated", () => this.marketplaceListingsUpdated.next());
    this.connection.on("marketplaceListingFulfilled", (listingId: string, message: {amount: number}) => this.marketplaceListingFulfilled.next({ listingId, amount: message.amount }));
    this.connection.on("reinvestInterestToggled", () => this.reinvestInterestToggled.next());
    
    this.connection.on("voteCast", () => this.voteCast.next());

    this.connection.on("investorChatMessagePending", pendingMessage => this.investorChatMessagePending.next(pendingMessage));
    this.connection.on("investorChatMessageApproved", approvedMessage => this.investorChatMessageApproved.next(approvedMessage));
    this.connection.on("investorChatMessageDeclined", declinedMessage => this.investorChatMessageDeclined.next(declinedMessage));
    this.connection.on("investorChatMessageDeleted", deletedMessage => this.investorChatMessageDeleted.next(deletedMessage));

    this.connection.on("chatMessagePending", pendingMessage => this.chatMessagePending.next(pendingMessage));
    this.connection.on("chatMessageApproved", approvedMessage => this.chatMessageApproved.next(approvedMessage));
    this.connection.on("chatMessageDeclined", declinedMessage => this.chatMessageDeclined.next(declinedMessage));
    this.connection.on("chatMessageDeleted", deletedMessage => this.chatMessageDeleted.next(deletedMessage));
    
    this.tryConnect();
  }

  public disconnect() {
    if (!this.connection) return;

    this.connection.onclose(() => { });
    this.connection.stop();
    this.connection = null;
  }

  tryConnect() {
    this.retry(() => this.connection.start().then(() => this.connection.invoke("register", this.investorId)));
  }

   retry(fn, retries = 10, err = null) {
    if (!retries) {
      return Promise.reject(err);
    }
    return fn().catch(err => {
      return setTimeout(this.retry(fn, (retries - 1), err), 1000)
    });
  }

  public depositReceived = new Subject<{ amount: number, tokenId: string, tokenSymbol: string }>();
  public withdrawalProcessed = new Subject<{ amount: number, tokenId: string, tokenSymbol: string }>();
  public productPublished = new Subject<api.ProductDisplayModel>();
  public productPurchased = new Subject<{ productId: string, amount: number }>();
  public transactionsUpdated = new Subject();
  public marketplaceListingStatusChanged = new Subject<{ statusName: string }>();
  public marketplaceListingsUpdated = new Subject();
  public marketplaceListingFulfilled = new Subject < { listingId: string, amount: number }>();
  public reinvestInterestToggled = new Subject();

  public voteCast = new Subject();
  
  public investorChatMessagePending = new Subject<api.ChatMessageDisplayModel>();
  public investorChatMessageApproved = new Subject<api.ChatMessageDisplayModel>();
  public investorChatMessageDeclined = new Subject<api.ChatMessageDisplayModel>();
  public investorChatMessageDeleted = new Subject<api.ChatMessageDisplayModel>();

  public chatMessagePending = new Subject<api.ChatMessageDisplayModel>();
  public chatMessageApproved = new Subject<api.ChatMessageDisplayModel>();
  public chatMessageDeclined = new Subject<api.ChatMessageDisplayModel>();
  public chatMessageDeleted = new Subject<api.ChatMessageDisplayModel>();
}
