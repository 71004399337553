import { Component, Inject } from '@angular/core';
import { UploaderOptions, UploadFile, UploadOutput } from 'ngx-uploader';
import { BaseUploadComponent } from '../../classes/base-upload.component';
import { ConfigService } from '../../../services/config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { AppToasterService } from "../../../shared/services/toaster.service";

@Component({
  selector: 'app-data-import-modal',
  templateUrl: './data-import-modal.component.html',
  styleUrls: ['./data-import-modal.component.scss']
})
export class DataImportModalComponent extends BaseUploadComponent {
  document: any;
  immediate = true;
  entityImporting: string = null;
  entityTitle: string = null;
  
  options = {
    concurrency: 1,
    maxUploads: 1,
    allowedContentTypes: ['text/csv', 'application/vnd.ms-excel', 'application/json', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    maxFileSize: 20*1024*1024
  } as UploaderOptions;

  constructor (
    @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
    @Inject(ConfigService) protected configService: ConfigService,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(Store) protected store: Store,
  ) {
    super(configService, store);
  }

  ngOnInit() {
    if (this.entityTitle == null) {
      this.entityTitle = this.entityImporting;
    }
    super.ngOnInit();
  }

  protected addedToQueue(file: UploadFile) {
    this.importData(file, this.entityImporting);
  }

  public onImportFile(output: UploadOutput): void {
    this.onUploadOutput(output, this.entityImporting);
  }

  protected uploadDone(file: UploadFile) {
    this.toastr.success(`${this.entityTitle.charAt(0).toUpperCase()}${this.entityTitle.substring(1)}s have successfully been imported.`);
    this.activeModal.close(file.response);
  }
}
