import { Component, EventEmitter, Input, ViewEncapsulation, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-generic-radio-button',
    templateUrl: './generic-radio-button.component.html',
    styleUrls: ['./generic-radio-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
  })
  export class GenericRadioButtonComponent {
    @Input() selectedOption: boolean = null;

    @Input() heading: string;
    @Input() options: { name: string, value: string }[];

    @Output() selectedOptionChange = new EventEmitter<boolean>();

    selectOption() {
        this.selectedOptionChange.emit(this.selectedOption);
    }
  }