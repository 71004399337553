<h1 class="in-header-title">Change Password</h1>

<div class="col-xs-12 col-md-8 col-lg-6">
  <div class="card">
    <div class="card-header">
      <span class="text-uppercase h3">Select New Password</span>
    </div>
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
        <app-form-errors [formGroup]="form"></app-form-errors>
        <app-form-field [fieldOption]="options['currentPassword']" autocomplete="current-password"></app-form-field>
        <app-form-field [fieldOption]="options['newPassword']" autocomplete="new-password" [passwordStrengthMeter]="true"></app-form-field>
        <div class="form-buttons-right">
          <app-submit [busy]="busy" label="Change" btnClass="btn btn-primary"></app-submit>
        </div>
      </form>
      <div class="loading-blocks" *ngIf="!form">
        <div class="shadow-field"></div>
        <div class="shadow-field"></div>
        <div class="shadow-btn"></div>
      </div>
    </div>
  </div>
</div>
