import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { RedirectService } from '../services/redirect.service';
import { Store } from '@ngxs/store';
import { RequireLoginFromAuthInterceptor } from '../../store/actions';
import { Router } from '@angular/router';
import { AuthState } from '../../store/states';
import { LoginDialogComponent } from '../components/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelper } from '../classes/jwt-helper';
import { RefreshJWT } from '../../store/actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  getNewTokenInProgress = false;
  getNewTokenSource = new Subject();
  getNewToken$ = this.getNewTokenSource.asObservable();
  private jwtHelper = new JwtHelper();
  private refreshingTokenInProgress = false;

  constructor(
    @Inject(Store) private store: Store,
    @Inject(RedirectService) private redirectLogin: RedirectService,
    @Inject(Router) private router: Router,
    @Inject(MatDialog) private dialog: MatDialog,
  ) {
  }

  private setAuthToken(request: HttpRequest<any>) {
    const token = this.store.selectSnapshot(AuthState.token);
    if (token) {

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

      // Check if the token is still valid
      if (this.jwtHelper.isTokenValid(token, 15)) {
        // Renew token if it is about to expire.
        if (this.jwtHelper.isTokenAboutToExpire(token, 300)) {
          if (!this.refreshingTokenInProgress) {
            this.refreshingTokenInProgress = true;
            this.store.dispatch(new RefreshJWT());
          }
        } else if (this.refreshingTokenInProgress) {
          this.refreshingTokenInProgress = false;
        }
      }
    }
    return request;
  }

  /**
   * Add auth token to all API requests. Handle unauthenticated and unauthorised requests.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.setAuthToken(request);

    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // Present login dialog to get a new token without logging out the user.
            return this.refreshToken().pipe(
              switchMap(result => {
                // Dialog will return `true` on successful log in.
                if (result) {
                  // Replay HTTP request with updated token.
                  return next.handle(this.setAuthToken(request));
                }
                // Dialog was closed without successful log in.
                throw new Error();
              }),
              catchError(() => {
                // Getting a new token has failed. Log out the user and redirect to log in screen.
                // Only trigger state change once.
                return this.store.selectOnce(AuthState.token).pipe(
                  switchMap(token => {
                    // If token is not set, then the user has been logged out. To cancel the HTTP request, return Observable of `null`.
                    // User should be logged out before cancelling the request as components subscribed to the request will fail
                    // when receiving null data.
                    if (token) {
                      return this.store.dispatch(new RequireLoginFromAuthInterceptor(this.router.routerState.snapshot.url)).pipe(
                        map(() => null)
                      );
                    }
                    return of(null);
                  })
                );
              })
            );
          }
        }
        // All other errors besides HttpErrorResponse should be rethrown.
        return throwError(err);
      })
    );
  }

  refreshToken(): Observable<any> {
    // Do not show log in dialog when already open.
    if (this.getNewTokenInProgress) {
      return this.getNewToken$.pipe(take(1));
    } else {
      this.getNewTokenInProgress = true;
      return this.dialog.open(LoginDialogComponent, { panelClass: 'login-dialog-container' }).afterClosed().pipe(
        tap(result => {
          this.getNewTokenInProgress = false;
          this.refreshingTokenInProgress = false;
          this.getNewTokenSource.next(result);
        })
      );
    }
  }
}
