import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppState } from "../../store/states";
import { Select, Store } from "@ngxs/store";

@Injectable()
export class UserInceptionInterceptor implements HttpInterceptor {

  constructor(@Inject(Store) private store: Store) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userContext = this.store.selectSnapshot(AppState.userContext);

    if (userContext && userContext.isActingOnBehalfOfAnotherUser) {
      req = req.clone({ headers: req.headers
        .set("X-Acting-On-Behalf-Of-Investor-Id", userContext.actingOnBehalfOfUser.investorId)
        .set("X-Acting-On-Behalf-Of-Account-Id", userContext.actingOnBehalfOfUser.investorAccount.id)
        .set("X-Acting-On-Behalf-Of-User-Id", userContext.actingOnBehalfOfUser.userId)
        .set("X-Acting-On-Behalf-Of-Broker-Id", userContext.actingOnBehalfOfUser.brokerId || "") });
    }

    return next.handle(req);
  }
}
