import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AppToasterService {

  constructor(@Inject(ToastrService) private toastr: ToastrService) { }

  success(message?: string): ActiveToast<any> {
    return this.toastr.success(
      message || 'Data has been saved.',
      'Success'
    );
  }

  error(message?: string): ActiveToast<any> {
    return this.toastr.error(
      message || 'An error occurred.',
      'Oh no :('
    );
  }

  formError(): ActiveToast<any> {
    return this.toastr.warning('You have some form errors.', 'Oops');
  }

  timeoutError(): ActiveToast<any> {
    return this.toastr.error('The request has timed out.', 'Oh no :(');
  }

  info(message?: string): ActiveToast<any> {
    return this.toastr.info(
      message,
      'Info'
    );
  }

  /**
   * Displays a toaster when a API error occurs.
   *
   * The function will attempt to get the error from the following responses.
   *
   * `"An error occurred"`
   * `["An error occurred"]`
   * `{"detail": "An error occurred"}`
   * `{"detail": ["An error occurred"]}`
   * `{"message": "An error occurred"}`
   * `{"message": ["An error occurred"]}`
   *
   * @param {HttpErrorResponse} errorResp
   */
  apiError(errorResp: HttpErrorResponse): ActiveToast<any> {
    // Exclude all errors caught by request interceptors.
    // 500 >=, 401 and 403 errors are handled by interceptors.
    if (errorResp.status >= 300 && errorResp.status < 500 && errorResp.status !== 401) {
      const error = errorResp.error;
      let msg = null;
      if (typeof error === 'string') {
        msg = error;
      } else if (Array.isArray(error) && error.length > 0) {
        msg = error[0];
        // Test if isArray before assuming error is an object. Array is also an object.
      } else if (typeof error === 'object') {
        const keys = ['detail', 'message'];
        for (const key of keys) {
          if (error.hasOwnProperty(key)) {
            if (Array.isArray(error[key]) && error[key].length > 0) {
              msg = error[key][0];
            } else if (typeof error[key] === 'string') {
              msg = error[key];
            }
            break;
          }
        }
      } else {
        throw new Error('Not implemented.');
      }

      return this.toastr.error(msg || 'An error occurred.', 'Oh no :(');
    }
  }
}


