<div class="app-loader">
  <div class="spinner-wrapper">
    <img *ngIf="!alternateNameToggle" src="./assets/images/munio-logo.svg" width="89" height="40" class="logo" />
    <img *ngIf="alternateNameToggle" src="{{alternateNameLogoUrl}}" width="89" height="40" class="logo" />
    <div class="spinner">
      <div class="inner">
        <div class="gap"></div>
        <div class="left">
          <div class="half-circle"></div>
        </div>
        <div class="right">
          <div class="half-circle"></div>
        </div>
      </div>
    </div>
  </div>
</div>
