import { Injectable, Inject } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { AppToasterService } from "../services/toaster.service";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class SupplierNotificationsService {
  private connection: signalR.HubConnection;
  private supplierId: string;

  constructor(
    @Inject(AppToasterService) protected toastr: AppToasterService,
  ) {    
  }

  public connect(supplierId: string) {
    this.supplierId = supplierId;

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`/supplier/hub`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.onclose((err) => setTimeout(() => this.tryConnect(), 5000));

    this.connection.on("taskModified", (id: string) => this.taskModified.next(id));
    this.connection.on("taskConstraintModified", (id: string) => this.taskConstraintModified.next(id));
    this.connection.on("quoteModified", (id: string) => this.quoteModified.next(id));
    this.connection.on("contractModified", (id: string) => this.contractModified.next(id));    

    
    this.tryConnect();
  }

  public disconnect() {
    if (!this.connection) return;

    this.connection.onclose(() => { });
    this.connection.stop();
    this.connection = null;
  }

  tryConnect() {
    this.retry(() => this.connection.start().then(() => this.connection.invoke("register", this.supplierId)));
  }

   retry(fn, retries = 10, err = null) {
    if (!retries) {
      return Promise.reject(err);
    }
    return fn().catch(err => {
      return setTimeout(this.retry(fn, (retries - 1), err), 1000)
    });
  }
    
  public taskModified = new Subject<string>();
  public taskConstraintModified = new Subject<string>();
  public quoteModified = new Subject<string>();
  public contractModified = new Subject<string>();
}
