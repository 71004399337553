import { Inject, Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { JwtHelper } from '../classes/jwt-helper';
import { Store } from '@ngxs/store';
import { RefreshJWT } from '../../store/actions';
import { switchMap, tap } from 'rxjs/operators';
import { AuthState } from '../../store/states';


@Injectable()
export class JwtRenewService {
  private jwtHelper = new JwtHelper();
  private subscription: Subscription;

  constructor(
    @Inject(Store) private store: Store,
  ) {
  }

  /**
   * Checks if the JWT needs refreshing. If the JWT has expired and the current URL is auth guarded redirect to the log in page.
   * Should not redirect to log in if on an a page that does not require authentication.
   */
  public start() {
    const checkJWT$ = interval(2500).pipe(
      switchMap(() => this.store.selectOnce(AuthState.token)),
      tap(token => {
        if (token) {
          // Check if the token is still valid
          if (this.jwtHelper.isTokenValid(token, 15)) {
            // Renew token if it is about to expire.
            if (this.jwtHelper.isTokenAboutToExpire(token, 35)) {
              this.store.dispatch(new RefreshJWT());
            }
          }
        }
      }),
    );

    if (this.subscription === undefined) {
      this.subscription = checkJWT$.subscribe();
    }
  }

  public stop() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

}
