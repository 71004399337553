import { Component, OnInit, Inject } from '@angular/core';
import { FormComponent } from "../../classes/form.component";
import { AppToasterService } from "../../services/toaster.service";
import { FieldOptions, FieldType, InputType } from "../../components/field/field.component";
import * as data from "../../services/generated-api.service";
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends FormComponent<void> implements OnInit {
  protected fields = ['currentPassword', 'newPassword'];

  constructor(
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
    @Inject(AppToasterService) protected toastr: AppToasterService
  ) {
    super(toastr);
  }

  createForm() {
    let fieldOptions = {
      "currentPassword": new FieldOptions({
        name: "currentPassword",
        label: "Current Password",
        type: FieldType.input,
        inputType: InputType.password,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "newPassword": new FieldOptions({
        name: "newPassword",
        label: "New Password",
        type: FieldType.input,
        inputType: InputType.password,
        minLength: 10,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
    };

    this.buildFromOptions(of(fieldOptions));
  }

  submitData(): Observable<void> {
    return this.usersClient.changePassword({ ...this.form.value });
  }

  handleSubmitSuccess() {
    this.toastr.success('Password changed successfully.');
    this.form.reset();
  }
}

