import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import * as data from "../../shared/services/generated-api.service";
import { Store } from '@ngxs/store';
import { Login } from '../../store/actions';
import { LoginEmailComponent } from './login-email/login-email.component';
import { LoginAuthComponent } from './login-auth/login-auth.component';
import { LoginVerificationComponent } from './login-verification/login-verification.component';
import { MatStepper } from '@angular/material/stepper';
import { AppToasterService } from "../../shared/services/toaster.service";
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { EnableMfaModalComponent } from '../../shared/components/enable-mfa-modal/enable-mfa-modal.component';
import { ConfigService } from '../../services/config.service';
import{ GoogleAnalyticsService } from '../../services/google-analytics.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {    

  @ViewChild(LoginEmailComponent) email: LoginEmailComponent;
  @ViewChild(LoginAuthComponent) auth: LoginAuthComponent;
  @ViewChild(LoginVerificationComponent) verification: LoginVerificationComponent;
  @ViewChild(MatStepper) stepper: MatStepper;
  
  marketingQueryParams: any;
  isAuthSubmitting = false;
  isLoginSubmitting = false;
  successMsg: string;
  isMfaEnabled = false;
  mfaTypeId: any;
  contactNumber: any;
  totpLink = "";
  totpCode = "";  
  hasPreviousTotpSeed = false;
  requiresVerification = this.configService.settings.featureToggles.enableMfa;

  constructor(
    protected activatedRoute: ActivatedRoute,
    @Inject(data.UsersClient) private usersClient: data.UsersClient,
    @Inject(Store) private store: Store,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(GoogleAnalyticsService) protected googleAnalyticsService: GoogleAnalyticsService,
    @Inject(ConfigService) private configService: ConfigService,
    @Inject(MatDialog) private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.marketingQueryParams = this.activatedRoute.snapshot.queryParams;
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
    this.auth.reset();
  }

  stepChanged(event, stepper) {
    if (stepper.selectedIndex === 2) {
      stepper.selected.interacted = false;
    }

    if(event.selectedIndex > event.previouslySelectedIndex) { 
      this.googleAnalyticsService.emitAnalyticsEvent("login", "engagement", event.previouslySelectedStep.label);
    }
  }
  
  public authSubmitted() {
    this.isAuthSubmitting = true;
    this.googleAnalyticsService.emitAnalyticsEvent("login", "engagement", "login_password_filled");

    if (this.auth.bankId) {
      this.usersClient.requestDispAuthentication(new data.LoginRequest({ ...this.email.form.value }))
        .subscribe(response => window.location.href = response,
          error => this.handleError(error));
      return;
    }

    if (!this.requiresVerification) {    
      return this.verificationSubmitted();
    }
        
    this.usersClient.verifyLogin(new data.LoginRequest({ ...this.email.form.value, ...this.auth.form.value }))
      .subscribe(data => {
        { 
          this.isMfaEnabled = data.isMfaEnabled;
          this.mfaTypeId = data.mfaTypeId;
          this.contactNumber = data.contactNumber;
          this.totpLink = data.totpLinkDisplayModel.totpLink;
          this.totpCode = data.totpLinkDisplayModel.code;
          this.hasPreviousTotpSeed = data.totpLinkDisplayModel.hasPreviousTotpSeed;

          if (this.isMfaEnabled) {
            //check if we can bypass MFA if the user has already activated MFA
            this.usersClient.requireMfa().subscribe(a => {
              if (!a) {
                this.verificationSubmitted();
              }
              else {
                this.isAuthSubmitting = false;
                this.stepper.next();
              }
            })
          }
          else {
            this.isAuthSubmitting = false;
            this.stepper.next();
          }
        }
      },
        error => this.handleError(error));       
  }

  public verificationSubmitted() {
    this.isLoginSubmitting = true;
    
    let loginRequest = this.requiresVerification
      ? new data.LoginRequest({ ...this.email.form.value, ...this.auth.form.value, ...this.verification.form.value })
      : new data.LoginRequest({ ...this.email.form.value, ...this.auth.form.value });

    this.usersClient.login(
      this.marketingQueryParams["utm_source"],
      this.marketingQueryParams["utm_medium"],
      this.marketingQueryParams["utm_campaign"],
      this.marketingQueryParams["utm_term"],
      this.marketingQueryParams["utm_content"],
      loginRequest)
      .subscribe(data => {
        this.googleAnalyticsService.emitAnalyticsEvent("login", "engagement", "login_complete");
        this.store.dispatch(new Login(data.token));
      },
        error => {
          this.googleAnalyticsService.emitAnalyticsEvent("login", "engagement", "login_failed");
          this.handleError(error);
          this.isLoginSubmitting = false;
        });
  }

  handleError(error: any) {
    if (error.status === 400) {
      this.toastr.error("Please correct all validation errors and try again.");
    }

    if (!this.requiresVerification) {
      this.stepper.selectedIndex = 1;
      this.isAuthSubmitting = false;
      this.isLoginSubmitting = false;
      this.auth.handleSubmitError(error);
    }
    else if (this.isAuthSubmitting) {
      this.stepper.selectedIndex = 1;
      this.isAuthSubmitting = false;
      this.auth.handleSubmitError(error);
    }
    else {
      this.stepper.selectedIndex = 2;
      this.isLoginSubmitting = false;
      this.verification.handleSubmitError(error);
    }    
    
  }

  resendSms() {    
    return this.usersClient.sendSmsVerification(this.email.form.value.emailAddress)
      .subscribe();
  }

  openEnableMfaDialog() {
    this.dialog.open(EnableMfaModalComponent, <MatDialogConfig>{
      disableClose: true,
      panelClass: "header-mat-dialog",
      data: {
        contactNumber: this.contactNumber,
        totpLink: this.totpLink,
        totpCode: this.totpCode,
        hasPreviousTotpSeed: this.hasPreviousTotpSeed,
        username: this.email.form.value.emailAddress,
        password: this.auth.form.value.password,
      }

    })
  }
}

