import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { NavigateToDashboardFromUnAuthenticatedGuard } from '../../store/actions';
import { AuthState } from '../../store/states';

@Injectable()
export class UnAuthenticatedGuard implements CanActivate {
  constructor(
    @Inject(Store) private store: Store,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.selectOnce(AuthState.token).pipe(
      map(token => Boolean(token)),
      tap(isAuthenticated => {
        if (isAuthenticated) {
          this.store.dispatch(new NavigateToDashboardFromUnAuthenticatedGuard());
        }
      }),
      map(isAuthenticated => !isAuthenticated)
    );
  }
}
