import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NavigateToDashboardFromHomeComponent } from '../../store/actions';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  alternateNameToggle = false;
  alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";

  constructor(
    @Inject(Store) private store: Store,
    @Inject(ConfigService) private configService: ConfigService)
 { }

  ngOnInit() {
    this.alternateNameToggle = this.configService.settings.featureToggles.useAlternateProjectName;

    let alternateLogoId = this.configService.settings.featureToggles.alternateProjectLogoId;

    if (this.alternateNameToggle) {
      switch (alternateLogoId) {
        case 1:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";
          break;
        case 2:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-2.svg";
          break;
      }
    }

    this.store.dispatch(new NavigateToDashboardFromHomeComponent());
  }

}
