import { Component, Inject, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { FormComponent } from "../../shared/classes/form.component";
import * as api from "../../shared/services/generated-api.service";
import { FieldOptions, FieldType, InputType } from "../../shared/components/field/field.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"]
})
export class ConfirmEmailComponent extends FormComponent<api.PasswordResetResponse> implements OnInit {
  newRequestSucceeded = false;
  tokenIsValid : boolean = null;
  token: string;
  protected fields = ["emailAddress", "recaptcha"];

  constructor(
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['validationToken'];
      if (!this.token) {
        this.tokenIsValid = false;
        return;
      }
      this.usersClient.validate(this.token).subscribe(
        result => {
          this.tokenIsValid = result;
        },
      );
    });
    super.ngOnInit();
  }

  createForm() {
    let fieldOptions = {
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "recaptcha": new FieldOptions({
        name: "recaptcha",
        label: "Recaptcha",
        type: FieldType.recaptcha,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      })
    };

    this.activatedRoute.queryParams.subscribe(a => {
      let model = {
        emailAddress: a["emailAddress"]
      };

      this.buildFromOptions(of(fieldOptions), of(<any>model));
    });
  }

  submitData(): Observable<void> {
    return this.usersClient.confirmEmail({ ...this.form.value });
  }

  handleSubmitSuccess() {
    this.newRequestSucceeded = true;
    this.tokenIsValid = undefined;
  }
}
