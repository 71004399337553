import { Component, OnInit, Inject } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-unauthenticated',
  templateUrl: './unauthenticated.component.html',
  styleUrls: ['./unauthenticated.component.scss']
})
export class UnauthenticatedComponent implements OnInit {

  useAlternateProjectName = false;
  companyName;
  currentYear = new Date().getFullYear();

  constructor(
    @Inject(ConfigService) private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.useAlternateProjectName = this.configService.settings.featureToggles.useAlternateProjectName;
    this.companyName = this.configService.settings.companyName;
  }

}
