import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-submit',
  template: `<button mat-flat-button color="primary" [ngClass]="getClass()" type="submit" [disabled]="busy || disabled" (click)="hasClicked()">
                  <span *ngIf="busy">Busy</span>
                  <span *ngIf="!busy">{{ label || 'Save'}}</span>
                </button>`,
  styles: [``],
})
export class SubmitComponent {
  @Input() busy: boolean;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() btnClass: string | string[] | Set<string> | { [klass: string]: any };
  @Output('clicked') clicked = new EventEmitter();

  constructor() {
  }

  hasClicked() {
    this.clicked.emit();
  }

  getClass() {
    return this.btnClass || ['btn', 'btn-primary'];
  }

}
