<div class="remove-top-padding register-container">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div *ngIf="!sandboxToggle" class="card-body py-4 py-sm-5">

        <div *ngIf="!successMsg">
          <div *ngIf="stepper.selectedIndex !== 0">
            <button id="backButton" type="button" (click)="goBack()" class="back-button"><fa-icon icon="arrow-left"></fa-icon></button>
          </div>
          <div *ngIf="stepper.selectedIndex === 0">
            <button id="backButton" type="button" (click)="goBackPage()" class="back-button"><fa-icon icon="arrow-left"></fa-icon></button>
          </div>
          <h1>Registration</h1>

          <mat-horizontal-stepper #stepper (selectionChange)="stepChanged($event, stepper)" linear class="stepper-no-text stepper-register">

            <!--Choose to register with bank ID or email-->
            <mat-step label="registration_flow_selected" [completed]="registrationFlow != null">
              <mat-button-toggle-group vertical [(ngModel)]="registrationFlow" (click)="next()">
                <mat-button-toggle value="standard">
                  <mat-icon>
                    <fa-icon class="fa-2x icon-format" icon="envelope-square"></fa-icon>
                  </mat-icon>
                  <div class="type-label">
                    <h5 class="pb-0 mb-0 text-primary type-name">Email</h5>
                    <div class="type-description">Register using your email</div>
                  </div>
                </mat-button-toggle>
                <!--
                <mat-button-toggle value="bankId">
                  <mat-icon>
                    <fa-icon class="fa-2x icon-format" icon="piggy-bank"></fa-icon>
                  </mat-icon>
                  <div class="type-label">
                    <h5 class="pb-0 mb-0 text-primary type-name">Bank ID</h5>
                    <div class="type-description">Register using your Bank ID</div>
                  </div>
                </mat-button-toggle>
                  -->
              </mat-button-toggle-group>
            </mat-step>

            <!--Choose to register as investor or account manager-->
            <mat-step label="registration_type_selected" [completed]="registrationType != null">
              <mat-button-toggle-group vertical [(ngModel)]="registrationType" (click)="next()">
                <mat-button-toggle value="investor">
                  <mat-icon svgIcon="investor"></mat-icon>
                  <div class="type-label">
                    <h5 class="pb-0 mb-0 text-primary type-name">Investor</h5>
                    <div class="type-description">Register as an investor</div>
                  </div>
                </mat-button-toggle>
                <mat-button-toggle value="accountManager">
                  <mat-icon svgIcon="accountManager"></mat-icon>
                  <div class="type-label">
                    <h5 class="pb-0 mb-0 text-primary type-name">Account Manager</h5>
                    <div class="type-description">Register as an account manager</div>
                  </div>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </mat-step>

            <!--Choose to register as individual or company-->
            <mat-step label="investor_type_selected" [completed]="investorType != null">
              <mat-button-toggle-group vertical [(ngModel)]="investorType" (click)="processValues()">
                <mat-button-toggle value="individual">
                  <mat-icon svgIcon="individual"></mat-icon>
                  <div class="type-label">
                    <h5 class="pb-0 mb-0 text-primary type-name">Individual</h5>
                    <div class="type-description">Register an account for yourself.</div>
                  </div>
                </mat-button-toggle>
                <mat-button-toggle value="company">
                  <mat-icon svgIcon="company"></mat-icon>
                  <div class="type-label">
                    <h5 class="pb-0 mb-0 text-primary type-name">Company</h5>
                    <div class="type-description">Register an account for your company.</div>
                    <div class="type-description">You must be the owner or a signatory.</div>
                  </div>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </mat-step>

            <!--Fill in user details-->
            <mat-step label="registration_form_filled" [completed]="formValid">
              <ng-container *ngIf="isIndividualInvestor != null">
                <app-details-step [isStandardRegistration]="isStandardRegistration" [isIndividualInvestor]="isIndividualInvestor" [isAccountManager]="isAccountManager" (formValid)="validateStep($event)"></app-details-step>
              </ng-container>
            </mat-step>

            <!--Final confirmation step-->
            <mat-step>
              <app-final-step #finalStep (formSubmitted)="investorDetailsSubmitted()" [style.display]="submitting ? 'none' : 'block'"></app-final-step>
              <div class="mt-4" *ngIf="submitting">
                <mat-spinner class="spinner-centre"></mat-spinner>
              </div>
            </mat-step>

          </mat-horizontal-stepper>

        </div>

        <!-- Success message -->
        <div *ngIf="successMsg" class="text-center pt-3">
          <p><img src="./../assets/images/thumbs-up.svg" alt="" height="99" /></p>
          <h1 *ngIf="isStandardRegistration" class="smaller">You have successfully registered</h1>
          <h1 *ngIf="!isStandardRegistration" class="smaller">Please Wait</h1>
          <br>
          <p class="lead">{{successMsg}}</p>
        </div>

      </div>

      <div *ngIf="sandboxToggle" class="card-body py-4 py-sm-5">
        <app-sandbox-register></app-sandbox-register>
      </div>

    </div>
  </div>
</div>
