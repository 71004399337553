import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html'
})

export class SearchFilterComponent {    

  @Input() filterPopover: NgbPopover;
  @Input() searchCtrl: UntypedFormControl;
  @Input() activeFiltersCount: any;  
  @Input() allowExport: boolean = true;  
  @Input() allowEmail: boolean = false;
  @Input() allowSearchKeyword: boolean = true;  

  @Output() reload: EventEmitter<void> = new EventEmitter<void>();
  @Output() export: EventEmitter<void> = new EventEmitter<void>();
  @Output() email: EventEmitter<void> = new EventEmitter<void>();
  @Output() showFilter: EventEmitter<void> = new EventEmitter<void>();
  @Output() clearSearch: EventEmitter<void> = new EventEmitter<void>();
  
  triggerDataReload() {
    this.reload.emit();
  }

  exportData() {
    this.export.emit();
  }

  emailData() {
    this.email.emit();
  }

  toggleFilter() {
    this.showFilter.emit();
  }

  clearSearchFilters() {
    this.clearSearch.emit();
  }

}
