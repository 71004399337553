import { Component, Inject, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { FormComponent } from "../../classes/form.component";
import * as api from "../../services/generated-api.service";
import { Select, Store } from "@ngxs/store";
import { SetTokenFromAuthInterceptor } from "../../../store/actions";
import { AppState } from "../../../store/states";
import { switchMap, take } from "rxjs/operators";
import { UntypedFormGroup } from "@angular/forms";
import { FieldOptions, FieldType, InputType } from "../field/field.component";
import { ConfigService } from "../../../services/config.service";
import * as _ from "lodash";

@Component({
  selector: "app-login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login-dialog.component.scss"]
})
export class LoginDialogComponent extends FormComponent<api.LoginResponse> implements OnInit {
  protected fields = [];
  account: api.UserAccountModel = null;
  bankIdBusy = false;
  sandboxToggle = false;
  isMfaEnabled = this.configService.settings.featureToggles.enableMfa;

  verificationTokenLength = 6;
  verificationHint = "This is the rolling code generated by your chosen authenticator app";

  @Select(AppState.loggedInAccount) account$: Observable<api.UserAccountModel>;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(Store) private store: Store,
    @Inject(ConfigService) private configService: ConfigService
  ) {
    super();
    this.account$.pipe(take(1)).subscribe(acc => this.account = acc);
  }

  ngOnInit() {
    this.sandboxToggle = this.configService.settings.featureToggles.sandbox;

    this.usersClient.requireMfa().subscribe(a => {
      this.isMfaEnabled = this.isMfaEnabled && a;
      if (this.isMfaEnabled && this.account.mfaTypeId == 2) {
        this.sendMfaSms();
      };
      super.ngOnInit();
    });
  }

  sendMfaSms() {
    this.usersClient.sendSmsVerification(this.account.emailAddress).subscribe();
  }

  createForm() {

    if (this.account.mfaTypeId == 2) {
      this.verificationTokenLength = 7;
      this.verificationHint = "You will receive an SMS with your verification code. This code is valid for 3 minutes";
    }

    let password = new FieldOptions({
      name: "password",
      label: "Password",
      type: FieldType.input,
      inputType: InputType.password,
      allowBlank: false,
      allowNull: false,
      readOnly: false
    });

    let verificationToken = new FieldOptions({
      name: "verificationToken",
      label: "Verification Code",
      hint: this.verificationHint,
      type: FieldType.input,
      inputType: InputType.text,
      minLength: this.verificationTokenLength,
      maxLength: this.verificationTokenLength,
      allowBlank: false,
      allowNull: false,
      readOnly: false
    });

    let fieldOptions = this.isMfaEnabled ? ({ password, verificationToken }) : ({ password });
    this.fields = _.keys(fieldOptions);

    this.buildFromOptions(of(fieldOptions));
  }

  submitData(): Observable<api.LoginResponse> {
    return this.account$.pipe(
      take(1),
      switchMap(account => this.usersClient.login(undefined, undefined, undefined, undefined, undefined, new api.LoginRequest({
        emailAddress: account.emailAddress,
        password: this.form.value.password,
        verificationToken: this.form.value.verificationToken
      }))));
  }

  handleSubmitSuccess(data: api.LoginResponse) {
    this.store.dispatch(
      new SetTokenFromAuthInterceptor(data.token)).subscribe(() => this.dialogRef.close(true)
      );
  }

  protected attachFormErrors(formGroup: UntypedFormGroup, errors: {}) {
    // Reset password value on form error.
    formGroup.get("password").setValue("");
    super.attachFormErrors(formGroup, errors);
  }

  bankIdLogin() {
    this.bankIdBusy = true;
    this.account$.pipe(
      take(1),
      switchMap(account => this.usersClient.requestDispAuthentication(
        new api.LoginRequest({ emailAddress: account.emailAddress }))))
      .subscribe(response => window.location.href = response,
        error => {
          if (error.status === 400) {
            this.attachFormErrors(this.form, JSON.parse(error.response));
          }
        }).add(() => this.bankIdBusy = false);
  }
}
