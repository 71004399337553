import { Component, OnInit } from '@angular/core';
import { PasswordResetComponent } from '../password-reset/password-reset.component';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent extends PasswordResetComponent implements OnInit {

  handleSubmitSuccess() {
    this.toastr.success('Your password has been updated successfully.');
  }

}
