import { Directive, Input, Host, Self, AfterViewInit, OnDestroy } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import {MatAutocompleteTrigger, MatAutocomplete} from '@angular/material/autocomplete';
import {NgControl} from '@angular/forms';

@UntilDestroy()
@Directive({
  selector: '[appMatAutocompleteForceSelection]'
})
export class MatAutocompleteForceSelectionDirective implements AfterViewInit, OnDestroy {

  @Input()
  matAutocomplete: MatAutocomplete;

  constructor(@Host() @Self() private readonly autoCompleteTrigger: MatAutocompleteTrigger,
    private readonly ngControl: NgControl) {  }

  ngAfterViewInit() {
    this.autoCompleteTrigger.panelClosingActions.pipe(
      untilDestroyed(this)
    ).subscribe((e) => {
      if (!e || !e.source) {
        const selected = this.matAutocomplete.options
          .map(option => option.value)
          .find(option => option === this.ngControl.value);

        if (this.ngControl.dirty && selected == null) {
          this.ngControl.reset();
        }
      }
    });
  }

  ngOnDestroy() { }
}
