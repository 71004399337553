<div mat-dialog-content>

  <div class="container">
    <div class="row justify-content-center">
      <h1 mat-dialog-title>Update your Email address</h1>
    </div>
    <div class="row justify-content-center">
      <app-profile-pic class="profile-pic-md" [user]="account"></app-profile-pic>
    </div>
  </div>

  <form [formGroup]="form" class="update-email-form" (ngSubmit)="onSubmit()" *ngIf="form; else loading" novalidate>
    <app-form-errors [formGroup]="form"></app-form-errors>

    <div class="mt-3">
      <app-form-field [fieldOption]="options['newEmailAddress']"></app-form-field>
    </div>

    <div *ngIf="!isBankId">
      <app-form-field [fieldOption]="options['password']" autocomplete="current-password"></app-form-field>
      <div *ngIf="isMfaEnabled">
        <div *ngIf="account.mfaTypeId == 2; else appMfa" class-="row">
          <app-form-field [fieldOption]="options['verificationToken']" class="col-8"></app-form-field>
          <button class="btn btn-block btn-primary col-4" (click)="sendMfaSms()">Send code</button>
        </div>
        <ng-template #appMfa>
          <app-form-field [fieldOption]="options['verificationToken']"></app-form-field>
        </ng-template>
      </div>
    </div>
  </form>


  <ng-template #loading>
    <div>
      <div class="shadow-field"></div>
    </div>
  </ng-template>
</div>

<div mat-dialog-actions align="center">
  <button class="btn btn-default" [mat-dialog-close]>Cancel</button>
  <div *ngIf="isBankId; else standardUser">
    <button (click)="onSubmit()" class="btn btn-primary bankId">Confirm through BankId</button>
  </div>
  <ng-template #standardUser>
    <app-submit (clicked)="onSubmit()" [busy]="busy" label="Confirm" btnClass="btn btn-primary"></app-submit>
  </ng-template>
  
</div>

