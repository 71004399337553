<app-header-unauthenticated></app-header-unauthenticated>
<div class="main-container">
  <div class="unauthenticated-container">
    <router-outlet></router-outlet>
  </div>
</div>

<footer>
</footer>

