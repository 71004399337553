import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { FormComponent } from "../../shared/classes/form.component";
import * as api from "../../shared/services/generated-api.service";
import { FieldOptions, FieldType, InputType } from "../../shared/components/field/field.component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppToasterService } from "../../shared/services/toaster.service";

@Component({
  selector: "app-collect-contact-details-modal",
  templateUrl: "./collect-contact-details-modal.component.html",
  styleUrls: ["./collect-contact-details-modal.component.scss"]
})
export class CollectContactDetailsModal extends FormComponent<api.CollectContactDetailsInputModel> implements OnInit {
  successMsg: string;
  hasQuestions = false;
  wantsInvest = false;
  protected fields = ["jobTitle", "fullName", "emailAddress", "additionalComments", "contactNumber", "countryId"];

  constructor(
    public dialogRef: MatDialogRef<api.CollectContactDetailsInputModel>,
    @Inject(MAT_DIALOG_DATA) public data: {getProspectus: boolean},
    @Inject(api.UsersClient) private usersClient: api.UsersClient,
    @Inject(Router) private router: Router,
    @Inject(AppToasterService) protected toastr: AppToasterService,
    @Inject(api.LookupsClient) private lookupsClient: api.LookupsClient
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createForm() {
    let fieldOptions = {
      "jobTitle": new FieldOptions({
        name: "jobTitle",
        label: "Job Title",
        type: FieldType.input,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: true,
        readOnly: false
      }),
      "emailAddress": new FieldOptions({
        name: "emailAddress",
        label: "Email",
        type: FieldType.input,
        inputType: InputType.email,
        allowBlank: false,
        allowNull: false,
        readOnly: false
      }),
      "fullName": new FieldOptions({
        name: "fullName",
        label: "Full Name",
        type: FieldType.input,
        inputType: InputType.text,
        maxLength: 255,
        allowBlank: false,
        allowNull: true,
        readOnly: false
      }),
      "contactNumber": new FieldOptions({
        name: "phoneNumber",
        label: "Contact Number",
        type: FieldType.phoneNumber,
        inputType: InputType.text,
        allowBlank: false,
        allowNull: false,
        readOnly: false,
      }),
      "countryId": new FieldOptions({
        name: "countryId",
        label: "Country",
        type: FieldType.autocompleteSearch,
        inputType: InputType.text,
        choices$: this.lookupsClient.getCountries(),
        nameProperty: "countryName",
        allowBlank: false,
        allowNull: false,
        readOnly: false,
      }),
      "additionalComments": new FieldOptions({
        name: "reasonForInterest",
        label: "Message",
        minRows: 3,
        type: FieldType.textArea,
        allowBlank: true,
        allowNull: true,
        readOnly: false
      })
    };

    this.buildFromOptions(of(fieldOptions));

    if (this.data.getProspectus) {
      this.getFieldControl("additionalComments").disable();
      this.form.value.hasQuestion = false;
      this.form.value.wantInvest = false;
    }
  }

  reasonChangeInvest() {
    this.hasQuestions = false;
    this.wantsInvest = true;
  }

  reasonChangeQuestion() {
    this.hasQuestions = true;
    this.wantsInvest = false;
  }

  submitData(): Observable<void> {
    this.form.value.getProspectus = this.data.getProspectus;
    this.form.value.hasQuestions = this.hasQuestions;
    this.form.value.wantsInvest = this.wantsInvest;
    return this.usersClient.collectContactDetails({ ...this.form.value });
  }

  handleSubmitSuccess(obj) {
    this.toastr.success("Contact Details have been successfully submitted.")
    this.dialogRef.close(true);
  }
}
