import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-check-mark',
  templateUrl: './check-mark.component.html',
  styleUrls: ['./check-mark.component.scss']
})
export class CheckMarkComponent {
  @Input() value: boolean;

}
