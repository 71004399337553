import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServerErrorComponent } from './server-error.component';
import { ServerErrorInterceptor } from './server-error.interceptor';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ServerErrorComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true
        },
    ]
})
export class ServerErrorModule {}
