import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from "rxjs";
import { AppState } from "../../store/states";
import { Select } from '@ngxs/store';
import { UserContextModel } from '../../store/models';
import * as $ from "jquery";
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header-unauthenticated',
  templateUrl: './header-unauthenticated.component.html',
  styleUrls: ['./header-unauthenticated.component.scss']
})
export class HeaderUnauthenticatedComponent implements OnInit {

  navOpen = false;
  sandboxToggle = false;
  alternateNameToggle = false;
  alternateName = "";
  alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";
  alternateNameSandboxLogoUrl = "./assets/images/alternate-sandbox-1.svg";
  prodUrl = "";
  isHomePage = true;

  userContext: UserContextModel;
  @Select(AppState.userContext) userContext$: Observable<UserContextModel>;

  constructor(
    @Inject(ConfigService) private configService: ConfigService,
    @Inject(Router) private router: Router
  ) { }

  ngOnInit() {
    this.sandboxToggle = this.configService.settings.featureToggles.sandbox;
    this.alternateNameToggle = this.configService.settings.featureToggles.useAlternateProjectName;
    this.alternateName = this.configService.settings.alternateCompanyName;

    let alternateLogoId = this.configService.settings.featureToggles.alternateProjectLogoId;

    if (this.alternateNameToggle) {
      switch (alternateLogoId) {
        case 1:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-1.svg";
          this.alternateNameSandboxLogoUrl = "./assets/images/alternate-sandbox-1.svg";
          break;
        case 2:
          this.alternateNameLogoUrl = "./assets/images/alternate-logo-2.svg";
          this.alternateNameSandboxLogoUrl = "./assets/images/alternate-sandbox-2.svg";
          break;
      }
    }

    if (this.sandboxToggle) this.prodUrl = this.configService.settings.prodUrl;

    this.isHomePage = this.router.url === "/" || this.router.url.includes("/?");
    this.styleHeader();

    this.router.events.subscribe(event => {
      this.isHomePage = this.router.url === "/" || this.router.url.includes("/?");
      this.styleHeader();
    });

  }

  styleHeader() {
    this.router.events.subscribe(event => {
      this.isHomePage = this.router.url === "/" || this.router.url.includes("/?");
    });

    if (this.isHomePage) {
      $("#header").addClass("inactive");
      $(function () {
        $(window).on("scroll", function () {
          if ($(window).scrollTop() > $(window).height()*1/10) {
            $("#header").removeClass("inactive");
            $("#header").addClass("white");
          } else {
            $("#header").addClass("inactive");
            $("#header").removeClass("white");
          }
        });
      });
    } else {
      if (window.outerWidth < 425) {
        $(function () {
          $("#header").removeClass("inactive");
          $(window).on("scroll", function () {
            if ($(window).scrollTop() > 5) {
              $("#header").addClass("white");
            } else {
              $("#header").removeClass("white");
            }
          });
        });
      } else {
        $("#header").removeClass("inactive");
        $("#header").removeClass("white");
        $(function () {
          $(window).on("scroll", function () {
            $("#header").removeClass("inactive");
            $("#header").removeClass("white");
          });
        });
      }
    }
  }

  toggleNav() {
    this.navOpen = !this.navOpen;
  }

  setNavFalse() {
    this.navOpen = false;
  }

  isLoginPage() {
    return this.router.url.includes("log-in") || this.router.url.includes("register")
  }

  isMobile() {
    return window.outerWidth < 768;
  }
}
