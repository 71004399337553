import { Component, OnInit, Output, EventEmitter, Input, OnChanges, Inject } from '@angular/core';
import { FormComponent } from '../../../shared/classes/form.component';
import { FieldOptions, FieldType, InputType } from "../../../shared/components/field/field.component";
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import * as _ from "lodash";
import * as api from "../../../shared/services/generated-api.service";

@Component({
  selector: 'app-login-verification',
  templateUrl: './login-verification.component.html',
  styleUrls: ['./login-verification.component.scss']
})
export class LoginVerificationComponent extends FormComponent<any> implements OnInit, OnChanges {

  protected fields = [];
  @Input() mustEnableMfa: boolean;
  @Input() mfaTypeId: any;

  @Input() contactNumber: string;

  @Output() formSubmitted = new EventEmitter<UntypedFormGroup>();
  @Output() resendSmsMfa = new EventEmitter<UntypedFormGroup>();
  @Output() openEnableMfaDialog = new EventEmitter<UntypedFormGroup>();

  verificationTokenLength = 6;
  verificationHint = "This is the rolling code generated by your chosen authenticator app";

  public isAppMfa = true;    

  constructor(
    @Inject(api.LookupsClient) private lookupsClient: api.LookupsClient    
  ) {
    super();
    this.alwaysDeactivate = true;
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  ngOnChanges() {  
    if (this.mustEnableMfa && this.contactNumber != undefined) {
      return of(this.openEnableMfaDialog.emit(this.form));
    } else {
      this.createForm();
    }    
  }

  createForm() {
    if (this.mfaTypeId == 2) {
      this.verificationTokenLength = 7;
      this.verificationHint = "You will receive an SMS with your verification code. This code is valid for 3 minutes";
    }

    let verificationToken = new FieldOptions({
      name: "verificationToken",
      label: "Verification Code",
      hint: this.verificationHint,
      type: FieldType.input,
      inputType: InputType.text,
      minLength: this.verificationTokenLength,
      maxLength: this.verificationTokenLength,
      allowBlank: false,
      allowNull: false,
      readOnly: false
    });

    let fieldOptions = ({ verificationToken });
    this.fields = _.keys(fieldOptions);

    this.buildFromOptions(of(fieldOptions));
  }

  submitData(): Observable<any> {
    return of(this.formSubmitted.emit(this.form));
  }

  protected attachFormErrors(formGroup: UntypedFormGroup, errors: {}) {
    super.attachFormErrors(formGroup, errors);
  }

  public handleSubmitError(error: any) {
    if (error.status === 400) {
      this.attachFormErrors(this.form, JSON.parse(error.response));
    }
  }

  reset() {
    this.setFormAsPristine(this.form);
  }

  handleSubmitSuccess(respData: any) {
  }

  resendSms() {
    return of(this.resendSmsMfa.emit(this.form));
  }
}
