<div class="pt-5 http-status container-fluid text-center">
  <div class="row justify-content-center align-items-center">
    <div class="col-auto">
      <div class="status-number text-primary">404</div>
      <h1>Page not found</h1>
      <p>The page you visited does not exist.</p>
      <button class="btn btn-primary" routerLink="../">Go to Home</button>
    </div>
  </div>
</div>
