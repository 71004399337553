<div class="pre-auth-content remove-top-padding">
  <div class="container-fluid py-3 py-sm-5">
    <div class="card pre-auth-block">
      <div class="card-body py-4 py-sm-5">
        <!-- Register form -->
        <div *ngIf="!successMsg">
          <h1 class="pb-sm-3">Contact Us</h1>
          <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" novalidate>
            <app-form-errors [formGroup]="form"></app-form-errors>
            <app-form-field [fieldOption]="options['jobTitle']"></app-form-field>
            <app-form-field [fieldOption]="options['fullName']" autocomplete="name"></app-form-field>
            <app-form-field [fieldOption]="options['emailAddress']" autocomplete="email" name="username"></app-form-field>
            <app-form-field [fieldOption]="options['reasonForInterest']"></app-form-field>
            <app-form-field [fieldOption]="options['recaptcha']"></app-form-field>
            <app-submit [busy]="busy" [disabled]="!form.valid" label="Submit" btnClass="btn btn-primary btn-lg"></app-submit>
          </form>
          <div class="loading-blocks" *ngIf="!form">
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-field shadow-field-lg"></div>
            <div class="shadow-btn shadow-btn-lg"></div>
          </div>
        </div>

        <!-- Success message -->
        <div *ngIf="successMsg" class="text-center pb-4">
          <p><img src="./assets/images/thumbs-up.svg" alt="" height="99"/></p>
          <h2 class="smaller">Your message has been submitted and we'll be in contact shortly. </h2>
          <br>

        </div>
      </div>
    </div>
  </div>
</div>


