<div #wrapper [ngStyle]="{'display': !value ? 'none' : null}">
  <ng-content></ng-content>
</div>
<ng-container *ngIf="value && !hasContent()">
    <ng-container *ngIf="isBoolean()">
      <fa-icon icon="check"></fa-icon>
    </ng-container>
    <ng-container *ngIf="!isBoolean()">
      {{value}}
    </ng-container>
</ng-container>
<fa-icon *ngIf="!value" icon="minus"></fa-icon>
