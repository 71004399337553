import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';

import { CanDeactivateComponent } from '../classes/can-deactivate.component';
import { UnsavedDialogComponent } from '../components/unsaved-dialog/unsaved-dialog.component';
import { Store } from '@ngxs/store';
import { map, switchMap } from 'rxjs/operators';
import { AuthState } from '../../store/states';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<CanDeactivateComponent> {

  constructor (
    @Inject(Store) private store: Store,
    @Inject(MatDialog) private dialog: MatDialog,
  ) {}

  canDeactivate(component: CanDeactivateComponent): Observable<boolean> | boolean {
    // When the user is not authenticated we should not prevent redirects to the log in page.
    return this.store.selectOnce(AuthState.token).pipe(
      map(token => Boolean(token)),
      switchMap(isAuthenticated => {
        if (isAuthenticated && !component.canDeactivate()) {
          const dialogRef = this.dialog.open(UnsavedDialogComponent);
          return dialogRef.afterClosed();
        }
        else {
          return of(true);
        }
      })
    );
  }
}
