import { Component, Inject, OnInit, Input, NgZone, ViewChildren, QueryList, ElementRef, Output, EventEmitter } from '@angular/core';
import * as api from "../../services/generated-api.service";
import { AppState } from '../../../store/states';
import { Select } from '@ngxs/store';
import { Observable, forkJoin} from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { AppToasterService } from "../../services/toaster.service";
import { ConfigService } from "../../../services/config.service";
import { DeleteConfirmDialogComponent } from "../delete-confirm-dialog/delete-confirm-dialog.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { DeclineChatDialogComponent } from "../decline-chat-dialog/decline-chat-dialog.component";
import { AdminNotificationsService } from "../../push-notifications/admin-notifications.service";
import { InvestorNotificationsService } from "../../push-notifications/investor-notifications.service";
import * as _ from "lodash";

export interface ChatInfoInterface {
  id : string;
  name: string;
  isUnread: boolean;
}

@Component({
  selector: 'app-chatroom',
  templateUrl: './chatroom.component.html',
  styleUrls: ['./chatroom.component.scss']
})

export class ChatroomComponent implements OnInit {
  currentChat: api.ChatDisplayModel;
  messages: api.ChatMessageDisplayModelPagedResult;
  loading: boolean = false;
  isMobileSidebarActive: boolean = false;
  messageToggle: boolean = false;
  user: api.UserAccountModel;
  draftMessage: string = '';

  @Input() chatsInfo: ChatInfoInterface[];
  @Input() allowDelete: boolean;
  @Select(AppState.accountDetails) account$: Observable<api.UserAccountModel>;
  @ViewChildren("chatMessageElement") chatMessageElements: QueryList<ElementRef>;

  constructor(
      @Inject(MatDialog) private dialog: MatDialog,
      @Inject(ConfigService) public configService: ConfigService,
      @Inject(api.ChatsClient) protected chatsClient: api.ChatsClient,
      @Inject(AppToasterService) protected toastr: AppToasterService,
      @Inject(AdminNotificationsService) private adminNotificationsService: AdminNotificationsService,
      @Inject(InvestorNotificationsService) private investorNotificationsService: InvestorNotificationsService,
      private zone: NgZone
  ) { }

  ngOnInit() {
    if(!this.configService.settings.featureToggles.showChat){ return; }
    this.loading = true;
    this.account$.subscribe(result => {
      this.user = result;
      if(this.user.isAdmin) {
        this.adminNotificationsService.chatMessagePending.subscribe((pendingMessage) => this.zone.run(() => this.addMessageOnClientSide(pendingMessage)));
        this.adminNotificationsService.chatMessageApproved.subscribe((approvedMessage) => this.zone.run(() => approvedMessage.fromUserIsAdmin ? this.addMessageOnClientSide(approvedMessage) : this.updateMessageOnClientSide(approvedMessage)));
        this.adminNotificationsService.chatMessageDeclined.subscribe((declinedMessage) => this.zone.run(() => this.updateMessageOnClientSide(declinedMessage)));
        this.adminNotificationsService.chatMessageDeleted.subscribe((deletedMessage) => this.zone.run(() => this.updateMessageOnClientSide(deletedMessage)));

      } else {
        this.investorNotificationsService.investorChatMessagePending.subscribe((pendingMessage) => this.zone.run(() => this.addMessageOnClientSide(pendingMessage)));
        this.investorNotificationsService.chatMessageApproved.subscribe((approvedMessage) => this.zone.run(() => approvedMessage.fromUserIsAdmin || approvedMessage.fromUserId != this.user.userId ? this.addMessageOnClientSide(approvedMessage) : this.updateMessageOnClientSide(approvedMessage)));
        this.investorNotificationsService.chatMessageDeclined.subscribe((declinedMessage) => this.zone.run(() => this.updateMessageOnClientSide(declinedMessage)));
        this.investorNotificationsService.chatMessageDeleted.subscribe((deletedMessage) => this.zone.run(() => this.updateMessageOnClientSide(deletedMessage)));

      }
    });
    var initialChatId = this.chatsInfo[0].id;
    forkJoin(
      this.chatsClient.getChatroom(initialChatId),
      this.chatsClient.getChatroomMessages(1, initialChatId)
    )
    .subscribe(([chatroom, chatroomMessages]) => {
      this.currentChat = chatroom;
      this.messages = chatroomMessages;
      this.loading = false;

      if(this.currentChat.isActive)
        this.currentChat.isActive = new Date().getHours() >= 8 && new Date().getHours() <= 17;

      if(this.user.isAdmin)
        this.currentChat.isActive = true;
    });
  }

  sendMessageToChat() {
    if(this.draftMessage.trim() != '') {
      if(this.draftMessage.length > 1000) {this.toastr.error(`Please make sure your message is less than 1000 chacaters.`); return;}
      this.messages.results.unshift(new api.ChatMessageDisplayModel({
        fromUserId: this.user.userId,
        fromUserFirstName: this.user.firstName,
        fromUserLastName: this.user.lastName,
        from: `${this.user.firstName[0]}. ${this.user.lastName}`,
        createdOn: new Date,
        body: this.draftMessage,
        statusId: 1,
        fromUserIsAdmin: this.user.isAdmin,
        toChatId: this.currentChat.id
      }));
      this.chatsClient.sendMessageToChat(new api.ChatMessageInputModel({
        fromUserId : this.user.userId,
        chatId : this.currentChat.id,
        body : this.draftMessage
      }))
      .subscribe(() => {
          this.draftMessage = '';
      });
    }
  }

  deleteMessage(messageId: string) {
      const dialogRef = this.dialog.open(DeleteConfirmDialogComponent);
      dialogRef.componentInstance.message = `Are you sure you want to delete this message?`;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.chatsClient.censorMessageInChat(messageId)
          .subscribe();
        }
      }, () => null);
  }

  approveMessage(messageId: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.message = `Are you sure you want to make this message visible to all chat members?`;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.chatsClient.approveMessageInChat(messageId)
        .subscribe();
      }
    }, () => null);
  }


  declineMessage(messageId: string) {
    const dialogRef = this.dialog.open(DeclineChatDialogComponent);
    dialogRef.componentInstance.message = `Are you sure you want to reply this message privately?`;
    dialogRef.afterClosed().subscribe(result => {
      if(result == undefined) {return;}
      if(result == '') {this.toastr.error(`Please make sure you include a reply message.`); return;}
      if(result.length > 1000) {this.toastr.error(`Please make sure your message is less than 1000 chacaters.`); return;}
      this.chatsClient.declineMessageInChat(messageId,result)
      .subscribe(() => this.toastr.success(`Message responded to privately.`), (err) => this.toastr.error(`Message status has already been updated.`));
    }, () => null);
  }

  setChat(chatId: string) {
    if(chatId != this.currentChat.id) {
      forkJoin(
        this.chatsClient.getChatroom(chatId),
        this.chatsClient.getChatroomMessages(1, chatId)
      )
      .subscribe(([chatroom, chatroomMessages]) => {
        this.currentChat = chatroom;
        this.messages = chatroomMessages;
        if(this.currentChat.isActive)
          this.currentChat.isActive = new Date().getHours() >= 8 && new Date().getHours() <= 17;

        if(this.user.isAdmin)
          this.currentChat.isActive = true;

        this.isMobileSidebarActive = false;
      });
    }
  }

  getMoreMessages() {
    var currentMessages = this.messages.results;
    this.chatsClient.getChatroomMessages(this.messages.currentPage + 1, this.currentChat.id)
    .subscribe(result => {
      this.messages = result;
      var combinedMessages = currentMessages.concat(this.messages.results);
      this.messages.results = _.uniqBy(combinedMessages, 'id');
    });
  }

  toggleMessagePopUp() {
    this.messageToggle = !this.messageToggle;
    if(this.messageToggle) {
      this.chatMessageElements.changes.subscribe(() => {
        if (this.chatMessageElements && this.chatMessageElements.last) {
          var messagesContainer = document.getElementById("messages-container");
          var containerHeight = messagesContainer.clientHeight;
          var containerTop = messagesContainer.scrollTop;
          var lastElemTop = this.chatMessageElements.last.nativeElement.offsetTop;
          if(messagesContainer){
            if(lastElemTop <= containerHeight + containerTop) {
              messagesContainer.scrollTop = this.chatMessageElements.last.nativeElement.offsetTop;
            }
          }
        }
      });
    }
  }

  toggleSidebar() {
    this.isMobileSidebarActive = !this.isMobileSidebarActive;
  }

  addMessageOnClientSide(chatMessage: api.ChatMessageDisplayModel) {
    this.removeClientMessage();
    if(chatMessage.toChatId == this.currentChat.id) {
      var result = api.ChatMessageDisplayModel.fromJS(chatMessage);
      this.messages.results.unshift(result);
      var currentMessages = _.sortBy(this.messages.results, a => a.createdOn).reverse();
      this.messages.results = currentMessages;
    }
  }

  updateMessageOnClientSide(chatMessage: api.ChatMessageDisplayModel) {
    const currentMessage = this.messages.results.find(a => a.id == chatMessage.id);

    if (currentMessage == undefined) {
      return;
    }

    var indexOfMessage = this.messages.results.indexOf(currentMessage);
    this.messages.results[indexOfMessage].adminReply = chatMessage.adminReply;
    this.messages.results[indexOfMessage].statusId = chatMessage.statusId;
  }

  removeClientMessage() {
    _.remove(this.messages.results, a => a.id == undefined)
  }
}
